var _SessionToken_value, _SessionToken_user;
import { __classPrivateFieldGet, __classPrivateFieldSet } from "tslib";
import { base64decode } from '../utils';
export class SessionToken {
    constructor(value) {
        _SessionToken_value.set(this, void 0);
        _SessionToken_user.set(this, void 0);
        __classPrivateFieldSet(this, _SessionToken_value, value, "f");
        // this is a quick and easy way to parse JWT tokens without using a library
        const splits = value.split('.');
        __classPrivateFieldSet(this, _SessionToken_user, JSON.parse(base64decode(splits[1])), "f");
    }
    get value() {
        return __classPrivateFieldGet(this, _SessionToken_value, "f");
    }
    get user() {
        return __classPrivateFieldGet(this, _SessionToken_user, "f");
    }
    isValidForXMoreSeconds(seconds) {
        const now = new Date().getTime() / 1000;
        return __classPrivateFieldGet(this, _SessionToken_user, "f").exp > now + seconds;
    }
    toString() {
        return __classPrivateFieldGet(this, _SessionToken_value, "f");
    }
}
_SessionToken_value = new WeakMap(), _SessionToken_user = new WeakMap();
