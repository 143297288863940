var _CorbadoApp_instances, _CorbadoApp_authProcessService, _CorbadoApp_sessionService, _CorbadoApp_projectId, _CorbadoApp_validateProjectId;
import { __classPrivateFieldGet, __classPrivateFieldSet } from "tslib";
import { Ok } from 'ts-results';
import { defaultTimeout } from '../utils';
import { ProcessService } from './ProcessService';
import { SessionService } from './SessionService';
export { ConnectService } from './ConnectService';
/**
 * CorbadoApp is a class that represents the main application.
 * It manages the services and the flow of the application.
 * It also handles the initialization and destruction of the application.
 */
export class CorbadoApp {
    /**
     * The constructor initializes the services and sets up the application.
     */
    constructor(corbadoParams) {
        _CorbadoApp_instances.add(this);
        _CorbadoApp_authProcessService.set(this, void 0);
        _CorbadoApp_sessionService.set(this, void 0);
        _CorbadoApp_projectId.set(this, void 0);
        const { projectId, apiTimeout = defaultTimeout, frontendApiUrlSuffix = 'frontendapi.corbado.io', isPreviewMode = false, } = corbadoParams;
        __classPrivateFieldSet(this, _CorbadoApp_projectId, projectId, "f");
        __classPrivateFieldSet(this, _CorbadoApp_authProcessService, new ProcessService(__classPrivateFieldGet(this, _CorbadoApp_projectId, "f"), apiTimeout, isPreviewMode, frontendApiUrlSuffix), "f");
        __classPrivateFieldSet(this, _CorbadoApp_sessionService, new SessionService(__classPrivateFieldGet(this, _CorbadoApp_projectId, "f"), isPreviewMode, frontendApiUrlSuffix), "f");
    }
    get authProcessService() {
        return __classPrivateFieldGet(this, _CorbadoApp_authProcessService, "f");
    }
    get sessionService() {
        return __classPrivateFieldGet(this, _CorbadoApp_sessionService, "f");
    }
    /**
     * Method to initialize the application.
     * It fetches the project configuration and initializes the services.
     */
    async init() {
        // This can be improved by using the Err() type. Then we need to decide how to present the
        // error (print it to the browser console, render it in the component, do both etc.)
        if (!__classPrivateFieldGet(this, _CorbadoApp_instances, "m", _CorbadoApp_validateProjectId).call(this, __classPrivateFieldGet(this, _CorbadoApp_projectId, "f"))) {
            throw new Error(`Invalid project ID '${__classPrivateFieldGet(this, _CorbadoApp_projectId, "f")}'`);
        }
        await __classPrivateFieldGet(this, _CorbadoApp_sessionService, "f").init();
        return Ok(void 0);
    }
    dispose() {
        __classPrivateFieldGet(this, _CorbadoApp_authProcessService, "f").dispose();
        __classPrivateFieldGet(this, _CorbadoApp_sessionService, "f").dispose();
    }
}
_CorbadoApp_authProcessService = new WeakMap(), _CorbadoApp_sessionService = new WeakMap(), _CorbadoApp_projectId = new WeakMap(), _CorbadoApp_instances = new WeakSet(), _CorbadoApp_validateProjectId = function _CorbadoApp_validateProjectId(projectId) {
    return /^pro-\d+$/.test(projectId);
};
