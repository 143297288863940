const getStorageKey = (projectId) => `cbo_connect_process-${projectId}`;
export class ConnectProcess {
    constructor(id, projectId, frontendApiUrl, loginData, appendData, manageData) {
        this.id = id;
        this.projectId = projectId;
        this.frontendApiUrl = frontendApiUrl;
        this.loginData = loginData;
        this.appendData = appendData;
        this.manageData = manageData;
    }
    isValid() {
        return true;
    }
    resetLoginData() {
        return new ConnectProcess(this.id, this.projectId, this.frontendApiUrl, null, this.appendData, this.manageData);
    }
    copyWithLoginData(loginData) {
        return new ConnectProcess(this.id, this.projectId, this.frontendApiUrl, loginData, this.appendData, this.manageData);
    }
    copyWithAppendData(appendData) {
        return new ConnectProcess(this.id, this.projectId, this.frontendApiUrl, this.loginData, appendData, this.manageData);
    }
    copyWithManageData(manageData) {
        return new ConnectProcess(this.id, this.projectId, this.frontendApiUrl, this.loginData, this.appendData, manageData);
    }
    getValidLoginData() {
        if (!this.loginData || !this.loginData.expiresAt) {
            return;
        }
        if (this.loginData.expiresAt < Date.now() / 1000) {
            return;
        }
        return this.loginData;
    }
    getValidAppendData() {
        if (!this.appendData || !this.appendData.expiresAt) {
            return;
        }
        if (this.appendData.expiresAt < Date.now() / 1000) {
            return;
        }
        return this.appendData;
    }
    getValidManageData() {
        if (!this.manageData || !this.manageData.expiresAt) {
            return;
        }
        if (this.manageData.expiresAt < Date.now() / 1000) {
            return;
        }
        return this.manageData;
    }
    static loadFromStorage(projectId) {
        const serialized = localStorage.getItem(getStorageKey(projectId));
        if (!serialized) {
            return undefined;
        }
        const { id, frontendApiUrl, loginData, appendData, manageData } = JSON.parse(serialized);
        const process = new ConnectProcess(id, projectId, frontendApiUrl, loginData, appendData, manageData);
        if (!process.isValid()) {
            return undefined;
        }
        return process;
    }
    persistToStorage() {
        localStorage.setItem(getStorageKey(this.projectId), JSON.stringify({
            id: this.id,
            frontendApiUrl: this.frontendApiUrl,
            loginData: this.loginData,
            appendData: this.appendData,
            manageData: this.manageData,
        }));
    }
    static clearStorage(projectId) {
        localStorage.removeItem(getStorageKey(projectId));
    }
}
