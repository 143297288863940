

import { Avatar } from "baseui/avatar";
import { Drawer } from "baseui/drawer";
import CryptoES from "crypto-es";
import { DateTime } from "luxon";
import { FullUser } from "@smc-legacy/v5/types/user";

type props = {
  isOpen: boolean;
  user: FullUser | null;
  onClose: () => void;
};

const getGravatar = (email: string) => {
  const trimmedEmail = email.trim().toLowerCase();
  const hash = CryptoES.SHA256(trimmedEmail).toString(CryptoES.enc.Hex);
  return `https://www.gravatar.com/avatar/${hash}?s=80&d=identicon`;
};

export default function UserDrawer({ isOpen, user, onClose }: Readonly<props>) {
  return (
    <Drawer
      isOpen={isOpen}
      autoFocus
      onClose={(e) => {
        onClose();
      }}
    >
      <Avatar
        name={user?.fullName}
        size="scale1600"
        src={user ? getGravatar(user.emails[0].email) : undefined}
      />
      <h1>{user?.fullName}</h1>

      <p>Email: {user ? user.emails[0].email : "No email"}</p>
      <p>
        Created:{" "}
        {user
          ? DateTime.fromFormat(
              user?.created,
              "yyyy-MM-dd HH:mm:ss"
            ).toRFC2822()
          : "No date"}
      </p>
    </Drawer>
  );
}
