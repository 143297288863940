import { useEffect, useState } from "react";

import { AdvancedMarker as GoogleMarker, Pin } from "@vis.gl/react-google-maps";
import { Icon } from "leaflet";
import "mapbox-gl/dist/mapbox-gl.css";
import { Marker as MapkitMarker } from "mapkit-react";
import { Marker } from "react-leaflet";
import { Feature, Layer } from "react-mapbox-gl";

import { jagIcon } from "@smc/components/icons";

import Position from "./Position";
import StealMapType from "./StealMapType";
import { useMap } from "./MapContext";

type StealMarkerProps = {
  position: Position;
  icon?: Icon;
  googleIcon?: React.ReactNode;
};

const StealMarker = ({
  position,
  icon = jagIcon,
  googleIcon
}: StealMarkerProps) => {

const {map} = useMap();
  const [content, setContent] = useState<React.ReactNode>(null);
  useEffect(() => {

    switch (map) {
      case StealMapType.Leaflet:
        setContent(
          <Marker
            position={[position.latitude, position.longitude]}
            icon={icon}
          />
        );
        break;
      case StealMapType.Mapkit:
        setContent(
          <MapkitMarker
            latitude={position.latitude}
            longitude={position.longitude}
          />
        );
        break;
      case StealMapType.Mapbox:
        setContent(
          <Layer type="symbol" id={`marker-${position.latitude}-${position.longitude}`} layout={{ 'icon-image': 'marker-15' }}>
          <Feature coordinates={[position.longitude, position.latitude]} />
          </Layer>
        );
        break;
      case StealMapType.Google:
        setContent(
          <GoogleMarker
            position={{ lat: position.latitude, lng: position.longitude }}
          >
            {googleIcon ? googleIcon : <Pin />}
          </GoogleMarker>
        );
        break;
      default:
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [position, icon ,map]);
  return content;
};

export default StealMarker;
