import { createContext } from 'react';
const missingImplementation = () => {
    throw new Error('Please make sure that your components are wrapped inside <CorbadoProvider/>');
};
export const initialContext = {
    corbadoApp: undefined,
    sessionToken: undefined,
    loading: true,
    isAuthenticated: false,
    user: undefined,
    globalError: undefined,
    logout: missingImplementation,
    appendPasskey: missingImplementation,
    getPasskeys: missingImplementation,
    deletePasskey: missingImplementation,
    getFullUser: missingImplementation,
};
export const CorbadoSessionContext = createContext(initialContext);
