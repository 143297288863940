import { useStyletron } from "baseui";
import { Card } from "baseui/card";

import { colors } from "@smc/theme/theme";

export default function SelectAJourney() {
  const [css] = useStyletron();
  // Using our custom dark theme colors
  return (
    <div
      className={css({
        textAlign: "center",
        justifyContent: "center",
        justifyItems: "center",
        width: "20%",
        paddingLeft: "10rem"
      })}
    >
      <Card
        overrides={{
          Root: {
            style: ({ $theme }) => ({
              outline: `${colors.cyan[400]} solid`,
              backgroundColor: colors.zinc[800],
              color: colors.slate[50]
            })
          },
          Body: {
            style: ({ $theme }) => ({
              color: colors.slate[300]
            })
          }
        }}
      >
        Select a Journey
      </Card>
    </div>
  );
}
