import { useEffect, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";

import { Pin } from "@vis.gl/react-google-maps";
import { useStyletron } from "baseui";
import { Button } from "baseui/button";
import { Feature, Layer } from "react-mapbox-gl";

import { JourneyLog } from "@smc-api/middleware/db/schema";

import {
  StealMap,
  StealMapType,
  StealMarker,
  getColoredMapIcon,
  tyreIcon,
  useMap
} from "@smc/components";
import { useAxios } from "@smc/hooks";
import { colors } from "@smc/theme/theme";

const lineLayout = {
  "line-cap": "round" as "round",
  "line-join": "round" as "round"
};
const linePaint = {
  "line-color": colors.cyan[400], // Primary accent color
  "line-width": 12
};

export default function WatchRoute() {
  const ctx = useMap();

  const [css] = useStyletron();
  const journeyId = useOutletContext();
  const { apiClient } = useAxios();
  const navigate = useNavigate();
  const [steps, setSteps] = useState<JourneyLog[]>([
    {
      id: 0,
      journeyId: 0,
      latitude: "0",
      longitude: "0",
      timestamp: new Date()
    }
  ]);
  const [mappedPoints, setMappedPoints] = useState<[number, number][]>([]);

  useEffect(() => {
    fetchJourneySteps().then(() => {
      setMappedPoints(
        steps.map((step) => [
          parseFloat(step.latitude ?? "0"),
          parseFloat(step.longitude ?? "0")
        ])
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [journeyId]);

  const fetchJourneySteps = async () => {
    const response = await apiClient.get(`journeys/${journeyId}/logs`);
    setSteps(response.data);
  };

  return (
    <div
      className={css({
        display: "flex",
        margin: "auto",
        flexDirection: "column"
      })}
    >
      <StealMap
        containerStyle={{
          height: "80vh",
          width: "60vw",
          margin: "auto",
          border: `10px solid ${colors.cyan[400]}` // Primary accent color
        }}
      >
        {ctx.map === StealMapType.Mapbox ? (
          <Layer type="line" layout={lineLayout} paint={linePaint}>
            <Feature coordinates={mappedPoints} />
          </Layer>
        ) : (
          <>
            <StealMarker
              position={{
                latitude: parseFloat(steps[0].latitude ?? "0"),
                longitude: parseFloat(steps[0].longitude ?? "0")
              }}
              icon={getColoredMapIcon("green")}
              googleIcon={
                <Pin
                  background={colors.emerald[400]} // Success color
                  borderColor={colors.zinc[900]} // Dark background
                  glyphColor={colors.slate[50]} // Content primary
                />
              }
            />
            {steps.slice(1, steps.length - 1).map((step) => (
              <StealMarker
                key={step.id}
                position={{
                  latitude: +step.latitude!,
                  longitude: +step.longitude!
                }}
                icon={tyreIcon}
                googleIcon={
                  <img
                    src="../../tyre.png"
                    alt="Tyre Icon"
                    width="24"
                    height="24"
                  />
                }
              />
            ))}
            <StealMarker
              position={{
                latitude: parseFloat(steps[steps.length - 1].latitude ?? "0"),
                longitude: parseFloat(steps[steps.length - 1].longitude ?? "0")
              }}
              icon={getColoredMapIcon("red")}
              googleIcon={
                <Pin
                  background={colors.rose[500]} // Error color
                  borderColor={colors.zinc[900]} // Dark background
                  glyphColor={colors.slate[50]} // Content primary
                />
              }
            />
          </>
        )}
      </StealMap>
      <div className={css({ height: "5px" })} />
      <Button
        onClick={() => navigate("../")}
        className={css({
          width: "100%",
          margin: "auto"
        })}
      >
        Close
      </Button>
    </div>
  );
}
