var _ConnectService_instances, _ConnectService_connectApi, _ConnectService_webAuthnService, _ConnectService_projectId, _ConnectService_timeout, _ConnectService_frontendApiUrlSuffix, _ConnectService_visitorId, _ConnectService_createAxiosInstanceV2, _ConnectService_setApisV2, _ConnectService_getExistingProcess, _ConnectService_loginFinish, _ConnectService_recordEvent, _ConnectService_getDefaultFrontendApiUrl, _ConnectService_getInitReq;
import { __classPrivateFieldGet, __classPrivateFieldSet } from "tslib";
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import axios from 'axios';
import log from 'loglevel';
import { Err, Ok } from 'ts-results';
import { Configuration } from '../api/v1';
import { CorbadoConnectApi, PasskeyEventType } from '../api/v2';
import { ConnectFlags } from '../models/connect/connectFlags';
import { ConnectInvitation } from '../models/connect/connectInvitation';
import { ConnectLastLogin } from '../models/connect/connectLastLogin';
import { ConnectProcess } from '../models/connect/connectProcess';
import { CorbadoError } from '../utils';
import { WebAuthnService } from './WebAuthnService';
const packageVersion = process.env.FE_LIBRARY_VERSION;
export class ConnectService {
    constructor(projectId, frontendApiUrlSuffix, isDebug) {
        _ConnectService_instances.add(this);
        _ConnectService_connectApi.set(this, new CorbadoConnectApi());
        _ConnectService_webAuthnService.set(this, void 0);
        // Private fields for project ID and default timeout for API calls.
        _ConnectService_projectId.set(this, void 0);
        _ConnectService_timeout.set(this, void 0);
        _ConnectService_frontendApiUrlSuffix.set(this, void 0);
        _ConnectService_visitorId.set(this, void 0);
        __classPrivateFieldSet(this, _ConnectService_projectId, projectId, "f");
        __classPrivateFieldSet(this, _ConnectService_timeout, 10 * 1000, "f");
        __classPrivateFieldSet(this, _ConnectService_frontendApiUrlSuffix, frontendApiUrlSuffix, "f");
        __classPrivateFieldSet(this, _ConnectService_webAuthnService, new WebAuthnService(), "f");
        __classPrivateFieldSet(this, _ConnectService_visitorId, '', "f");
        // Initializes the API instances with no authentication token.
        // Authentication tokens are set in the SessionService.
        __classPrivateFieldGet(this, _ConnectService_instances, "m", _ConnectService_setApisV2).call(this);
        if (isDebug) {
            log.setLevel('debug');
        }
        else {
            log.setLevel('error');
        }
    }
    async wrapWithErr(callback) {
        try {
            const r = await callback();
            return Ok(r.data);
        }
        catch (e) {
            if (e instanceof CorbadoError) {
                return Err(e);
            }
            return Err(CorbadoError.fromUnknownFrontendError(e));
        }
    }
    async loginInit(abortController) {
        var _a, _b;
        const existingProcess = ConnectProcess.loadFromStorage(__classPrivateFieldGet(this, _ConnectService_projectId, "f"));
        const maybeLoginData = existingProcess === null || existingProcess === void 0 ? void 0 : existingProcess.getValidLoginData();
        if (existingProcess &&
            maybeLoginData &&
            !maybeLoginData.loginAllowed &&
            ((_a = ConnectInvitation.loadFromStorage()) === null || _a === void 0 ? void 0 : _a.token)) {
            existingProcess.resetLoginData().persistToStorage();
        }
        else if (existingProcess) {
            log.debug('process exists, preparing api clients');
            __classPrivateFieldGet(this, _ConnectService_instances, "m", _ConnectService_setApisV2).call(this, existingProcess);
            // process has already been initialized
            if (maybeLoginData) {
                return Ok(maybeLoginData);
            }
        }
        const { req, flags } = await __classPrivateFieldGet(this, _ConnectService_instances, "m", _ConnectService_getInitReq).call(this);
        const res = await this.wrapWithErr(() => __classPrivateFieldGet(this, _ConnectService_connectApi, "f").connectLoginInit(req, { signal: abortController.signal, timeout: 5 * 1000 }));
        if (res.err) {
            return res;
        }
        const existingProcessFromOtherLoginInit = ConnectProcess.loadFromStorage(__classPrivateFieldGet(this, _ConnectService_projectId, "f"));
        const maybeExistingLoginDataFromOtherLoginInit = existingProcessFromOtherLoginInit === null || existingProcessFromOtherLoginInit === void 0 ? void 0 : existingProcessFromOtherLoginInit.getValidLoginData();
        if (maybeExistingLoginDataFromOtherLoginInit) {
            if (res.val.token !== (existingProcessFromOtherLoginInit === null || existingProcessFromOtherLoginInit === void 0 ? void 0 : existingProcessFromOtherLoginInit.id)) {
                await __classPrivateFieldGet(this, _ConnectService_connectApi, "f").connectProcessClear({ processId: res.val.token });
            }
            log.debug('process exists (after login init attempt');
            __classPrivateFieldGet(this, _ConnectService_instances, "m", _ConnectService_setApisV2).call(this, existingProcessFromOtherLoginInit);
            return Ok(maybeExistingLoginDataFromOtherLoginInit);
        }
        // if the backend decides that a new client handle is needed, we store it in local storage
        if (res.val.newClientEnvHandle) {
            WebAuthnService.setClientHandle(res.val.newClientEnvHandle);
        }
        flags.addItemsObject(res.val.flags);
        const loginData = {
            loginAllowed: res.val.loginAllowed,
            conditionalUIChallenge: (_b = res.val.conditionalUIChallenge) !== null && _b !== void 0 ? _b : null,
            flags: flags.getItemsObject(),
            expiresAt: res.val.expiresAt,
        };
        if (existingProcess && existingProcess.id === res.val.token) {
            log.debug('process exists, updating login data', loginData);
            const p = existingProcess.copyWithLoginData(loginData);
            p.persistToStorage();
        }
        else {
            log.debug('creating new process', loginData);
            const newProcess = new ConnectProcess(res.val.token, __classPrivateFieldGet(this, _ConnectService_projectId, "f"), res.val.frontendApiUrl, loginData, null, null);
            __classPrivateFieldGet(this, _ConnectService_instances, "m", _ConnectService_setApisV2).call(this, newProcess);
            newProcess.persistToStorage();
        }
        // persist flags
        flags.persistToStorage(__classPrivateFieldGet(this, _ConnectService_projectId, "f"));
        return Ok(loginData);
    }
    async loginStart(identifier, source, loadedMs, connectToken, ac) {
        const existingProcess = await this.loginInit(ac !== null && ac !== void 0 ? ac : new AbortController());
        if (existingProcess.err) {
            return Err(CorbadoError.missingInit());
        }
        let identifierHintAvailable = false;
        if (localStorage.getItem('vicroads_login_user_email_personal')) {
            identifierHintAvailable = true;
        }
        const res = await this.wrapWithErr(() => __classPrivateFieldGet(this, _ConnectService_connectApi, "f").connectLoginStart({
            identifier,
            source: source,
            loadedMs,
            loginConnectToken: connectToken,
            identifierHintAvailable: identifierHintAvailable,
        }, { signal: ac === null || ac === void 0 ? void 0 : ac.signal }));
        if (res.err) {
            this.clearLastLogin();
            return res;
        }
        if (res.val.error) {
            this.clearLastLogin();
            return Err(CorbadoError.fromConnectErrorResponse(res.val.error));
        }
        if (!res.val.assertionOptions) {
            this.clearLastLogin();
            return Err(CorbadoError.noPasskeyAvailable());
        }
        return res;
    }
    async loginContinue(start) {
        const res = await __classPrivateFieldGet(this, _ConnectService_webAuthnService, "f").login(start.assertionOptions, false);
        if (res.err) {
            this.clearLastLogin();
            return res;
        }
        return __classPrivateFieldGet(this, _ConnectService_instances, "m", _ConnectService_loginFinish).call(this, res.val, false);
    }
    async conditionalUILogin(preWebAuthn, postWebAuthn, onLoginEnd, loadedMs) {
        var _a, _b;
        const existingProcess = await __classPrivateFieldGet(this, _ConnectService_instances, "m", _ConnectService_getExistingProcess).call(this, () => this.loginInit(new AbortController()));
        if (!existingProcess) {
            return Err(CorbadoError.missingInit());
        }
        if (!existingProcess.loginData || ((_a = existingProcess.loginData) === null || _a === void 0 ? void 0 : _a.conditionalUIChallenge) === null) {
            return Err(CorbadoError.missingInit());
        }
        const challenge = (_b = existingProcess.loginData) === null || _b === void 0 ? void 0 : _b.conditionalUIChallenge;
        const res = await __classPrivateFieldGet(this, _ConnectService_webAuthnService, "f").login(challenge, true, preWebAuthn);
        if (res.err) {
            return res;
        }
        postWebAuthn();
        const loginFinishResp = await __classPrivateFieldGet(this, _ConnectService_instances, "m", _ConnectService_loginFinish).call(this, res.val, true, loadedMs);
        onLoginEnd();
        return loginFinishResp;
    }
    async appendInit(abortController) {
        const existingProcess = ConnectProcess.loadFromStorage(__classPrivateFieldGet(this, _ConnectService_projectId, "f"));
        if (existingProcess) {
            log.debug('process exists, preparing api clients');
            __classPrivateFieldGet(this, _ConnectService_instances, "m", _ConnectService_setApisV2).call(this, existingProcess);
            // process has already been initialized
            const maybeAppendData = existingProcess === null || existingProcess === void 0 ? void 0 : existingProcess.getValidAppendData();
            if (maybeAppendData) {
                return Ok(maybeAppendData);
            }
        }
        const { req, flags } = await __classPrivateFieldGet(this, _ConnectService_instances, "m", _ConnectService_getInitReq).call(this);
        const res = await this.wrapWithErr(() => __classPrivateFieldGet(this, _ConnectService_connectApi, "f").connectAppendInit(req, { signal: abortController.signal }));
        if (res.err) {
            return res;
        }
        // if the backend decides that a new client handle is needed, we store it in local storage
        if (res.val.newClientEnvHandle) {
            WebAuthnService.setClientHandle(res.val.newClientEnvHandle);
        }
        flags.addItemsObject(res.val.flags);
        const appendData = {
            appendAllowed: res.val.appendAllowed,
            flags: flags.getItemsObject(),
            expiresAt: res.val.expiresAt,
        };
        // update local state with process
        if (existingProcess && existingProcess.id === res.val.processID) {
            log.debug('process exists, updating append data', appendData);
            const p = existingProcess.copyWithAppendData(appendData);
            p.persistToStorage();
        }
        else {
            log.debug('creating new process', appendData);
            const newProcess = new ConnectProcess(res.val.processID, __classPrivateFieldGet(this, _ConnectService_projectId, "f"), res.val.frontendApiUrl, null, appendData, null);
            __classPrivateFieldGet(this, _ConnectService_instances, "m", _ConnectService_setApisV2).call(this, newProcess);
            newProcess.persistToStorage();
        }
        // persist flags
        flags.persistToStorage(__classPrivateFieldGet(this, _ConnectService_projectId, "f"));
        return Ok(appendData);
    }
    async append(appendTokenValue, loadedMs) {
        const existingProcess = await __classPrivateFieldGet(this, _ConnectService_instances, "m", _ConnectService_getExistingProcess).call(this, () => this.appendInit(new AbortController()));
        if (!existingProcess) {
            return Err(CorbadoError.missingInit());
        }
        const resStart = await this.wrapWithErr(() => __classPrivateFieldGet(this, _ConnectService_connectApi, "f").connectAppendStart({ appendTokenValue: appendTokenValue, loadedMs }));
        if (resStart.err) {
            return resStart;
        }
        const platformRes = await __classPrivateFieldGet(this, _ConnectService_webAuthnService, "f").createPasskey(resStart.val.attestationOptions);
        if (platformRes.err) {
            return platformRes;
        }
        return this.wrapWithErr(() => __classPrivateFieldGet(this, _ConnectService_connectApi, "f").connectAppendFinish({ attestationResponse: platformRes.val }));
    }
    async startAppend(appendTokenValue, loadedMs, abortController, initiatedByUser) {
        const existingProcess = await __classPrivateFieldGet(this, _ConnectService_instances, "m", _ConnectService_getExistingProcess).call(this, () => this.appendInit(new AbortController()));
        if (!existingProcess) {
            return Err(CorbadoError.missingInit());
        }
        return this.wrapWithErr(() => __classPrivateFieldGet(this, _ConnectService_connectApi, "f").connectAppendStart({ appendTokenValue: appendTokenValue, forcePasskeyAppend: initiatedByUser, loadedMs }, abortController && { signal: abortController.signal }));
    }
    async completeAppend(attestationOptions) {
        const existingProcess = await __classPrivateFieldGet(this, _ConnectService_instances, "m", _ConnectService_getExistingProcess).call(this, () => this.appendInit(new AbortController()));
        if (!existingProcess) {
            return Err(CorbadoError.missingInit());
        }
        const res = await __classPrivateFieldGet(this, _ConnectService_webAuthnService, "f").createPasskey(attestationOptions);
        if (res.err) {
            return res;
        }
        const finishRes = await this.wrapWithErr(() => __classPrivateFieldGet(this, _ConnectService_connectApi, "f").connectAppendFinish({ attestationResponse: res.val }));
        if (finishRes.ok) {
            const latestLogin = new ConnectLastLogin(finishRes.val.passkeyOperation);
            latestLogin.persistToStorage(__classPrivateFieldGet(this, _ConnectService_projectId, "f"));
        }
        return finishRes;
    }
    dispose() {
        __classPrivateFieldGet(this, _ConnectService_webAuthnService, "f").abortOngoingOperation();
    }
    async manageInit(abortController) {
        const existingProcess = ConnectProcess.loadFromStorage(__classPrivateFieldGet(this, _ConnectService_projectId, "f"));
        if (existingProcess) {
            log.debug('process exists, preparing api clients');
            __classPrivateFieldGet(this, _ConnectService_instances, "m", _ConnectService_setApisV2).call(this, existingProcess);
            // process has already been initialized
            const maybeManageData = existingProcess === null || existingProcess === void 0 ? void 0 : existingProcess.getValidManageData();
            if (maybeManageData) {
                return Ok(maybeManageData);
            }
        }
        const { req, flags } = await __classPrivateFieldGet(this, _ConnectService_instances, "m", _ConnectService_getInitReq).call(this);
        const res = await this.wrapWithErr(() => __classPrivateFieldGet(this, _ConnectService_connectApi, "f").connectManageInit(req, { signal: abortController.signal }));
        if (res.err) {
            return res;
        }
        // if the backend decides that a new client handle is needed, we store it in local storage
        if (res.val.newClientEnvHandle) {
            WebAuthnService.setClientHandle(res.val.newClientEnvHandle);
        }
        flags.addItemsObject(res.val.flags);
        const manageData = {
            manageAllowed: res.val.manageAllowed,
            flags: flags.getItemsObject(),
            expiresAt: res.val.expiresAt,
        };
        // update local state with process
        if (existingProcess && existingProcess.id === res.val.processID) {
            const p = existingProcess.copyWithManageData(manageData);
            p.persistToStorage();
        }
        else {
            const newProcess = new ConnectProcess(res.val.processID, __classPrivateFieldGet(this, _ConnectService_projectId, "f"), res.val.frontendApiUrl, null, null, manageData);
            __classPrivateFieldGet(this, _ConnectService_instances, "m", _ConnectService_setApisV2).call(this, newProcess);
            newProcess.persistToStorage();
        }
        // persist flags
        flags.persistToStorage(__classPrivateFieldGet(this, _ConnectService_projectId, "f"));
        return Ok(manageData);
    }
    async manageList(passkeyListToken, triggerSignalAllAccepted) {
        const existingProcess = await __classPrivateFieldGet(this, _ConnectService_instances, "m", _ConnectService_getExistingProcess).call(this, () => this.manageInit(new AbortController()));
        if (!existingProcess) {
            return Err(CorbadoError.missingInit());
        }
        const req = {
            connectToken: passkeyListToken,
        };
        const out = await this.wrapWithErr(() => __classPrivateFieldGet(this, _ConnectService_connectApi, "f").connectManageList(req));
        if (out.err) {
            return out;
        }
        // self-healing mechanism: if a user has no passkeys, we clear the last login
        if (out.val.passkeys.length === 0) {
            this.clearLastLogin();
        }
        if (triggerSignalAllAccepted) {
            const credentialIDs = out.val.passkeys.map(pk => pk.credentialID);
            await WebAuthnService.signalAllAcceptedCredentials(out.val.rpID, out.val.userID, credentialIDs);
        }
        return out;
    }
    async manageDelete(passkeyDeleteToken, credentialID) {
        const existingProcess = await __classPrivateFieldGet(this, _ConnectService_instances, "m", _ConnectService_getExistingProcess).call(this, () => this.manageInit(new AbortController()));
        if (!existingProcess) {
            return Err(CorbadoError.missingInit());
        }
        const req = {
            connectToken: passkeyDeleteToken,
            credentialID,
        };
        return this.wrapWithErr(() => __classPrivateFieldGet(this, _ConnectService_connectApi, "f").connectManageDelete(req));
    }
    setInvitation(token) {
        const invitation = new ConnectInvitation(token);
        invitation.persistToStorage();
    }
    recordEventLoginError(messageCode) {
        return __classPrivateFieldGet(this, _ConnectService_instances, "m", _ConnectService_recordEvent).call(this, PasskeyEventType.LoginError, messageCode);
    }
    recordEventLoginExplicitAbort(assertionOptions) {
        let challenge;
        if (assertionOptions) {
            challenge = WebAuthnService.challengeFromAssertionOptions(assertionOptions);
        }
        return __classPrivateFieldGet(this, _ConnectService_instances, "m", _ConnectService_recordEvent).call(this, PasskeyEventType.LoginExplicitAbort, undefined, challenge);
    }
    recordEventLoginOneTapSwitch() {
        return __classPrivateFieldGet(this, _ConnectService_instances, "m", _ConnectService_recordEvent).call(this, PasskeyEventType.LoginOneTapSwitch);
    }
    recordEventLoginErrorUntyped() {
        return __classPrivateFieldGet(this, _ConnectService_instances, "m", _ConnectService_recordEvent).call(this, PasskeyEventType.LoginErrorUntyped);
    }
    recordEventUserAppendAfterCrossPlatformBlacklisted() {
        return __classPrivateFieldGet(this, _ConnectService_instances, "m", _ConnectService_recordEvent).call(this, PasskeyEventType.UserAppendAfterCrossPlatformBlacklisted);
    }
    recordEventUserAppendAfterLoginErrorBlacklisted() {
        return __classPrivateFieldGet(this, _ConnectService_instances, "m", _ConnectService_recordEvent).call(this, PasskeyEventType.UserAppendAfterLoginErrorBlacklisted);
    }
    recordEventAppendCredentialExistsError() {
        return __classPrivateFieldGet(this, _ConnectService_instances, "m", _ConnectService_recordEvent).call(this, PasskeyEventType.AppendCredentialExists);
    }
    recordEventAppendError() {
        return __classPrivateFieldGet(this, _ConnectService_instances, "m", _ConnectService_recordEvent).call(this, PasskeyEventType.AppendError);
    }
    recordEventLoginErrorUnexpected(messageCode) {
        return __classPrivateFieldGet(this, _ConnectService_instances, "m", _ConnectService_recordEvent).call(this, PasskeyEventType.LoginErrorUnexpected, messageCode);
    }
    recordEventAppendErrorUnexpected(messageCode) {
        return __classPrivateFieldGet(this, _ConnectService_instances, "m", _ConnectService_recordEvent).call(this, PasskeyEventType.AppendErrorUnexpected, messageCode);
    }
    recordEventManageErrorUnexpected(messageCode) {
        return __classPrivateFieldGet(this, _ConnectService_instances, "m", _ConnectService_recordEvent).call(this, PasskeyEventType.ManageErrorUnexpected, messageCode);
    }
    recordEventAppendExplicitAbort(attestationOptions) {
        let challenge;
        if (attestationOptions) {
            challenge = WebAuthnService.challengeFromAttestationOptions(attestationOptions);
        }
        return __classPrivateFieldGet(this, _ConnectService_instances, "m", _ConnectService_recordEvent).call(this, PasskeyEventType.AppendExplicitAbort, undefined, challenge);
    }
    getLastLogin() {
        return ConnectLastLogin.loadFromStorage(__classPrivateFieldGet(this, _ConnectService_projectId, "f"));
    }
    clearLastLogin() {
        ConnectLastLogin.clearStorage(__classPrivateFieldGet(this, _ConnectService_projectId, "f"));
    }
}
_ConnectService_connectApi = new WeakMap(), _ConnectService_webAuthnService = new WeakMap(), _ConnectService_projectId = new WeakMap(), _ConnectService_timeout = new WeakMap(), _ConnectService_frontendApiUrlSuffix = new WeakMap(), _ConnectService_visitorId = new WeakMap(), _ConnectService_instances = new WeakSet(), _ConnectService_createAxiosInstanceV2 = function _ConnectService_createAxiosInstanceV2(processId) {
    const corbadoVersion = {
        name: 'web-core',
        sdkVersion: packageVersion,
    };
    const headers = {
        'Content-Type': 'application/json',
        'X-Corbado-SDK': JSON.stringify(corbadoVersion),
        'X-Corbado-Client-Timezone': Intl.DateTimeFormat().resolvedOptions().timeZone,
    };
    const out = axios.create({
        timeout: __classPrivateFieldGet(this, _ConnectService_timeout, "f"),
        withCredentials: true,
        headers: processId ? { ...headers, 'x-corbado-process-id': processId } : headers,
    });
    // We transform AxiosErrors into CorbadoErrors using axios interceptors.
    out.interceptors.response.use(response => response, (error) => {
        const e = CorbadoError.fromConnectAxiosError(error);
        log.debug('axios error', error, e);
        return Promise.reject(e);
    });
    return out;
}, _ConnectService_setApisV2 = function _ConnectService_setApisV2(process) {
    var _a;
    let frontendApiUrl = __classPrivateFieldGet(this, _ConnectService_instances, "m", _ConnectService_getDefaultFrontendApiUrl).call(this);
    if ((process === null || process === void 0 ? void 0 : process.frontendApiUrl) && (process === null || process === void 0 ? void 0 : process.frontendApiUrl.length) > 0) {
        frontendApiUrl = process.frontendApiUrl;
    }
    const config = new Configuration({
        apiKey: __classPrivateFieldGet(this, _ConnectService_projectId, "f"),
        basePath: frontendApiUrl,
    });
    const axiosInstance = __classPrivateFieldGet(this, _ConnectService_instances, "m", _ConnectService_createAxiosInstanceV2).call(this, (_a = process === null || process === void 0 ? void 0 : process.id) !== null && _a !== void 0 ? _a : '');
    __classPrivateFieldSet(this, _ConnectService_connectApi, new CorbadoConnectApi(config, frontendApiUrl, axiosInstance), "f");
}, _ConnectService_getExistingProcess = async function _ConnectService_getExistingProcess(generator) {
    const existingProcess = ConnectProcess.loadFromStorage(__classPrivateFieldGet(this, _ConnectService_projectId, "f"));
    if (existingProcess) {
        log.debug('process found');
        return existingProcess;
    }
    log.debug('process not found, trying to reinitialize');
    const generatorResult = await generator();
    const newProcess = ConnectProcess.loadFromStorage(__classPrivateFieldGet(this, _ConnectService_projectId, "f"));
    if (generatorResult.err || !newProcess) {
        return null;
    }
    return newProcess;
}, _ConnectService_loginFinish = async function _ConnectService_loginFinish(assertionResponse, isConditionalUI, loadedMs) {
    const existingProcess = await __classPrivateFieldGet(this, _ConnectService_instances, "m", _ConnectService_getExistingProcess).call(this, () => this.loginInit(new AbortController()));
    if (!existingProcess) {
        return Err(CorbadoError.missingInit());
    }
    const res = await this.wrapWithErr(() => __classPrivateFieldGet(this, _ConnectService_connectApi, "f").connectLoginFinish({ assertionResponse, isConditionalUI, loadedMs }, { timeout: 15 * 1000 }));
    if (isConditionalUI) {
        existingProcess.resetLoginData().persistToStorage();
    }
    if (res.ok) {
        const latestLogin = new ConnectLastLogin(res.val.passkeyOperation);
        latestLogin.persistToStorage(__classPrivateFieldGet(this, _ConnectService_projectId, "f"));
    }
    return res;
}, _ConnectService_recordEvent = function _ConnectService_recordEvent(eventType, message, challenge) {
    const existingProcess = ConnectProcess.loadFromStorage(__classPrivateFieldGet(this, _ConnectService_projectId, "f"));
    if (!existingProcess) {
        log.warn('No process found to record event.');
        return;
    }
    const req = {
        eventType,
        message,
        challenge,
    };
    return this.wrapWithErr(() => __classPrivateFieldGet(this, _ConnectService_connectApi, "f").connectEventCreate(req));
}, _ConnectService_getDefaultFrontendApiUrl = function _ConnectService_getDefaultFrontendApiUrl() {
    return `https://${__classPrivateFieldGet(this, _ConnectService_projectId, "f")}.${__classPrivateFieldGet(this, _ConnectService_frontendApiUrlSuffix, "f")}`;
}, _ConnectService_getInitReq = async function _ConnectService_getInitReq() {
    var _a;
    let currentVisitorId = __classPrivateFieldGet(this, _ConnectService_visitorId, "f");
    if (!currentVisitorId) {
        const fpJS = await FingerprintJS.load();
        const { visitorId } = await fpJS.get();
        currentVisitorId = visitorId;
        __classPrivateFieldSet(this, _ConnectService_visitorId, visitorId, "f");
    }
    const flags = ConnectFlags.loadFromStorage(__classPrivateFieldGet(this, _ConnectService_projectId, "f"));
    const clientInformation = await __classPrivateFieldGet(this, _ConnectService_webAuthnService, "f").getClientInformation();
    const invitationToken = (_a = ConnectInvitation.loadFromStorage()) === null || _a === void 0 ? void 0 : _a.token;
    const req = {
        clientInformation: clientInformation,
        flags: flags.getItemsObject(),
        invitationToken: invitationToken,
    };
    return { req, flags };
};
