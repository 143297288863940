var _SessionService_instances, _a, _SessionService_usersApi, _SessionService_webAuthnService, _SessionService_projectId, _SessionService_isPreviewMode, _SessionService_frontendApiUrlSuffix, _SessionService_sessionConfig, _SessionService_sessionToken, _SessionService_refreshToken, _SessionService_refreshIntervalId, _SessionService_userChanges, _SessionService_sessionTokenChanges, _SessionService_authStateChanges, _SessionService_onSessionTokenChange, _SessionService_setApisV2, _SessionService_createAxiosInstanceV2, _SessionService_getSessionToken, _SessionService_getCookieValue, _SessionService_deleteRefreshToken, _SessionService_getRefreshToken, _SessionService_setSessionToken, _SessionService_deleteSessionToken, _SessionService_getSessionTokenCookieString, _SessionService_getDeleteSessionTokenCookieString, _SessionService_setRefreshToken, _SessionService_handleRefreshRequest, _SessionService_refresh, _SessionService_handleVisibilityChange, _SessionService_updateUser, _SessionService_updateAuthState, _SessionService_getSessionConfig, _SessionService_getSessionTokenCookieConfig, _SessionService_loadSessionConfig, _SessionService_getDefaultFrontendApiUrl, _SessionService_buildCorbadoFlags;
import { __classPrivateFieldGet, __classPrivateFieldSet } from "tslib";
import axios from 'axios';
import log from 'loglevel';
import { BehaviorSubject } from 'rxjs';
import { Err, Ok, Result } from 'ts-results';
import { Configuration } from '../api/v1';
import { ConfigsApi, UsersApi } from '../api/v2';
import { SessionToken } from '../models/sessionToken';
import { AuthState, base64decode, CorbadoError, PasskeyAlreadyExistsError, PasskeysNotSupported, SessionManagementNotEnabled, } from '../utils';
import { WebAuthnService } from './WebAuthnService';
const sessionTokenKey = 'cbo_session_token';
const refreshTokenKey = 'cbo_refresh_token';
// controls how long before the session-token expires we should refresh it
const sessionTokenRefreshBeforeExpirationSeconds = 60;
// controls how often we check if we need to refresh the session-token
const sessionTokenRefreshIntervalMs = 10000;
const packageVersion = '0.0.0';
/**
 * The SessionService manages user sessions for the Corbado Application, handling session-token and refresh-token.
 * It offers methods to set, delete, and retrieve these tokens and the username,
 * as well as a method to fetch the full user object from the Corbado API.
 *
 * The refresh-token should not be exposed from this service as it is only used for session refresh.
 */
export class SessionService {
    constructor(projectId, isPreviewMode, frontendApiUrlSuffix) {
        _SessionService_instances.add(this);
        _SessionService_usersApi.set(this, new UsersApi());
        _SessionService_webAuthnService.set(this, void 0);
        _SessionService_projectId.set(this, void 0);
        _SessionService_isPreviewMode.set(this, void 0);
        _SessionService_frontendApiUrlSuffix.set(this, void 0);
        _SessionService_sessionConfig.set(this, void 0);
        _SessionService_sessionToken.set(this, void 0);
        /**
         * The refresh-token is returned in the response payload from the Frontend API if the project
         * environment is set to 'dev' and set in this property. If the project environment is not set
         * to 'dev' the refresh-token will be set as a cookie from the Frontend API (not part of the
         * response payload and you can not access it from JavaScript because it is a HTTP only cookie).
         * This is needed because Safari does not allow third-party cookies and for local development
         * you will most likely run your project on a different origin than the Frontend API
         * (e.g. http://localhost:3000 vs. https://pro-xxx.cloud.frontendapi.corbado.io). On production,
         * this is "fixed" by setting a CNAME on the Frontend API to align the origins (e.g.
         * https://www.example.com and https://auth.example.com).
         */
        _SessionService_refreshToken.set(this, void 0);
        _SessionService_refreshIntervalId.set(this, void 0);
        _SessionService_userChanges.set(this, new BehaviorSubject(undefined));
        _SessionService_sessionTokenChanges.set(this, new BehaviorSubject(undefined));
        _SessionService_authStateChanges.set(this, new BehaviorSubject(AuthState.LoggedOut));
        _SessionService_updateUser.set(this, (user) => {
            const currentUser = __classPrivateFieldGet(this, _SessionService_userChanges, "f").value;
            if (currentUser === user) {
                return;
            }
            if ((currentUser === null || currentUser === void 0 ? void 0 : currentUser.email) === (user === null || user === void 0 ? void 0 : user.email) &&
                (currentUser === null || currentUser === void 0 ? void 0 : currentUser.name) === (user === null || user === void 0 ? void 0 : user.name) &&
                (currentUser === null || currentUser === void 0 ? void 0 : currentUser.orig) === (user === null || user === void 0 ? void 0 : user.orig) &&
                (currentUser === null || currentUser === void 0 ? void 0 : currentUser.sub) === (user === null || user === void 0 ? void 0 : user.sub)) {
                return;
            }
            __classPrivateFieldGet(this, _SessionService_userChanges, "f").next(user);
        });
        _SessionService_updateAuthState.set(this, (authState) => {
            if (__classPrivateFieldGet(this, _SessionService_authStateChanges, "f").value === authState) {
                return;
            }
            __classPrivateFieldGet(this, _SessionService_authStateChanges, "f").next(authState);
        });
        _SessionService_getSessionConfig.set(this, () => {
            if (!__classPrivateFieldGet(this, _SessionService_sessionConfig, "f")) {
                throw CorbadoError.missingInit();
            }
            return __classPrivateFieldGet(this, _SessionService_sessionConfig, "f");
        });
        _SessionService_getSessionTokenCookieConfig.set(this, () => {
            const cfg = __classPrivateFieldGet(this, _SessionService_getSessionConfig, "f").call(this).sessionTokenCookieConfig;
            if (!cfg) {
                throw CorbadoError.invalidConfig();
            }
            return cfg;
        });
        _SessionService_loadSessionConfig.set(this, async () => {
            const config = new Configuration({
                apiKey: __classPrivateFieldGet(this, _SessionService_projectId, "f"),
            });
            const axiosInstance = __classPrivateFieldGet(this, _SessionService_instances, "m", _SessionService_createAxiosInstanceV2).call(this);
            const configsApi = new ConfigsApi(config, __classPrivateFieldGet(this, _SessionService_instances, "m", _SessionService_getDefaultFrontendApiUrl).call(this), axiosInstance);
            return Result.wrapAsync(async () => {
                const r = await configsApi.getSessionConfig();
                return r.data;
            });
        });
        _SessionService_buildCorbadoFlags.set(this, () => {
            const flags = [];
            if (__classPrivateFieldGet(this, _SessionService_isPreviewMode, "f")) {
                flags.push('preview');
            }
            return flags.join(',');
        });
        __classPrivateFieldSet(this, _SessionService_projectId, projectId, "f");
        __classPrivateFieldSet(this, _SessionService_isPreviewMode, isPreviewMode, "f");
        __classPrivateFieldSet(this, _SessionService_frontendApiUrlSuffix, frontendApiUrlSuffix, "f");
        __classPrivateFieldSet(this, _SessionService_webAuthnService, new WebAuthnService(), "f");
        __classPrivateFieldSet(this, _SessionService_refreshToken, undefined, "f");
    }
    /**
     * Initializes the SessionService by registering a callback that is called when the session-token changes.
     */
    async init() {
        const sessionConfig = await __classPrivateFieldGet(this, _SessionService_loadSessionConfig, "f").call(this);
        if (sessionConfig.err) {
            return sessionConfig.val;
        }
        if (!sessionConfig.val.useSessionManagement) {
            throw new SessionManagementNotEnabled();
        }
        __classPrivateFieldSet(this, _SessionService_sessionConfig, sessionConfig.val, "f");
        __classPrivateFieldSet(this, _SessionService_refreshToken, __classPrivateFieldGet(_a, _a, "m", _SessionService_getRefreshToken).call(_a), "f");
        __classPrivateFieldSet(this, _SessionService_sessionToken, __classPrivateFieldGet(_a, _a, "m", _SessionService_getSessionToken).call(_a), "f");
        // if the session is valid, we emit it
        if (__classPrivateFieldGet(this, _SessionService_sessionToken, "f") && __classPrivateFieldGet(this, _SessionService_sessionToken, "f").isValidForXMoreSeconds(0)) {
            log.debug('emit session-token', __classPrivateFieldGet(this, _SessionService_sessionToken, "f"));
            __classPrivateFieldGet(this, _SessionService_instances, "m", _SessionService_onSessionTokenChange).call(this, __classPrivateFieldGet(this, _SessionService_sessionToken, "f"));
        }
        else {
            await __classPrivateFieldGet(this, _SessionService_instances, "m", _SessionService_handleRefreshRequest).call(this);
        }
        __classPrivateFieldGet(this, _SessionService_instances, "m", _SessionService_setApisV2).call(this, __classPrivateFieldGet(this, _SessionService_refreshToken, "f"));
        // init scheduled session refresh
        __classPrivateFieldSet(this, _SessionService_refreshIntervalId, setInterval(() => {
            void __classPrivateFieldGet(this, _SessionService_instances, "m", _SessionService_handleRefreshRequest).call(this);
        }, sessionTokenRefreshIntervalMs), "f");
        document.addEventListener('visibilitychange', () => {
            __classPrivateFieldGet(this, _SessionService_instances, "m", _SessionService_handleVisibilityChange).call(this);
        });
        return null;
    }
    /**
     * Getter method for retrieving the session-token.
     * @returns The session-token or null if it's not set.
     */
    get sessionToken() {
        return __classPrivateFieldGet(this, _SessionService_sessionToken, "f");
    }
    /**
     * Getter method for retrieving the username.
     * @returns The username or null if it's not set.
     */
    getUser() {
        if (!__classPrivateFieldGet(this, _SessionService_sessionToken, "f")) {
            return;
        }
        const sessionParts = __classPrivateFieldGet(this, _SessionService_sessionToken, "f").value.split('.');
        return JSON.parse(base64decode(sessionParts[1]));
    }
    /**
     * Exposes changes to the user object
     */
    get userChanges() {
        return __classPrivateFieldGet(this, _SessionService_userChanges, "f");
    }
    /**
     * Exposes changes to the session-token.
     */
    get sessionTokenChanges() {
        return __classPrivateFieldGet(this, _SessionService_sessionTokenChanges, "f");
    }
    /**
     * Exposes changes to the auth state
     */
    get authStateChanges() {
        return __classPrivateFieldGet(this, _SessionService_authStateChanges, "f");
    }
    dispose() {
        __classPrivateFieldGet(this, _SessionService_webAuthnService, "f").abortOngoingOperation();
    }
    async getFullUser(abortController) {
        return this.wrapWithErr(async () => __classPrivateFieldGet(this, _SessionService_usersApi, "f").currentUserGet({ signal: abortController.signal }));
    }
    async appendPasskey() {
        const clientInformation = await __classPrivateFieldGet(this, _SessionService_webAuthnService, "f").getClientInformation();
        const respStart = await __classPrivateFieldGet(this, _SessionService_usersApi, "f").currentUserPasskeyAppendStart({
            clientInformation: clientInformation,
        });
        if (respStart.data.newClientEnvHandle) {
            WebAuthnService.setClientHandle(respStart.data.newClientEnvHandle);
        }
        if (respStart.data.appendNotAllowedReason) {
            switch (respStart.data.appendNotAllowedReason) {
                case 'passkey_already_exists':
                    return Err(new PasskeyAlreadyExistsError());
                case 'passkeys_not_supported':
                    return Err(new PasskeysNotSupported());
                default:
                    return Err(CorbadoError.ignore());
            }
        }
        const signedChallenge = await __classPrivateFieldGet(this, _SessionService_webAuthnService, "f").createPasskey(respStart.data.attestationOptions);
        if (signedChallenge.err) {
            return signedChallenge;
        }
        await __classPrivateFieldGet(this, _SessionService_usersApi, "f").currentUserPasskeyAppendFinish({
            attestationResponse: signedChallenge.val,
            clientInformation: clientInformation,
        });
        return Ok(void 0);
    }
    async passkeyList(abortController) {
        return this.wrapWithErr(async () => __classPrivateFieldGet(this, _SessionService_usersApi, "f").currentUserPasskeyGet({ signal: abortController.signal }));
    }
    async passkeyDelete(id) {
        return Result.wrapAsync(async () => {
            await __classPrivateFieldGet(this, _SessionService_usersApi, "f").currentUserPasskeyDelete(id);
            return void 0;
        });
    }
    async logout() {
        log.debug('logging out user');
        await Result.wrapAsync(async () => {
            await __classPrivateFieldGet(this, _SessionService_usersApi, "f").currentUserSessionLogout({});
        });
        this.clear();
        __classPrivateFieldGet(this, _SessionService_instances, "m", _SessionService_onSessionTokenChange).call(this, undefined);
    }
    /** Method to set Session
     * It sets the session-token, refresh-token, and username for the Corbado Application.
     * @param sessionToken The session-token to be set.
     * @param refreshToken The refresh-token to be set.
     */
    setSession(sessionToken, refreshToken) {
        const sessionTokenModel = new SessionToken(sessionToken);
        __classPrivateFieldGet(this, _SessionService_instances, "m", _SessionService_setSessionToken).call(this, sessionTokenModel);
        __classPrivateFieldGet(this, _SessionService_instances, "m", _SessionService_setApisV2).call(this, refreshToken !== null && refreshToken !== void 0 ? refreshToken : '');
        __classPrivateFieldGet(this, _SessionService_instances, "m", _SessionService_onSessionTokenChange).call(this, sessionTokenModel);
        __classPrivateFieldGet(this, _SessionService_instances, "m", _SessionService_setRefreshToken).call(this, refreshToken);
    }
    /**
     * Method to delete Session.
     * It deletes the session-token, refresh-token and username for the Corbado Application.
     */
    clear() {
        __classPrivateFieldGet(this, _SessionService_instances, "m", _SessionService_deleteSessionToken).call(this);
        __classPrivateFieldGet(this, _SessionService_instances, "m", _SessionService_deleteRefreshToken).call(this);
        if (__classPrivateFieldGet(this, _SessionService_refreshIntervalId, "f")) {
            clearInterval(__classPrivateFieldGet(this, _SessionService_refreshIntervalId, "f"));
        }
    }
    async wrapWithErr(callback) {
        try {
            const r = await callback();
            return Ok(r.data);
        }
        catch (e) {
            if (e instanceof CorbadoError) {
                return Err(e);
            }
            return Err(CorbadoError.fromUnknownFrontendError(e));
        }
    }
}
_a = SessionService, _SessionService_usersApi = new WeakMap(), _SessionService_webAuthnService = new WeakMap(), _SessionService_projectId = new WeakMap(), _SessionService_isPreviewMode = new WeakMap(), _SessionService_frontendApiUrlSuffix = new WeakMap(), _SessionService_sessionConfig = new WeakMap(), _SessionService_sessionToken = new WeakMap(), _SessionService_refreshToken = new WeakMap(), _SessionService_refreshIntervalId = new WeakMap(), _SessionService_userChanges = new WeakMap(), _SessionService_sessionTokenChanges = new WeakMap(), _SessionService_authStateChanges = new WeakMap(), _SessionService_updateUser = new WeakMap(), _SessionService_updateAuthState = new WeakMap(), _SessionService_getSessionConfig = new WeakMap(), _SessionService_getSessionTokenCookieConfig = new WeakMap(), _SessionService_loadSessionConfig = new WeakMap(), _SessionService_buildCorbadoFlags = new WeakMap(), _SessionService_instances = new WeakSet(), _SessionService_onSessionTokenChange = function _SessionService_onSessionTokenChange(sessionToken) {
    const user = this.getUser();
    if (user && sessionToken) {
        __classPrivateFieldGet(this, _SessionService_sessionTokenChanges, "f").next(sessionToken.value);
        __classPrivateFieldGet(this, _SessionService_updateAuthState, "f").call(this, AuthState.LoggedIn);
        __classPrivateFieldGet(this, _SessionService_updateUser, "f").call(this, user);
    }
    else {
        log.debug('user is logged out', user, sessionToken);
        __classPrivateFieldGet(this, _SessionService_sessionTokenChanges, "f").next(undefined);
        __classPrivateFieldGet(this, _SessionService_updateAuthState, "f").call(this, AuthState.LoggedOut);
        __classPrivateFieldGet(this, _SessionService_updateUser, "f").call(this, undefined);
    }
}, _SessionService_setApisV2 = function _SessionService_setApisV2(refreshToken) {
    let frontendApiUrl = __classPrivateFieldGet(this, _SessionService_getSessionConfig, "f").call(this).frontendApiUrl;
    if (!frontendApiUrl || frontendApiUrl.length === 0) {
        frontendApiUrl = __classPrivateFieldGet(this, _SessionService_instances, "m", _SessionService_getDefaultFrontendApiUrl).call(this);
    }
    const config = new Configuration({
        apiKey: __classPrivateFieldGet(this, _SessionService_projectId, "f"),
        basePath: frontendApiUrl,
    });
    const axiosInstance = __classPrivateFieldGet(this, _SessionService_instances, "m", _SessionService_createAxiosInstanceV2).call(this, refreshToken);
    __classPrivateFieldSet(this, _SessionService_usersApi, new UsersApi(config, frontendApiUrl, axiosInstance), "f");
}, _SessionService_createAxiosInstanceV2 = function _SessionService_createAxiosInstanceV2(refreshToken) {
    const corbadoVersion = {
        name: 'web-core',
        sdkVersion: packageVersion,
    };
    const headers = {
        'Content-Type': 'application/json',
        'X-Corbado-SDK': JSON.stringify(corbadoVersion), // Example default version
        'X-Corbado-Client-Timezone': Intl.DateTimeFormat().resolvedOptions().timeZone,
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache',
        Expires: '0',
    };
    headers['X-Corbado-Flags'] = __classPrivateFieldGet(this, _SessionService_buildCorbadoFlags, "f").call(this);
    let axiosInstance;
    if (refreshToken) {
        axiosInstance = axios.create({
            withCredentials: true,
            headers: { ...headers, Authorization: `Bearer ${refreshToken}` },
        });
    }
    else {
        axiosInstance = axios.create({
            withCredentials: true,
        });
    }
    // We transform AxiosErrors into CorbadoErrors using axios interceptors.
    axiosInstance.interceptors.response.use(response => response, (error) => {
        const e = CorbadoError.fromAxiosError(error);
        log.warn('error', e);
        return Promise.reject(e);
    });
    return axiosInstance;
}, _SessionService_getSessionToken = function _SessionService_getSessionToken() {
    const sessionToken = __classPrivateFieldGet(this, _a, "m", _SessionService_getCookieValue).call(this, sessionTokenKey);
    if (sessionToken) {
        return new SessionToken(sessionToken);
    }
    return undefined;
}, _SessionService_getCookieValue = function _SessionService_getCookieValue(name) {
    const regex = new RegExp(`(^| )${name}=([^;]+)`);
    const match = document.cookie.match(regex);
    if (match) {
        return match[2];
    }
    return undefined;
}, _SessionService_deleteRefreshToken = function _SessionService_deleteRefreshToken() {
    localStorage.removeItem(refreshTokenKey);
    __classPrivateFieldSet(this, _SessionService_refreshToken, '', "f");
}, _SessionService_getRefreshToken = function _SessionService_getRefreshToken() {
    var _b;
    return (_b = localStorage.getItem(refreshTokenKey)) !== null && _b !== void 0 ? _b : '';
}, _SessionService_setSessionToken = function _SessionService_setSessionToken(value) {
    __classPrivateFieldSet(this, _SessionService_sessionToken, value, "f");
    const cookieConfig = __classPrivateFieldGet(this, _SessionService_getSessionTokenCookieConfig, "f").call(this);
    document.cookie = __classPrivateFieldGet(this, _SessionService_instances, "m", _SessionService_getSessionTokenCookieString).call(this, cookieConfig, value);
}, _SessionService_deleteSessionToken = function _SessionService_deleteSessionToken() {
    __classPrivateFieldSet(this, _SessionService_sessionToken, undefined, "f");
    const cookieConfig = __classPrivateFieldGet(this, _SessionService_getSessionTokenCookieConfig, "f").call(this);
    document.cookie = __classPrivateFieldGet(this, _SessionService_instances, "m", _SessionService_getDeleteSessionTokenCookieString).call(this, cookieConfig);
}, _SessionService_getSessionTokenCookieString = function _SessionService_getSessionTokenCookieString(config, value) {
    const expires = new Date(Date.now() + config.lifetimeSeconds * 1000).toUTCString();
    return `${sessionTokenKey}=${value}; domain=${config.domain}; ${config.secure ? 'secure; ' : ''}sameSite=${config.sameSite}; path=${config.path}; expires=${expires}`;
}, _SessionService_getDeleteSessionTokenCookieString = function _SessionService_getDeleteSessionTokenCookieString(config) {
    return `${sessionTokenKey}=; domain=${config.domain}; ${config.secure ? 'secure; ' : ''}sameSite=${config.sameSite}; path=${config.path}; expires=${new Date().toUTCString()}`;
}, _SessionService_setRefreshToken = function _SessionService_setRefreshToken(refreshToken) {
    if (!refreshToken) {
        return;
    }
    localStorage.setItem(refreshTokenKey, refreshToken);
    __classPrivateFieldSet(this, _SessionService_refreshToken, refreshToken, "f");
}, _SessionService_handleRefreshRequest = async function _SessionService_handleRefreshRequest() {
    // no session-token => user is not logged in => nothing to refresh
    if (!__classPrivateFieldGet(this, _SessionService_sessionToken, "f")) {
        log.debug('session refresh: no refresh, user not logged in');
        return;
    }
    // refresh, token too old
    if (!__classPrivateFieldGet(this, _SessionService_sessionToken, "f").isValidForXMoreSeconds(sessionTokenRefreshBeforeExpirationSeconds)) {
        await __classPrivateFieldGet(this, _SessionService_instances, "m", _SessionService_refresh).call(this);
    }
    // nothing to do for now
    log.debug('no refresh, no refresh, token still valid');
    return;
}, _SessionService_refresh = async function _SessionService_refresh() {
    log.debug('session refresh: starting refresh');
    try {
        const options = {
            headers: {
                Authorization: `Bearer ${__classPrivateFieldGet(this, _SessionService_refreshToken, "f")}`,
            },
        };
        const response = await __classPrivateFieldGet(this, _SessionService_usersApi, "f").currentUserSessionRefresh(options);
        if (response.status !== 200) {
            log.warn(`refresh error, status code: ${response.status}`);
            return;
        }
        if (!response.data.sessionToken) {
            log.warn('refresh error, missing session-token');
            return;
        }
        this.setSession(response.data.sessionToken, undefined);
    }
    catch (e) {
        // if it's a network error, we should do a retry
        // for all other errors, we should log out the user
        log.warn(e);
        await this.logout();
    }
}, _SessionService_handleVisibilityChange = function _SessionService_handleVisibilityChange() {
    if (document.hidden) {
        log.debug('session refresh: no refresh, page is hidden');
        return;
    }
    try {
        void __classPrivateFieldGet(this, _SessionService_instances, "m", _SessionService_handleRefreshRequest).call(this);
    }
    catch (e) {
        log.error(e);
    }
}, _SessionService_getDefaultFrontendApiUrl = function _SessionService_getDefaultFrontendApiUrl() {
    return `https://${__classPrivateFieldGet(this, _SessionService_projectId, "f")}.${__classPrivateFieldGet(this, _SessionService_frontendApiUrlSuffix, "f")}`;
};
