import { createDarkTheme, createTheme } from "baseui";

/* Modern Dark Theme Color Palette
- Primary: cyan-400 #22d3ee (vibrant accent)
- Secondary: fuchsia-500 #d946ef (contrast accent)
- Dark BG: zinc-900 #18181b (main background)
- Surface: zinc-800 #27272a (card/component background)
- Success: emerald-400 #34d399 (success indicator)
- Warning: amber-400 #fbbf24 (warnings)
- Error: rose-500 #f43f5e (errors/danger)
*/
export const colors = {
  slate: {
    50: "#f8fafc",
    100: "#f1f5f9",
    200: "#e2e8f0",
    300: "#cbd5e1",
    400: "#94a3b8",
    500: "#64748b",
    600: "#475569",
    700: "#334155",
    800: "#1e293b",
    900: "#0f172a",
    950: "#020617"
  },
  zinc: {
    50: "#fafafa",
    100: "#f4f4f5",
    200: "#e4e4e7",
    300: "#d4d4d8",
    400: "#a1a1aa",
    500: "#71717a",
    600: "#52525b",
    700: "#3f3f46",
    800: "#27272a", // Surface level dark
    900: "#18181b", // Primary dark background
    950: "#09090b"
  },
  cyan: {
    50: "#ecfeff",
    100: "#cffafe",
    200: "#a5f3fc",
    300: "#67e8f9",
    400: "#22d3ee", // Primary vibrant accent
    500: "#06b6d4",
    600: "#0891b2",
    700: "#0e7490",
    800: "#155e75",
    900: "#164e63",
    950: "#083344"
  },
  fuchsia: {
    50: "#fdf4ff",
    100: "#fae8ff",
    200: "#f5d0fe",
    300: "#f0abfc",
    400: "#e879f9",
    500: "#d946ef", // Secondary accent
    600: "#c026d3",
    700: "#a21caf",
    800: "#86198f",
    900: "#701a75",
    950: "#4a044e"
  },
  emerald: {
    50: "#ecfdf5",
    100: "#d1fae5",
    200: "#a7f3d0",
    300: "#6ee7b7",
    400: "#34d399", // Success indicator
    500: "#10b981",
    600: "#059669",
    700: "#047857",
    800: "#065f46",
    900: "#064e3b",
    950: "#022c22"
  },
  amber: {
    50: "#fffbeb",
    100: "#fef3c7",
    200: "#fde68a",
    300: "#fcd34d",
    400: "#fbbf24", // Warning indicator
    500: "#f59e0b",
    600: "#d97706",
    700: "#b45309",
    800: "#92400e",
    900: "#78350f",
    950: "#451a03"
  },
  rose: {
    50: "#fff1f2",
    100: "#ffe4e6",
    200: "#fecdd3",
    300: "#fda4af",
    400: "#fb7185",
    500: "#f43f5e", // Error indicator
    600: "#e11d48",
    700: "#be123c",
    800: "#9f1239",
    900: "#881337",
    950: "#4c0519"
  }
};

const primitives = {
  // Primary accent (Cyan)
  accent: colors.cyan[400],
  accent50: colors.cyan[50],
  accent100: colors.cyan[100],
  accent200: colors.cyan[200],
  accent300: colors.cyan[300],
  accent400: colors.cyan[400],
  accent500: colors.cyan[500],
  accent600: colors.cyan[600],
  accent700: colors.cyan[700],
  accent800: colors.cyan[800],
  accent900: colors.cyan[900],
  accent950: colors.cyan[950],
  
  // Error accent (Rose)
  accentNegative: colors.rose[500],
  accentNegative50: colors.rose[50],
  accentNegative100: colors.rose[100],
  accentNegative200: colors.rose[200],
  accentNegative300: colors.rose[300],
  accentNegative400: colors.rose[400],
  accentNegative500: colors.rose[500],
  accentNegative600: colors.rose[600],
  accentNegative700: colors.rose[700],
  accentNegative800: colors.rose[800],
  accentNegative900: colors.rose[900],
  accentNegative950: colors.rose[950],
  
  // Warning accent (Amber)
  accentWarning: colors.amber[400],
  accentWarning50: colors.amber[50],
  accentWarning100: colors.amber[100],
  accentWarning200: colors.amber[200],
  accentWarning300: colors.amber[300],
  accentWarning400: colors.amber[400],
  accentWarning500: colors.amber[500],
  accentWarning600: colors.amber[600],
  accentWarning700: colors.amber[700],
  accentWarning800: colors.amber[800],
  accentWarning900: colors.amber[900],
  accentWarning950: colors.amber[950],
  
  // Positive/Success accent (Emerald)
  accentPositive: colors.emerald[400],
  accentPositive50: colors.emerald[50],
  accentPositive100: colors.emerald[100],
  accentPositive200: colors.emerald[200],
  accentPositive300: colors.emerald[300],
  accentPositive400: colors.emerald[400],
  accentPositive500: colors.emerald[500],
  accentPositive600: colors.emerald[600],
  accentPositive700: colors.emerald[700],
  accentPositive800: colors.emerald[800],
  accentPositive900: colors.emerald[900],
  accentPositive950: colors.emerald[950],
  
  // Information accent (Fuchsia)
  accentInformation: colors.fuchsia[500],
  accentInformation50: colors.fuchsia[50],
  accentInformation100: colors.fuchsia[100],
  accentInformation200: colors.fuchsia[200],
  accentInformation300: colors.fuchsia[300],
  accentInformation400: colors.fuchsia[400],
  accentInformation500: colors.fuchsia[500],
  accentInformation600: colors.fuchsia[600],
  accentInformation700: colors.fuchsia[700],
  accentInformation800: colors.fuchsia[800],
  accentInformation900: colors.fuchsia[900],
  accentInformation950: colors.fuchsia[950],
  
  // Light accent (for light surfaces)
  accentLight: colors.slate[100],
  accentLight50: colors.slate[50],
  accentLight100: colors.slate[100],
  accentLight200: colors.slate[200],
  accentLight300: colors.slate[300],
  accentLight400: colors.slate[400],
  accentLight500: colors.slate[500],
  accentLight600: colors.slate[600],
  accentLight700: colors.slate[700],
  accentLight800: colors.slate[800],
  accentLight900: colors.slate[900],
  accentLight950: colors.slate[950],
  
  // Dark accent (for dark surfaces)
  accentDark: colors.zinc[900],
  accentDark50: colors.zinc[50],
  accentDark100: colors.zinc[100],
  accentDark200: colors.zinc[200],
  accentDark300: colors.zinc[300],
  accentDark400: colors.zinc[400],
  accentDark500: colors.zinc[500],
  accentDark600: colors.zinc[600],
  accentDark700: colors.zinc[700],
  accentDark800: colors.zinc[800],
  accentDark900: colors.zinc[900],
  accentDark950: colors.zinc[950],
  
  // Content colors
  contentPrimary: colors.slate[50],
  contentSecondary: colors.slate[300],
  contentTertiary: colors.slate[400],
  contentNegative: colors.rose[400],
  contentWarning: colors.amber[400],
  contentPositive: colors.emerald[400],
  contentInformation: colors.fuchsia[400],
  contentLight: colors.slate[200],
  contentDark: colors.zinc[800],
  contentBackground: colors.zinc[900],
  
  // Background colors
  backgroundPrimary: colors.zinc[900],
  backgroundSecondary: colors.zinc[800],
  backgroundTertiary: colors.zinc[700],
  backgroundNegative: colors.rose[900],
  backgroundWarning: colors.amber[900],
  backgroundPositive: colors.emerald[900]
};

const overrides = {
  primitives,
  colors: {
    // Primary button (Cyan)
    buttonPrimaryFill: colors.cyan[400],
    buttonPrimaryText: colors.zinc[950],
    buttonPrimaryHover: colors.cyan[500],
    buttonPrimaryActive: colors.cyan[600],
    buttonPrimarySelectedFill: colors.cyan[500],
    buttonPrimarySelectedText: colors.zinc[950],
    buttonPrimarySpinnerForeground: colors.cyan[200],
    buttonPrimarySpinnerBackground: colors.cyan[600],

    // Secondary button (Fuchsia)
    buttonSecondaryFill: colors.fuchsia[500],
    buttonSecondaryText: colors.zinc[950], 
    buttonSecondaryHover: colors.fuchsia[600],
    buttonSecondaryActive: colors.fuchsia[700],
    buttonSecondarySelectedFill: colors.fuchsia[600],
    buttonSecondarySelectedText: colors.zinc[950],
    buttonSecondarySpinnerForeground: colors.fuchsia[200],
    buttonSecondarySpinnerBackground: colors.fuchsia[700],
    
    // Tertiary button (outline/ghost style)
    buttonTertiaryFill: 'transparent',
    buttonTertiaryText: colors.cyan[400],
    buttonTertiaryHover: colors.zinc[800],
    buttonTertiaryActive: colors.zinc[700],
    buttonTertiarySelectedFill: colors.zinc[800],
    buttonTertiarySelectedText: colors.cyan[300],
    buttonTertiarySpinnerForeground: colors.cyan[300],
    buttonTertiarySpinnerBackground: 'rgba(0, 0, 0, 0.2)',

    // Content colors (for text)
    contentPrimary: colors.slate[50],
    contentSecondary: colors.slate[300],
    contentTertiary: colors.slate[400],
    contentInversePrimary: colors.zinc[900],
    contentInverseSecondary: colors.zinc[800],
    contentInverseTertiary: colors.zinc[700],

    // Background colors
    backgroundPrimary: colors.zinc[900],
    backgroundSecondary: colors.zinc[800],
    backgroundTertiary: colors.zinc[700],
    backgroundInversePrimary: colors.slate[50],
    backgroundInverseSecondary: colors.slate[100],
    backgroundInverseTertiary: colors.slate[200],
    
    // Input fields
    inputFill: colors.zinc[800],
    inputFillError: colors.rose[900],
    inputFillDisabled: colors.zinc[700],
    inputTextDisabled: colors.slate[500],
    inputBorderRadius: '8px',
    inputBorder: colors.zinc[700],
    inputBorderError: colors.rose[500],
    
    // Link colors
    linkText: colors.cyan[400],
    linkVisited: colors.fuchsia[400],
    linkHover: colors.cyan[300],
    linkActive: colors.cyan[200],
    
    // Borders
    borderOpaque: colors.zinc[700],
    borderSelected: colors.cyan[400],
    borderFocus: colors.cyan[400],
    
    // Shadows for depth
    shadowColor: 'rgba(0, 0, 0, 0.5)',
    
    // Typography
    primaryFontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif"
  }
};

export const LightStealTheme = createTheme({
  ...overrides,
  primitives,
  name: "LightStealTheme"
});
export const DarkStealTheme = createDarkTheme({
  ...overrides,
  primitives,
  name: "DarkStealTheme"
});
