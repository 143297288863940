import React, { memo } from 'react';
import { useCorbado } from '../../hooks/useCorbado';
import useTheme from '../../hooks/useTheme';
import { PasskeyDefaultIcon } from '../ui/icons/PasskeyDefaultIcon';
import { PasskeyDelete } from './PasskeyDelete';
import { PasskeyDetails } from './PasskeyDetails';
export const PasskeyListItem = memo(({ passkey, fetchPasskeys }) => {
    const { deletePasskey } = useCorbado();
    const { darkMode } = useTheme();
    const icon = darkMode ? passkey.aaguidDetails.iconDark : passkey.aaguidDetails.iconLight;
    const handleDeletePasskey = async (id) => {
        await deletePasskey(id);
        await fetchPasskeys();
    };
    return (React.createElement("div", { key: passkey.id, className: 'cb-passkey-list-card' },
        React.createElement("div", { className: 'cb-passkey-list-icon cb-passkey-list-icon-left' }, icon ? (React.createElement("img", { src: icon, alt: passkey.authenticatorAAGUID })) : (React.createElement(PasskeyDefaultIcon, null))),
        React.createElement(PasskeyDetails, { passkey: passkey }),
        React.createElement(PasskeyDelete, { passkeyId: passkey.id, onPasskeyDelete: handleDeletePasskey })));
});
