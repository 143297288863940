import { BlockTypes, InitState, ScreenNames } from '@corbado/shared-ui';
import React, { useEffect, useMemo, useState } from 'react';
import useErrorHandling from '../../hooks/useErrorHandling';
import useFlowHandler from '../../hooks/useFlowHandler';
import { EditEmail } from '../../screens/auth-blocks/email-verify/EditEmail';
import { EmailLinkSent } from '../../screens/auth-blocks/email-verify/EmailLinkSent';
import { EmailLinkSuccess } from '../../screens/auth-blocks/email-verify/EmailLinkSuccess';
import { EmailLinkVerification } from '../../screens/auth-blocks/email-verify/EmailLinkVerification';
import { EmailOtp } from '../../screens/auth-blocks/email-verify/EmailOtp';
import { LoginInit } from '../../screens/auth-blocks/login-init/LoginInit';
import { MissingFields } from '../../screens/auth-blocks/missing-fields/MissingFields';
import { EditUserData } from '../../screens/auth-blocks/passkey-append/EditUserData';
import { PasskeyAppend } from '../../screens/auth-blocks/passkey-append/PasskeyAppend';
import { PasskeyAppendAfterError } from '../../screens/auth-blocks/passkey-append/PasskeyAppendAfterError';
import { PasskeyAppendAfterHybrid } from '../../screens/auth-blocks/passkey-append/PasskeyAppendAfterHybrid';
import { PasskeyError as PasskeyAppendPasskeyError } from '../../screens/auth-blocks/passkey-append/PasskeyError';
import { PasskeyAppended } from '../../screens/auth-blocks/passkey-appended/PasskeyAppended';
import { PasskeyBackground } from '../../screens/auth-blocks/passkey-verify/PasskeyBackground';
import { PasskeyError as PasskeyVerifyPasskeyError } from '../../screens/auth-blocks/passkey-verify/PasskeyError';
import { PasskeyErrorLight } from '../../screens/auth-blocks/passkey-verify/PasskeyErrorLight';
import { PasskeyHybrid } from '../../screens/auth-blocks/passkey-verify/PasskeyHybrid';
import { EditPhone } from '../../screens/auth-blocks/phone-verify/EditPhone';
import { PhoneOtp } from '../../screens/auth-blocks/phone-verify/PhoneOtp';
import { SignupInit } from '../../screens/auth-blocks/signup-init/SignupInit';
import { ComponentUnavailableError } from '../ui/errors/ComponentUnavailable';
import ErrorPopup from '../ui/errors/ErrorPopup';
import { FreemiumBadge } from '../ui/FreemiumBadge';
import { LoadingSpinner } from '../ui/LoadingSpinner';
export const AuthFlow = ({ initialAutoFocus }) => {
    const { isDevMode, customerSupportEmail } = useErrorHandling();
    const { currentScreen, initState } = useFlowHandler();
    const [loading, setLoading] = useState(false);
    const [hideBadge, setHideBadge] = useState(false);
    useEffect(() => {
        // this delays showing a loading screen for a very short period of time
        // the idea is to reduce flickering when the loading screen is only shown for a very short time
        if (initState !== InitState.Initializing || loading) {
            setLoading(initState === InitState.Initializing);
            return;
        }
        const timeout = setTimeout(() => {
            setLoading(true);
        }, 100);
        return () => {
            clearTimeout(timeout);
        };
    }, [initState]);
    const screenComponent = useMemo(() => {
        if (!currentScreen) {
            return null;
        }
        if (currentScreen.block.common.hideBadge) {
            setHideBadge(true);
        }
        switch (currentScreen.block.type) {
            case BlockTypes.LoginInit:
                return (React.createElement(LoginInit, { block: currentScreen.block, initialAutoFocus: initialAutoFocus }));
            case BlockTypes.SignupInit:
                return (React.createElement(SignupInit, { block: currentScreen.block, initialAutoFocus: initialAutoFocus }));
            case BlockTypes.EmailVerify:
                switch (currentScreen.screen) {
                    case ScreenNames.EmailLinkSent:
                        return React.createElement(EmailLinkSent, { block: currentScreen.block });
                    case ScreenNames.EmailOtpVerification:
                        return React.createElement(EmailOtp, { block: currentScreen.block });
                    case ScreenNames.EmailLinkVerification:
                        return React.createElement(EmailLinkVerification, { block: currentScreen.block });
                    case ScreenNames.EditEmail:
                        return React.createElement(EditEmail, { block: currentScreen.block });
                    default:
                        throw new Error(`Invalid screen: ${currentScreen.screen}`);
                }
            case BlockTypes.PhoneVerify:
                switch (currentScreen.screen) {
                    case ScreenNames.PhoneOtp:
                        return React.createElement(PhoneOtp, { block: currentScreen.block });
                    case ScreenNames.EditPhone:
                        return React.createElement(EditPhone, { block: currentScreen.block });
                    default:
                        throw new Error(`Invalid screen: ${currentScreen.screen}`);
                }
            case BlockTypes.PasskeyAppend:
                switch (currentScreen.screen) {
                    case ScreenNames.PasskeyAppendAfterHybrid:
                        return React.createElement(PasskeyAppendAfterHybrid, { block: currentScreen.block });
                    case ScreenNames.PasskeyAppendAfterError:
                        return React.createElement(PasskeyAppendAfterError, { block: currentScreen.block });
                    case ScreenNames.PasskeyAppend:
                        return React.createElement(PasskeyAppend, { block: currentScreen.block });
                    case ScreenNames.PasskeyError:
                        return React.createElement(PasskeyAppendPasskeyError, { block: currentScreen.block });
                    case ScreenNames.EditUserData:
                        return React.createElement(EditUserData, { block: currentScreen.block });
                    default:
                        throw new Error(`Invalid screen: ${currentScreen.screen}`);
                }
            case BlockTypes.PasskeyVerify:
                switch (currentScreen.screen) {
                    case ScreenNames.PasskeyBackground:
                        return React.createElement(PasskeyBackground, { block: currentScreen.block });
                    case ScreenNames.PasskeyHybrid:
                        return React.createElement(PasskeyHybrid, { block: currentScreen.block });
                    case ScreenNames.PasskeyErrorLight:
                        return React.createElement(PasskeyErrorLight, { block: currentScreen.block });
                    case ScreenNames.PasskeyError:
                        return React.createElement(PasskeyVerifyPasskeyError, { block: currentScreen.block });
                    default:
                        throw new Error(`Invalid screen: ${currentScreen.screen}`);
                }
            case BlockTypes.PasskeyAppended:
                return React.createElement(PasskeyAppended, { block: currentScreen.block });
            case BlockTypes.Completed:
                return null;
            case BlockTypes.ContinueOnOtherEnv:
                return React.createElement(EmailLinkSuccess, { block: currentScreen.block });
            case BlockTypes.MissingFields:
                return React.createElement(MissingFields, { block: currentScreen.block });
        }
    }, [currentScreen]);
    const renderContent = () => {
        if (initState === InitState.Failed) {
            return React.createElement(ComponentUnavailableError, null);
        }
        if (loading) {
            return React.createElement(LoadingSpinner, { className: 'cb-initital-loading-spinner' });
        }
        return (React.createElement(React.Fragment, null,
            (currentScreen === null || currentScreen === void 0 ? void 0 : currentScreen.block.error) && (React.createElement(ErrorPopup, { isDevMode: isDevMode, error: currentScreen === null || currentScreen === void 0 ? void 0 : currentScreen.block.error, customerSupportEmail: customerSupportEmail })),
            React.createElement("div", { className: 'cb-container-body' }, screenComponent)));
    };
    return (React.createElement("div", { style: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        } },
        React.createElement("div", { className: 'cb-container' }, renderContent()),
        hideBadge ? null : React.createElement(FreemiumBadge, null)));
};
