import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InputField, PhoneInputField, PrimaryButton } from '../../ui';
export const LoginForm = ({ block, loading, socialLoadingInProgress, setLoading, initialAutoFocus, }) => {
    const { t } = useTranslation('translation', { keyPrefix: `login.login-init.login-init` });
    const [textField, setTextField] = useState(null);
    const [usePhone, setUsePhone] = useState(block.data.isPhoneFocused || !(block.data.emailEnabled || block.data.usernameEnabled));
    const [phoneInput, setPhoneInput] = useState('');
    const hasBothEmailAndUsername = block.data.emailEnabled && block.data.usernameEnabled;
    const textFieldRef = useRef();
    useEffect(() => {
        const shouldUsePhone = block.data.isPhoneFocused || !(block.data.emailEnabled || block.data.usernameEnabled);
        if (shouldUsePhone) {
            setUsePhone(true);
        }
        setTextField({ value: block.data.loginIdentifier, translatedError: block.data.loginIdentifierError });
        if (!textFieldRef.current) {
            return;
        }
        if (initialAutoFocus) {
            textFieldRef.current.focus();
        }
        textFieldRef.current.value = block.data.loginIdentifier ? block.data.loginIdentifier : '';
    }, [block]);
    const submitButtonText = useMemo(() => t('button_submit'), [t]);
    const IdentifierInputField = useMemo(() => {
        const commonProps = {
            errorMessage: textField === null || textField === void 0 ? void 0 : textField.translatedError,
            ref: (el) => el && (textFieldRef.current = el),
        };
        if (usePhone) {
            let fieldLinkText = undefined;
            if (hasBothEmailAndUsername) {
                fieldLinkText = t('button_switchToAlternate.emailOrUsername');
            }
            else if (block.data.emailEnabled) {
                fieldLinkText = t('button_switchToAlternate.email');
            }
            else if (block.data.usernameEnabled) {
                fieldLinkText = t('button_switchToAlternate.username');
            }
            const fieldLink = fieldLinkText
                ? {
                    text: fieldLinkText,
                    onClick: () => setUsePhone(false),
                }
                : undefined;
            return (React.createElement(PhoneInputField, { label: t('textField.phone'), labelLink: fieldLink, id: 'phone', autoComplete: 'tel', initialCountry: 'US', initialPhoneNumber: textField === null || textField === void 0 ? void 0 : textField.value, errorMessage: textField === null || textField === void 0 ? void 0 : textField.translatedError, autoFocus: initialAutoFocus, onChange: setPhoneInput }));
        }
        commonProps.labelLink = block.data.phoneEnabled
            ? {
                text: t('button_switchToAlternate.phone'),
                onClick: () => setUsePhone(true),
            }
            : undefined;
        if (hasBothEmailAndUsername || block.data.usernameEnabled) {
            return (React.createElement(InputField, { label: hasBothEmailAndUsername ? t('textField.emailOrUsername') : t('textField.username'), id: 'username', name: 'username', type: 'username', autoComplete: 'username webauthn', autoFocus: initialAutoFocus, ...commonProps }));
        }
        // we set autocomplete to username webauthn because Safari and Firefox need this for conditional UI to work
        return (React.createElement(InputField, { label: t('textField.email'), id: 'email', name: 'email', type: 'email', autoComplete: 'username webauthn', autoFocus: initialAutoFocus, ...commonProps }));
    }, [block, t, textField, usePhone]);
    const handleSubmit = useCallback((e) => {
        var _a, _b;
        e.preventDefault();
        setLoading(true);
        if (usePhone) {
            void block.start(phoneInput, true);
        }
        else {
            void block.start((_b = (_a = textFieldRef.current) === null || _a === void 0 ? void 0 : _a.value) !== null && _b !== void 0 ? _b : '', false);
        }
    }, [block, usePhone, phoneInput]);
    return (React.createElement("form", { className: 'cb-form', onSubmit: handleSubmit },
        IdentifierInputField,
        React.createElement(PrimaryButton, { type: 'submit', className: 'cb-signup-form-submit-button', isLoading: loading, disabled: socialLoadingInProgress }, submitButtonText)));
};
