var _WebAuthnService_abortController, _WebAuthnService_visitorId;
import { __classPrivateFieldGet, __classPrivateFieldSet } from "tslib";
import { create, get } from '@corbado/webauthn-json';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import { detectIncognito } from 'detectincognitojs';
import log from 'loglevel';
import { Err, Ok } from 'ts-results';
import { CorbadoError } from '../utils';
const clientHandleKey = 'cbo_client_handle';
/**
 * AuthenticatorService handles all interactions with webAuthn platform authenticators.
 * Currently, this includes the creation of passkeys and the login with existing passkeys.
 */
export class WebAuthnService {
    constructor() {
        _WebAuthnService_abortController.set(this, void 0);
        _WebAuthnService_visitorId.set(this, void 0);
    }
    async createPasskey(serializedChallenge) {
        try {
            const abortController = this.abortOngoingOperation();
            const challenge = JSON.parse(serializedChallenge);
            challenge.signal = abortController.signal;
            __classPrivateFieldSet(this, _WebAuthnService_abortController, abortController, "f");
            const signedChallenge = await create(challenge);
            const serializedResponse = JSON.stringify(signedChallenge);
            return Ok(serializedResponse);
        }
        catch (e) {
            if (e instanceof DOMException) {
                return Err(CorbadoError.fromDOMException(e));
            }
            else {
                return Err(CorbadoError.fromUnknownFrontendError(e));
            }
        }
    }
    async login(serializedChallenge, conditional, onConditionalLoginStart) {
        try {
            const abortController = this.abortOngoingOperation();
            const challenge = JSON.parse(serializedChallenge);
            challenge.signal = abortController.signal;
            __classPrivateFieldSet(this, _WebAuthnService_abortController, abortController, "f");
            onConditionalLoginStart === null || onConditionalLoginStart === void 0 ? void 0 : onConditionalLoginStart(abortController);
            if (conditional) {
                challenge.mediation = 'conditional';
            }
            const signedChallenge = await get(challenge);
            const serializedResponse = JSON.stringify(signedChallenge);
            return Ok(serializedResponse);
        }
        catch (e) {
            if (e instanceof DOMException) {
                return Err(CorbadoError.fromDOMException(e));
            }
            else {
                return Err(CorbadoError.fromUnknownFrontendError(e));
            }
        }
    }
    async getClientInformation() {
        const bluetoothAvailable = await WebAuthnService.canUseBluetooth();
        const isUserVerifyingPlatformAuthenticatorAvailable = await WebAuthnService.doesBrowserSupportPasskeys();
        const javaScriptHighEntropy = await WebAuthnService.getHighEntropyValues();
        const canUseConditionalUI = await WebAuthnService.doesBrowserSupportConditionalUI();
        const maybeClientHandle = WebAuthnService.getClientHandle();
        // iOS & macOS Only so far
        const clientCapabilities = await WebAuthnService.getClientCapabilities();
        let currentVisitorId = __classPrivateFieldGet(this, _WebAuthnService_visitorId, "f");
        if (!currentVisitorId) {
            const fpJS = await FingerprintJS.load();
            const { visitorId } = await fpJS.get();
            currentVisitorId = visitorId;
            __classPrivateFieldSet(this, _WebAuthnService_visitorId, visitorId, "f");
        }
        return {
            bluetoothAvailable: bluetoothAvailable,
            isUserVerifyingPlatformAuthenticatorAvailable: isUserVerifyingPlatformAuthenticatorAvailable,
            isConditionalMediationAvailable: canUseConditionalUI,
            clientEnvHandle: maybeClientHandle !== null && maybeClientHandle !== void 0 ? maybeClientHandle : undefined,
            visitorId: currentVisitorId,
            javaScriptHighEntropy: javaScriptHighEntropy,
            clientCapabilities,
            webdriver: WebAuthnService.getWebdriver(),
            privateMode: await WebAuthnService.isPrivateMode(),
        };
    }
    static async doesBrowserSupportPasskeys() {
        if (!window.PublicKeyCredential) {
            return undefined;
        }
        try {
            return await window.PublicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable();
        }
        catch (e) {
            log.debug('Error checking passkey availability', e);
            return;
        }
    }
    static async doesBrowserSupportConditionalUI() {
        if (!window.PublicKeyCredential) {
            return undefined;
        }
        try {
            return await window.PublicKeyCredential.isConditionalMediationAvailable();
        }
        catch (e) {
            log.debug('Error checking conditional UI availability', e);
            return;
        }
    }
    static async isPrivateMode() {
        try {
            const res = await detectIncognito();
            return res.isPrivate;
        }
        catch (e) {
            return;
        }
    }
    static async canUseBluetooth() {
        try {
            return await navigator.bluetooth.getAvailability();
        }
        catch (e) {
            // When using Safari and Firefox navigator.bluetooth returns undefined => we will return undefined
            log.debug('Error checking bluetooth availability', e);
            return;
        }
    }
    static getWebdriver() {
        try {
            return navigator.webdriver;
        }
        catch (e) {
            return false;
        }
    }
    static getClientHandle() {
        return localStorage.getItem(clientHandleKey);
    }
    static async getHighEntropyValues() {
        try {
            if (!navigator.userAgentData) {
                return;
            }
            const ua = await navigator.userAgentData.getHighEntropyValues(['platformVersion']);
            const platform = ua.platform;
            const mobile = ua.mobile;
            const platformVersion = ua.platformVersion;
            if (!platform || mobile === undefined || !platformVersion) {
                return;
            }
            return {
                platform,
                mobile,
                platformVersion,
            };
        }
        catch (e) {
            return;
        }
    }
    static setClientHandle(clientHandle) {
        localStorage.setItem(clientHandleKey, clientHandle);
    }
    abortOngoingOperation() {
        if (__classPrivateFieldGet(this, _WebAuthnService_abortController, "f")) {
            __classPrivateFieldGet(this, _WebAuthnService_abortController, "f").abort();
        }
        return new AbortController();
    }
    static async getClientCapabilities() {
        if (!window.PublicKeyCredential) {
            log.debug('PublicKeyCredential is not supported on this browser');
            return;
        }
        try {
            // We will ignore the type check as getClientCapabilities does not exist in the stable authn version and types
            // @ts-ignore
            return await window.PublicKeyCredential.getClientCapabilities();
        }
        catch (e) {
            log.debug('Error using getClientCapabilities: ', e);
            return;
        }
    }
    static challengeFromAttestationOptions(attestationOptions) {
        const typed = JSON.parse(attestationOptions);
        return typed.publicKey.challenge;
    }
    static challengeFromAssertionOptions(assertionOptions) {
        var _a;
        const typed = JSON.parse(assertionOptions);
        return (_a = typed.publicKey) === null || _a === void 0 ? void 0 : _a.challenge;
    }
    static async signalAllAcceptedCredentials(rpId, userId, credentialIds) {
        // @ts-ignore
        if (!window.PublicKeyCredential || !window.PublicKeyCredential.signalAllAcceptedCredentials) {
            return undefined;
        }
        try {
            // @ts-ignore
            await PublicKeyCredential.signalAllAcceptedCredentials({
                rpId: rpId,
                userId: userId,
                allAcceptedCredentialIds: credentialIds,
            });
        }
        catch (e) {
            log.debug('Error calling signalAllAcceptedCredentials', e);
            return;
        }
    }
}
_WebAuthnService_abortController = new WeakMap(), _WebAuthnService_visitorId = new WeakMap();
