const getStorageKey = () => `cbo_connect_invitation`;
export class ConnectInvitation {
    constructor(token) {
        this.token = token;
    }
    static loadFromStorage() {
        const serialized = localStorage.getItem(getStorageKey());
        if (!serialized) {
            return undefined;
        }
        const { token } = JSON.parse(serialized);
        return new ConnectInvitation(token);
    }
    persistToStorage() {
        localStorage.setItem(getStorageKey(), JSON.stringify({
            token: this.token,
        }));
    }
    static clearStorage() {
        localStorage.removeItem(getStorageKey());
    }
}
