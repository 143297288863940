import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState
} from "react";


import StealMapType from "./StealMapType";

export const defaultMap = StealMapType.Google;

interface MapContextType {
  map: StealMapType;
  setMap: (map: StealMapType) => void;
}

export const MapContext = createContext<MapContextType>({
  map: defaultMap,
  setMap: () => {}
});



export const MapProvider = ({ children }: { children: ReactNode }) => {
  const [map, setMap] = useState<StealMapType>(defaultMap);
  useEffect(() => {
    setMap(map);
  }, [map]);

  return (
    <MapContext.Provider value={{ map, setMap }}>
      {children}
    </MapContext.Provider>
  );
};

export const useMap = (): MapContextType => {
  const context = useContext(MapContext);
  if (!context) {
    throw new Error("useMap must be used within a MapProvider");
  }
  return context;
};
