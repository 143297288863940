var _ProcessService_instances, _ProcessService_authApi, _ProcessService_webAuthnService, _ProcessService_projectId, _ProcessService_timeout, _ProcessService_isPreviewMode, _ProcessService_frontendApiUrlSuffix, _ProcessService_createAxiosInstanceV2, _ProcessService_initNewAuthProcess, _ProcessService_setApisV2, _ProcessService_initAuthProcess, _ProcessService_processInit, _ProcessService_getAuthProcessState, _ProcessService_getDefaultFrontendApiUrl, _ProcessService_buildCorbadoFlags, _ProcessService_recordEvent;
import { __classPrivateFieldGet, __classPrivateFieldSet } from "tslib";
import axios from 'axios';
import log from 'loglevel';
import { Err, Ok } from 'ts-results';
import { Configuration } from '../api/v1';
import { AuthApi, BlockType, LoginIdentifierType, PasskeyEventType, SocialDataStatusEnum, VerificationMethod, } from '../api/v2';
import { AuthProcess } from '../models/authProcess';
import { EmailVerifyFromUrl } from '../models/emailVerifyFromUrl';
import { CorbadoError, PasskeyChallengeCancelledError, skipPasskeyAppendAfterHybridKey } from '../utils';
import { WebAuthnService } from './WebAuthnService';
const packageVersion = process.env.FE_LIBRARY_VERSION;
const passkeyAppendShownKey = 'cbo_passkey_append_shown';
const lastIdentifierKey = 'cbo_last_identifier';
export class ProcessService {
    constructor(projectId, timeout = 30 * 1000, isPreviewMode, frontendApiUrlSuffix) {
        _ProcessService_instances.add(this);
        _ProcessService_authApi.set(this, new AuthApi());
        _ProcessService_webAuthnService.set(this, void 0);
        _ProcessService_projectId.set(this, void 0);
        _ProcessService_timeout.set(this, void 0);
        _ProcessService_isPreviewMode.set(this, void 0);
        _ProcessService_frontendApiUrlSuffix.set(this, void 0);
        this.dropLastIdentifier = (passkeyOperations) => {
            const hasPasskey = passkeyOperations === null || passkeyOperations === void 0 ? void 0 : passkeyOperations.operationType;
            if (!hasPasskey || !passkeyOperations) {
                localStorage.removeItem(lastIdentifierKey);
                return;
            }
            localStorage.setItem(lastIdentifierKey, JSON.stringify({
                value: passkeyOperations.identifierValue,
                type: passkeyOperations.identifierType,
            }));
        };
        this.getLastIdentifier = () => {
            const lastIdentifierStore = localStorage.getItem(lastIdentifierKey);
            return lastIdentifierStore ? JSON.parse(lastIdentifierStore) : undefined;
        };
        _ProcessService_buildCorbadoFlags.set(this, () => {
            const flags = [];
            if (__classPrivateFieldGet(this, _ProcessService_isPreviewMode, "f")) {
                flags.push('preview');
            }
            return flags.join(',');
        });
        __classPrivateFieldSet(this, _ProcessService_projectId, projectId, "f");
        __classPrivateFieldSet(this, _ProcessService_timeout, timeout, "f");
        __classPrivateFieldSet(this, _ProcessService_isPreviewMode, isPreviewMode, "f");
        __classPrivateFieldSet(this, _ProcessService_frontendApiUrlSuffix, frontendApiUrlSuffix, "f");
        __classPrivateFieldSet(this, _ProcessService_webAuthnService, new WebAuthnService(), "f");
        // Initializes the API instances with no authentication token.
        // Authentication tokens are set in the SessionService.
        __classPrivateFieldGet(this, _ProcessService_instances, "m", _ProcessService_setApisV2).call(this);
    }
    async init(abortController, frontendPreferredBlockType, isDebug = false) {
        if (isDebug) {
            log.setLevel('debug');
        }
        else {
            log.setLevel('error');
        }
        // if frontendPreferredBlockType is not set, we check if lastIdentifierKey has been set in localstorage
        // if that is the case we show login-init block
        if (!frontendPreferredBlockType && localStorage.getItem(lastIdentifierKey)) {
            frontendPreferredBlockType = BlockType.LoginInit;
        }
        // we check if there is a process in local storage, if not we have to create a new one
        const process = AuthProcess.loadFromStorage(__classPrivateFieldGet(this, _ProcessService_projectId, "f"));
        if (!process) {
            console.log('process is missing');
            return __classPrivateFieldGet(this, _ProcessService_instances, "m", _ProcessService_initNewAuthProcess).call(this, abortController, frontendPreferredBlockType);
        }
        // if the process is already in local storage, we configure the client to use the existing process (we do not know about the
        __classPrivateFieldGet(this, _ProcessService_instances, "m", _ProcessService_setApisV2).call(this, process);
        const res = await __classPrivateFieldGet(this, _ProcessService_instances, "m", _ProcessService_getAuthProcessState).call(this, abortController, frontendPreferredBlockType);
        if (res.err && res.val.ignore) {
            return res;
        }
        if (res.err) {
            console.log('process has error', res.val);
            return __classPrivateFieldGet(this, _ProcessService_instances, "m", _ProcessService_initNewAuthProcess).call(this, abortController);
        }
        const block = res.val.blockBody.block;
        const initScreenBlocks = ['signup-init', 'login-init'];
        // if the frontend preferred block is in the initScreenBlocks, we need to init the signup/login process
        // another condition we need to check is that the new block is not in the initScreenBlocks. This is because in social login we come back to the signup/login process with addional data
        if ((!frontendPreferredBlockType || initScreenBlocks.includes(frontendPreferredBlockType)) &&
            !initScreenBlocks.includes(block)) {
            return __classPrivateFieldGet(this, _ProcessService_instances, "m", _ProcessService_initNewAuthProcess).call(this, abortController, frontendPreferredBlockType);
        }
        // if the process does not contain any state yet, we recreate it from backend to get potential config changes
        // we might disable this for PROD projects
        const initial = isProcessInitial(res.val);
        if (initial) {
            console.log('process is initial');
            return __classPrivateFieldGet(this, _ProcessService_instances, "m", _ProcessService_initNewAuthProcess).call(this, abortController, frontendPreferredBlockType);
        }
        return res;
    }
    clearProcess() {
        return AuthProcess.clearStorage(__classPrivateFieldGet(this, _ProcessService_projectId, "f"));
    }
    initEmailVerifyFromUrl() {
        const searchParams = new URLSearchParams(window.location.search);
        const encodedProcess = searchParams.get('corbadoEmailLinkID');
        if (!encodedProcess) {
            return Ok(null);
        }
        const token = searchParams.get('corbadoToken');
        if (!token) {
            return Ok(null);
        }
        try {
            const maybeProcess = AuthProcess.loadFromStorage(__classPrivateFieldGet(this, _ProcessService_projectId, "f"));
            const emailVerifyFromUrl = EmailVerifyFromUrl.fromURL(encodedProcess, token, maybeProcess);
            __classPrivateFieldGet(this, _ProcessService_instances, "m", _ProcessService_setApisV2).call(this, emailVerifyFromUrl.process);
            return Ok(emailVerifyFromUrl);
        }
        catch (e) {
            return Err(CorbadoError.fromUnknownFrontendError(e));
        }
    }
    async wrapWithErr(callback) {
        try {
            const r = await callback();
            return Ok(r.data);
        }
        catch (e) {
            if (e instanceof CorbadoError) {
                return Err(e);
            }
            return Err(CorbadoError.fromUnknownFrontendError(e));
        }
    }
    async finishAuthProcess() {
        return this.wrapWithErr(() => __classPrivateFieldGet(this, _ProcessService_authApi, "f").processComplete());
    }
    async resetAuthProcess() {
        const res = await this.wrapWithErr(() => __classPrivateFieldGet(this, _ProcessService_authApi, "f").processReset());
        if (res.ok && res.val.newProcess) {
            const newProcess = new AuthProcess(res.val.newProcess.token, __classPrivateFieldGet(this, _ProcessService_projectId, "f"), res.val.newProcess.expiresAt, res.val.common.frontendApiUrl);
            __classPrivateFieldGet(this, _ProcessService_instances, "m", _ProcessService_setApisV2).call(this, newProcess);
            newProcess.persistToStorage();
        }
        return res;
    }
    async initSignup(identifiers, fullName) {
        return this.wrapWithErr(() => __classPrivateFieldGet(this, _ProcessService_authApi, "f").signupInit({
            identifiers: identifiers,
            fullName: fullName,
        }));
    }
    async initLogin(identifierValue, isPhone) {
        return this.wrapWithErr(() => __classPrivateFieldGet(this, _ProcessService_authApi, "f").loginInit({
            isPhone: isPhone,
            identifierValue: identifierValue,
        }));
    }
    async skipBlock() {
        return this.wrapWithErr(() => __classPrivateFieldGet(this, _ProcessService_authApi, "f").blockSkip());
    }
    async startPasskeyAppend() {
        return this.wrapWithErr(() => __classPrivateFieldGet(this, _ProcessService_authApi, "f").passkeyAppendStart({
            clientInformation: {},
        }));
    }
    async finishPasskeyAppend(signedChallenge) {
        return this.wrapWithErr(() => __classPrivateFieldGet(this, _ProcessService_authApi, "f").passkeyAppendFinish({
            signedChallenge: signedChallenge,
        }));
    }
    async startPasskeyLogin() {
        return this.wrapWithErr(() => __classPrivateFieldGet(this, _ProcessService_authApi, "f").passkeyLoginStart({}));
    }
    async finishPasskeyLogin(signedChallenge) {
        return this.wrapWithErr(() => __classPrivateFieldGet(this, _ProcessService_authApi, "f").passkeyLoginFinish({
            signedChallenge: signedChallenge,
        }));
    }
    async finishPasskeyMediation(signedChallenge) {
        return this.wrapWithErr(() => __classPrivateFieldGet(this, _ProcessService_authApi, "f").passkeyMediationFinish({
            signedChallenge: signedChallenge,
        }));
    }
    async startEmailCodeVerification() {
        return this.wrapWithErr(() => __classPrivateFieldGet(this, _ProcessService_authApi, "f").identifierVerifyStart({
            verificationType: 'email-otp',
            identifierType: 'email',
        }));
    }
    async finishEmailCodeVerification(code) {
        return this.wrapWithErr(() => __classPrivateFieldGet(this, _ProcessService_authApi, "f").identifierVerifyFinish({
            verificationType: 'email-otp',
            identifierType: 'email',
            code: code,
            isNewDevice: false,
        }));
    }
    async startEmailLinkVerification() {
        return this.wrapWithErr(() => __classPrivateFieldGet(this, _ProcessService_authApi, "f").identifierVerifyStart({
            verificationType: VerificationMethod.EmailLink,
            identifierType: LoginIdentifierType.Email,
        }));
    }
    finishEmailLinkVerification(abortController, code, isNewDevice) {
        const req = {
            verificationType: VerificationMethod.EmailLink,
            identifierType: LoginIdentifierType.Email,
            code: code,
            isNewDevice: isNewDevice,
        };
        return this.wrapWithErr(() => __classPrivateFieldGet(this, _ProcessService_authApi, "f").identifierVerifyFinish(req, { signal: abortController.signal }));
    }
    getVerificationStatus() {
        return this.wrapWithErr(() => __classPrivateFieldGet(this, _ProcessService_authApi, "f").identifierVerifyStatus());
    }
    async updateEmail(email) {
        return this.wrapWithErr(() => __classPrivateFieldGet(this, _ProcessService_authApi, "f").identifierUpdate({
            identifierType: 'email',
            value: email,
        }));
    }
    async updatePhone(phone) {
        return this.wrapWithErr(() => __classPrivateFieldGet(this, _ProcessService_authApi, "f").identifierUpdate({
            identifierType: 'phone',
            value: phone,
        }));
    }
    async updateUsername(username) {
        return this.wrapWithErr(() => __classPrivateFieldGet(this, _ProcessService_authApi, "f").identifierUpdate({
            identifierType: 'username',
            value: username,
        }));
    }
    async startPhoneOtpVerification() {
        return this.wrapWithErr(() => __classPrivateFieldGet(this, _ProcessService_authApi, "f").identifierVerifyStart({
            verificationType: 'phone-otp',
            identifierType: 'phone',
        }));
    }
    async finishPhoneOtpVerification(code) {
        return this.wrapWithErr(() => __classPrivateFieldGet(this, _ProcessService_authApi, "f").identifierVerifyFinish({
            verificationType: 'phone-otp',
            identifierType: 'phone',
            code: code,
            isNewDevice: false,
        }));
    }
    async startSocialVerification(providerType, redirectUrl, authType) {
        const res = await this.wrapWithErr(() => __classPrivateFieldGet(this, _ProcessService_authApi, "f").socialVerifyStart({
            providerType: providerType,
            redirectUrl: redirectUrl,
            authType: authType,
        }));
        if (res.err) {
            return res;
        }
        // redirects must be done carefully => we don't want to trigger that redirect during the usual process update cycle but immediately after we received a response from the backend
        let typed;
        if (res.val.blockBody.block === BlockType.SignupInit) {
            typed = res.val.blockBody.data;
        }
        else if (res.val.blockBody.block === BlockType.LoginInit) {
            typed = res.val.blockBody.data;
        }
        else {
            return res;
        }
        if (typed.socialData && typed.socialData.status === SocialDataStatusEnum.Started && typed.socialData.oauthUrl) {
            window.location.href = typed.socialData.oauthUrl;
            return null;
        }
        return res;
    }
    finishSocialVerification(abortController) {
        return this.wrapWithErr(() => __classPrivateFieldGet(this, _ProcessService_authApi, "f").socialVerifyFinish({}, { signal: abortController.signal }));
    }
    async appendPasskey() {
        const respStart = await this.startPasskeyAppend();
        if (respStart.err || respStart.val.blockBody.error) {
            await this.recordEventAppendError();
            return respStart;
        }
        const typed = respStart.val.blockBody.data;
        const signedChallenge = await __classPrivateFieldGet(this, _ProcessService_webAuthnService, "f").createPasskey(typed.challenge);
        if (signedChallenge.err) {
            await this.recordEventAppendError(typed.challenge);
            return signedChallenge;
        }
        const respFinish = await this.finishPasskeyAppend(signedChallenge.val);
        if (respFinish.err) {
            await this.recordEventAppendError(typed.challenge);
        }
        return respFinish;
    }
    // perform a passkey login
    // if the procedure fails, clear the last identifier
    async loginWithPasskey() {
        const respStart = await this.startPasskeyLogin();
        if (respStart.err) {
            await this.recordEventLoginError();
            return respStart;
        }
        if (respStart.val.blockBody.error) {
            await this.recordEventLoginError();
            this.dropLastIdentifier(undefined);
            return respStart;
        }
        const typed = respStart.val.blockBody.data;
        const signedChallenge = await __classPrivateFieldGet(this, _ProcessService_webAuthnService, "f").login(typed.challenge, false);
        if (signedChallenge.err) {
            await this.recordEventLoginError(typed.challenge);
            this.dropLastIdentifier(undefined);
            return signedChallenge;
        }
        const respFinish = await this.finishPasskeyLogin(signedChallenge.val);
        if (respFinish.err) {
            await this.recordEventLoginError(typed.challenge);
        }
        return respFinish;
    }
    async isConditionalUISupported() {
        const r = await WebAuthnService.doesBrowserSupportConditionalUI();
        if (!r) {
            return false;
        }
        return true;
    }
    async loginWithPasskeyChallenge(challenge, onAuthenticatorCompleted) {
        const signedChallenge = await __classPrivateFieldGet(this, _ProcessService_webAuthnService, "f").login(challenge, true);
        if (signedChallenge.err) {
            if (!(signedChallenge.val.ignore && signedChallenge.val instanceof PasskeyChallengeCancelledError)) {
                await this.recordEventLoginError(challenge);
            }
            return signedChallenge;
        }
        onAuthenticatorCompleted === null || onAuthenticatorCompleted === void 0 ? void 0 : onAuthenticatorCompleted();
        const respFinish = await this.finishPasskeyMediation(signedChallenge.val);
        if (respFinish.err) {
            await this.recordEventLoginError(challenge);
        }
        return respFinish;
    }
    // record time of last passkey append as unix timestamp (seconds)
    dropPasskeyAppendShown() {
        const now = new Date();
        const utcSeconds = Math.floor((now.getTime() + now.getTimezoneOffset() * 60 * 1000) / 1000);
        localStorage.setItem(passkeyAppendShownKey, utcSeconds.toString());
    }
    dispose() {
        __classPrivateFieldGet(this, _ProcessService_webAuthnService, "f").abortOngoingOperation();
    }
    recordEventLoginError(challenge) {
        return __classPrivateFieldGet(this, _ProcessService_instances, "m", _ProcessService_recordEvent).call(this, PasskeyEventType.LoginError, challenge);
    }
    recordEventLoginExplicitAbort() {
        return __classPrivateFieldGet(this, _ProcessService_instances, "m", _ProcessService_recordEvent).call(this, PasskeyEventType.LoginExplicitAbort);
    }
    recordEventUserAppendAfterCrossPlatformBlacklisted() {
        return __classPrivateFieldGet(this, _ProcessService_instances, "m", _ProcessService_recordEvent).call(this, PasskeyEventType.UserAppendAfterCrossPlatformBlacklisted);
    }
    recordEventUserAppendAfterLoginErrorBlacklisted() {
        return __classPrivateFieldGet(this, _ProcessService_instances, "m", _ProcessService_recordEvent).call(this, PasskeyEventType.UserAppendAfterLoginErrorBlacklisted);
    }
    recordEventAppendCredentialExistsError() {
        return __classPrivateFieldGet(this, _ProcessService_instances, "m", _ProcessService_recordEvent).call(this, PasskeyEventType.AppendCredentialExists);
    }
    recordEventAppendError(challenge) {
        return __classPrivateFieldGet(this, _ProcessService_instances, "m", _ProcessService_recordEvent).call(this, PasskeyEventType.AppendError, challenge);
    }
    recordEventAppendExplicitAbort() {
        return __classPrivateFieldGet(this, _ProcessService_instances, "m", _ProcessService_recordEvent).call(this, PasskeyEventType.AppendExplicitAbort);
    }
}
_ProcessService_authApi = new WeakMap(), _ProcessService_webAuthnService = new WeakMap(), _ProcessService_projectId = new WeakMap(), _ProcessService_timeout = new WeakMap(), _ProcessService_isPreviewMode = new WeakMap(), _ProcessService_frontendApiUrlSuffix = new WeakMap(), _ProcessService_buildCorbadoFlags = new WeakMap(), _ProcessService_instances = new WeakSet(), _ProcessService_createAxiosInstanceV2 = function _ProcessService_createAxiosInstanceV2(processId) {
    const corbadoVersion = {
        name: 'web-core',
        sdkVersion: packageVersion,
    };
    const headers = {
        'Content-Type': 'application/json',
        'X-Corbado-SDK': JSON.stringify(corbadoVersion),
        'X-Corbado-Client-Timezone': Intl.DateTimeFormat().resolvedOptions().timeZone,
    };
    headers['X-Corbado-Flags'] = __classPrivateFieldGet(this, _ProcessService_buildCorbadoFlags, "f").call(this);
    const out = axios.create({
        timeout: __classPrivateFieldGet(this, _ProcessService_timeout, "f"),
        withCredentials: true,
        headers: processId ? { ...headers, 'x-corbado-process-id': processId } : headers,
    });
    // We transform AxiosErrors into CorbadoErrors using axios interceptors.
    out.interceptors.response.use(response => response, (error) => {
        const e = CorbadoError.fromAxiosError(error);
        return Promise.reject(e);
    });
    return out;
}, _ProcessService_initNewAuthProcess = async function _ProcessService_initNewAuthProcess(abortController, frontendPreferredBlockType) {
    console.log('initNewAuthProcess');
    const res = await __classPrivateFieldGet(this, _ProcessService_instances, "m", _ProcessService_initAuthProcess).call(this, abortController, frontendPreferredBlockType);
    if (res.err) {
        return res;
    }
    const newProcess = new AuthProcess(res.val.token, __classPrivateFieldGet(this, _ProcessService_projectId, "f"), res.val.expiresAt, res.val.processResponse.common.frontendApiUrl);
    __classPrivateFieldGet(this, _ProcessService_instances, "m", _ProcessService_setApisV2).call(this, newProcess);
    newProcess.persistToStorage();
    return Ok(res.val.processResponse);
}, _ProcessService_setApisV2 = function _ProcessService_setApisV2(process) {
    var _a;
    let frontendApiUrl = __classPrivateFieldGet(this, _ProcessService_instances, "m", _ProcessService_getDefaultFrontendApiUrl).call(this);
    if ((process === null || process === void 0 ? void 0 : process.frontendApiUrl) && (process === null || process === void 0 ? void 0 : process.frontendApiUrl.length) > 0) {
        frontendApiUrl = process.frontendApiUrl;
    }
    const config = new Configuration({
        apiKey: __classPrivateFieldGet(this, _ProcessService_projectId, "f"),
        basePath: frontendApiUrl,
    });
    const axiosInstance = __classPrivateFieldGet(this, _ProcessService_instances, "m", _ProcessService_createAxiosInstanceV2).call(this, (_a = process === null || process === void 0 ? void 0 : process.id) !== null && _a !== void 0 ? _a : '');
    __classPrivateFieldSet(this, _ProcessService_authApi, new AuthApi(config, frontendApiUrl, axiosInstance), "f");
}, _ProcessService_initAuthProcess = async function _ProcessService_initAuthProcess(abortController, frontendPreferredBlockType) {
    const passkeyAppendShownRaw = localStorage.getItem(passkeyAppendShownKey);
    let passkeyAppendShown = null;
    if (passkeyAppendShownRaw) {
        passkeyAppendShown = parseInt(passkeyAppendShownRaw, 10);
    }
    const res = await __classPrivateFieldGet(this, _ProcessService_instances, "m", _ProcessService_processInit).call(this, abortController, passkeyAppendShown, frontendPreferredBlockType);
    if (res.err) {
        return res;
    }
    // if the backend decides that a new client handle is needed, we store it in local storage
    if (res.val.newClientEnvHandle) {
        WebAuthnService.setClientHandle(res.val.newClientEnvHandle);
    }
    return res;
}, _ProcessService_processInit = async function _ProcessService_processInit(abortController, passkeyAppendShown, frontendPreferredBlockType) {
    const req = {
        clientInformation: await __classPrivateFieldGet(this, _ProcessService_webAuthnService, "f").getClientInformation(),
        passkeyAppendShown: passkeyAppendShown !== null && passkeyAppendShown !== void 0 ? passkeyAppendShown : undefined,
        preferredBlock: frontendPreferredBlockType,
        optOutOfPasskeyAppendAfterHybrid: localStorage.getItem(skipPasskeyAppendAfterHybridKey) === 'true',
    };
    return this.wrapWithErr(() => __classPrivateFieldGet(this, _ProcessService_authApi, "f").processInit(req, { signal: abortController.signal }));
}, _ProcessService_getAuthProcessState = async function _ProcessService_getAuthProcessState(abortController, frontendPreferredBlockType) {
    return this.wrapWithErr(() => __classPrivateFieldGet(this, _ProcessService_authApi, "f").processGet(frontendPreferredBlockType, { signal: abortController.signal }));
}, _ProcessService_getDefaultFrontendApiUrl = function _ProcessService_getDefaultFrontendApiUrl() {
    return `https://${__classPrivateFieldGet(this, _ProcessService_projectId, "f")}.${__classPrivateFieldGet(this, _ProcessService_frontendApiUrlSuffix, "f")}`;
}, _ProcessService_recordEvent = function _ProcessService_recordEvent(eventType, challenge) {
    const existingProcess = AuthProcess.loadFromStorage(__classPrivateFieldGet(this, _ProcessService_projectId, "f"));
    if (!existingProcess) {
        log.warn('No process found to record event.');
        return;
    }
    const req = {
        eventType,
        challenge,
    };
    return this.wrapWithErr(() => __classPrivateFieldGet(this, _ProcessService_authApi, "f").eventCreate(req));
};
// returns true if the current process does not contain any meaningful data any can thus be reset to a new process
function isProcessInitial(process) {
    var _a, _b;
    switch (process.blockBody.block) {
        case BlockType.LoginInit: {
            const typed = process.blockBody.data;
            const identifiersExist = typed.identifierValue.length > 0;
            const socialInProgress = ((_a = typed.socialData) === null || _a === void 0 ? void 0 : _a.status) !== SocialDataStatusEnum.Initial;
            return !identifiersExist && !socialInProgress;
        }
        case BlockType.SignupInit: {
            const typed = process.blockBody.data;
            const identifiersExist = typed.identifiers.reduce((acc, curr) => acc || curr.identifier.length > 0, false);
            const socialInProgress = ((_b = typed.socialData) === null || _b === void 0 ? void 0 : _b.status) !== SocialDataStatusEnum.Initial;
            return !identifiersExist && !socialInProgress;
        }
        default:
            return false;
    }
}
