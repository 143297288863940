import { useCorbado } from "@corbado/react";

import axios from "axios";

const useAxios = () => {
  const { sessionToken } = useCorbado();

  const apiClient = axios.create({
    baseURL: process.env.REACT_APP_V5_API_BASE_URL,
    headers: {
      "X-User-Session": sessionToken ?? ""
    },
    timeout: 10000
  });

  return { apiClient };
};

export default useAxios;
