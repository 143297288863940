import { InitState, ProcessHandler } from '@corbado/shared-ui';
import i18n from 'i18next';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useCorbado } from '../hooks/useCorbado';
import FlowHandlerContext from './FlowHandlerContext';
export const FlowHandlerProvider = ({ children, handleNavigationEvents, onLoggedIn, initialBlock, }) => {
    const { corbadoApp } = useCorbado();
    const [currentScreen, setCurrentScreen] = useState();
    const [initState, setInitState] = useState(InitState.Initializing);
    const onFlowChangeCbId = useRef(0);
    useEffect(() => {
        const flowHandler = new ProcessHandler(i18n, corbadoApp, onLoggedIn, handleNavigationEvents);
        onFlowChangeCbId.current = flowHandler.onScreenChange(value => {
            setCurrentScreen(value);
        });
        void (async () => {
            const res = await flowHandler.init(initialBlock);
            if (res.ok) {
                setInitState(InitState.Success);
            }
            else if (!res.val.ignore) {
                setInitState(InitState.Failed);
            }
        })();
        return () => {
            flowHandler.dispose();
            flowHandler.removeOnScreenChangeCallback(onFlowChangeCbId.current);
        };
    }, []);
    const contextValue = useMemo(() => ({
        currentScreen,
        initState,
    }), [currentScreen, initState]);
    return React.createElement(FlowHandlerContext.Provider, { value: contextValue }, children);
};
export default FlowHandlerProvider;
