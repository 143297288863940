const getStorageKey = (projectId) => `cbo_connect_last_login-${projectId}`;
export class ConnectLastLogin {
    constructor({ identifierType, identifierValue, ceremonyType, operationType, }) {
        this.identifierType = identifierType;
        this.identifierValue = identifierValue;
        this.operationType = operationType;
        this.ceremonyType = ceremonyType;
    }
    static loadFromStorage(projectId) {
        const serialized = localStorage.getItem(getStorageKey(projectId));
        if (!serialized) {
            return undefined;
        }
        const process = new ConnectLastLogin(JSON.parse(serialized));
        return process;
    }
    persistToStorage(projectId) {
        localStorage.setItem(getStorageKey(projectId), JSON.stringify({
            identifierType: this.identifierType,
            identifierValue: this.identifierValue,
            ceremonyType: this.ceremonyType,
            operationType: this.operationType,
        }));
    }
    static clearStorage(projectId) {
        localStorage.removeItem(getStorageKey(projectId));
    }
}
