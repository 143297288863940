import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Header, PrimaryButton, SecondaryButton, Text } from '../../../components';
import { LockIcon } from '../../../components/ui/icons/LockIcon';
import { PasskeyAppendAfterHybridIcon } from '../../../components/ui/icons/PasskeyAppendAfterHybridIcon';
export const PasskeyAppendAfterHybrid = ({ block }) => {
    const { t } = useTranslation('translation', {
        keyPrefix: `signup.passkey-append.passkey-append-after-hybrid`,
    });
    const [loading, setLoading] = useState(false);
    const headerText = useMemo(() => t('header'), [t]);
    const bodyText = useMemo(() => t('body'), [t]);
    const primaryButtonText = useMemo(() => t('button_appendPasskey'), [t]);
    const secondaryButtonText = useMemo(() => t('button_continue'), [t]);
    const handleContinue = useCallback(async () => {
        setLoading(true);
        await block.passkeyAppend();
    }, [block]);
    const handleSkip = useCallback(() => {
        void block.skipPasskeyAppend();
    }, [block]);
    useEffect(() => {
        function handleKeyDown(event) {
            if (event.key === 'Enter') {
                void handleContinue();
            }
        }
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [handleContinue]);
    return (React.createElement("div", { className: 'cb-pk-append-after-hybrid' },
        React.createElement(Header, null, headerText),
        React.createElement("div", { className: 'cb-pk-append-after-hybrid-icon' },
            React.createElement(PasskeyAppendAfterHybridIcon, null)),
        React.createElement("div", { className: 'cb-pk-append-after-hybrid-description' },
            React.createElement(LockIcon, { className: 'cb-pk-append-after-hybrid-description-icon' }),
            React.createElement(Text, { level: '2', fontFamilyVariant: 'secondary' }, bodyText)),
        React.createElement(PrimaryButton, { onClick: () => void handleContinue(), isLoading: loading }, primaryButtonText),
        React.createElement("div", { className: 'cb-pk-append-after-hybrid-button' },
            React.createElement(SecondaryButton, { onClick: handleSkip, disabled: loading }, secondaryButtonText))));
};
