import { BrowserRouter, Route, Routes } from "react-router-dom";

import { CorbadoProvider, PasskeyList } from "@corbado/react";

import {
  AppInsightsErrorBoundary,
  ReactPlugin,
  withAITracking
} from "@microsoft/applicationinsights-react-js";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { BaseProvider } from "baseui";
import LogRocket from "logrocket";
import { Client as Styletron } from "styletron-engine-monolithic";
import { Provider as StyletronProvider } from "styletron-react";

import HistoryGraph from "@smc-legacy/components/HistoryGraph";
import { PaganMap } from "@smc-legacy/components/PaganMap";
import "@smc-legacy/styles/App.css";

import { AdminContainer, Users } from "@smc/admin";
import { JourneysContainer, MapProvider } from "@smc/components";
import {
  EditJourneys,
  JourneysMap,
  Tracker,
  WatchRoute,
  WatchRouteList
} from "@smc/journeys";
import { AuthPage, Home, Layout, NotAllowed, NotFound } from "@smc/pages";
import { ThemeContext, ThemeProvider } from "@smc/theme";
import { Vitals } from "@smc/vitals";

const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: process.env.REACT_APP_APP_INSIGHTS,
    extensions: [reactPlugin],
    enableAutoRouteTracking: true,
  }
});


if (process.env.NODE_ENV === "production") {
  appInsights.loadAppInsights();
  LogRocket.init(process.env.REACT_APP_LOGROCKET ?? "");
}

function App() {
  const engine = new Styletron();
  return (
    <AppInsightsErrorBoundary
      onError={() => <h1>I believe something went wrong</h1>}
      appInsights={reactPlugin}
    >
      <StyletronProvider value={engine}>
        <ThemeProvider>
          <ThemeContext.Consumer>
            {({ theme }) => (
              <BaseProvider theme={theme}>
                <CorbadoProvider
                  projectId={process.env.REACT_APP_CORBADO ?? ""}
                  theme="emerald-funk"
                  darkMode={theme.name === "DarkStealTheme" ? "on" : "off"}
                  frontendApiUrl={
                    process.env.NODE_ENV === "production"
                      ? "https://sessions.smc.chff.in"
                      : `https://${process.env.REACT_APP_CORBADO}.frontendapi.corbado.io`
                  }

                  // add more config options here (styling, language, etc.)
                >
                  <BrowserRouter>
                    <MapProvider>
                    <Layout appInsights={appInsights}>
                      <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="journeys" element={<JourneysContainer />}>
                          <Route path="map" element={<JourneysMap />} />
                          <Route path="edit" element={<EditJourneys />} />
                          <Route path="track" element={<Tracker />} />
                          <Route path="watch" element={<WatchRouteList />}>
                            <Route path=":journeyId" element={<WatchRoute />} />
                          </Route>
                        </Route>

                        <Route path="login" element={<AuthPage />} />
                        <Route path="pagans" element={<PaganMap />} />
                        <Route path="passkeys" element={<PasskeyList />} />
                        <Route
                          path="petrol"
                          element={<HistoryGraph bish="681" />}
                        />
                        <Route path="vitals" element={<Vitals />} />
                        <Route path="admin" element={<AdminContainer />}>
                          <Route path="users" element={<Users />} />
                        </Route>

                        <Route path="*" element={<NotFound />} />
                        <Route path="/unauthorized" element={<NotAllowed />} />
                      </Routes>
                    </Layout>
                    </MapProvider>
                  </BrowserRouter>
                </CorbadoProvider>
              </BaseProvider>
            )}
          </ThemeContext.Consumer>
        </ThemeProvider>
      </StyletronProvider>
    </AppInsightsErrorBoundary>
  );
}

export default withAITracking(reactPlugin, App);
