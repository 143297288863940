var _PasskeyVerifyBlock_passkeyAborted;
import { __classPrivateFieldGet, __classPrivateFieldSet } from "tslib";
import { BlockType, GeneralBlockPasskeyVerifyLoginHintEnum, VerificationMethod } from '@corbado/web-core';
import { BlockTypes, ScreenNames } from '../constants';
import { Block } from './Block';
export class PasskeyVerifyBlock extends Block {
    constructor(app, flowHandler, common, errorTranslator, blockBody) {
        super(app, flowHandler, common, errorTranslator);
        this.type = BlockTypes.PasskeyVerify;
        this.initialScreen = ScreenNames.PasskeyBackground;
        _PasskeyVerifyBlock_passkeyAborted.set(this, false);
        this.getFormattedPhoneNumber = () => Block.getFormattedPhoneNumber(this.data.identifierValue);
        const data = blockBody.data;
        this.authType = blockBody.authType;
        if (data.loginHint === GeneralBlockPasskeyVerifyLoginHintEnum.Cda) {
            this.initialScreen = ScreenNames.PasskeyHybrid;
        }
        else {
            this.initialScreen = ScreenNames.PasskeyBackground;
        }
        this.data = {
            availableFallbacks: [],
            identifierValue: data.identifierValue,
        };
    }
    init() {
        this.data.availableFallbacks = this.alternatives
            .filter(a => a.type === BlockTypes.PhoneVerify || a.type === BlockType.EmailVerify)
            .map(alternative => {
            const typed = alternative.data;
            let result = undefined;
            if (!this.data.identifierValue) {
                this.data.identifierValue = typed.identifier;
            }
            switch (alternative.type) {
                case BlockType.EmailVerify: {
                    if (typed.verificationMethod === VerificationMethod.EmailOtp) {
                        result = { label: 'button_switchToAlternate.emailOtp', action: () => this.initFallbackEmailOtp() };
                    }
                    else {
                        result = { label: 'button_switchToAlternate.emailLink', action: () => this.initFallbackEmailLink() };
                    }
                    this.data.preferredFallbackOnError = result;
                    return result;
                }
                case BlockType.PhoneVerify:
                    result = { label: 'button_switchToAlternate.phone', action: () => this.initFallbackSmsOtp() };
                    if (this.data.preferredFallbackOnError === undefined) {
                        this.data.preferredFallbackOnError = result;
                    }
                    return result;
                default:
                    throw new Error('Invalid block type');
            }
        });
    }
    async passkeyLogin() {
        __classPrivateFieldSet(this, _PasskeyVerifyBlock_passkeyAborted, false, "f");
        const res = await this.app.authProcessService.loginWithPasskey();
        if (res.err) {
            // This check is necessary because the user might have navigated away from the passkey block before the operation was completed
            if (__classPrivateFieldGet(this, _PasskeyVerifyBlock_passkeyAborted, "f")) {
                return;
            }
            //In case of a first error, we show a different screen which has a lighter tone then the regular error screen
            //If the user tries again and fails, we show the regular error screen
            if (this.flowHandler.currentScreenName === ScreenNames.PasskeyBackground) {
                this.updateScreen(ScreenNames.PasskeyErrorLight);
            }
            else {
                this.updateScreen(ScreenNames.PasskeyError);
            }
            return;
        }
        this.updateProcess(res);
        return;
    }
    async initFallbackEmailOtp() {
        await this.app.authProcessService.recordEventLoginExplicitAbort();
        this.cancelPasskeyOperation();
        const newBlock = await this.app.authProcessService.startEmailCodeVerification();
        this.updateProcess(newBlock);
        return;
    }
    async initFallbackSmsOtp() {
        await this.app.authProcessService.recordEventLoginExplicitAbort();
        this.cancelPasskeyOperation();
        const newBlock = await this.app.authProcessService.startPhoneOtpVerification();
        this.updateProcess(newBlock);
        return;
    }
    async initFallbackEmailLink() {
        await this.app.authProcessService.recordEventLoginExplicitAbort();
        this.cancelPasskeyOperation();
        const newBlock = await this.app.authProcessService.startEmailLinkVerification();
        this.updateProcess(newBlock);
        return;
    }
    // cancels the current passkey operation (if one has been started)
    // this should be called if a user leaves the passkey verify block without completing the passkey operation
    // (otherwise the operation will continue in the background and a passkey popup might occur much later when the user no longer expects it)
    cancelPasskeyOperation() {
        __classPrivateFieldSet(this, _PasskeyVerifyBlock_passkeyAborted, true, "f");
        return this.app.authProcessService.dispose();
    }
}
_PasskeyVerifyBlock_passkeyAborted = new WeakMap();
