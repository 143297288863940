var _ProcessHandler_currentScreen, _ProcessHandler_currentBlock, _ProcessHandler_abortController, _ProcessHandler_corbadoApp, _ProcessHandler_processHistoryHandler, _ProcessHandler_errorTranslator, _ProcessHandler_postProcess, _ProcessHandler_onScreenChangeCallbacks, _ProcessHandler_updatePrimaryBlock, _ProcessHandler_parseBlockData;
import { __classPrivateFieldGet, __classPrivateFieldSet } from "tslib";
import { AuthType, BlockType } from '@corbado/web-core';
import { Ok } from 'ts-results';
import { ContinueOnOtherEnvBlock, EmailVerifyBlock, LoginInitBlock, PasskeyAppendBlock, PasskeyAppendedBlock, PasskeyVerifyBlock, PhoneVerifyBlock, SignupInitBlock, } from './blocks';
import { CompletedBlock } from './blocks/CompletedBlock';
import { initScreenBlocks } from './constants';
import { ErrorTranslator } from './errorTranslator';
import { ProcessHistoryHandler } from './processHistoryHandler';
/**
 * ProcessHandler is a class that manages the navigation flow of the application.
 * It keeps track of the current flow, the current screen, and the screen history.
 * It also provides methods for navigating to the next screen, navigating back, and changing the flow.
 */
export class ProcessHandler {
    /**
     * The constructor initializes the ProcessHandler with a flow name, a project configuration, and a flow handler configuration.
     * It sets the current flow to the specified flow, the current screen to the InitSignup screen, and initializes the screen history as an empty array.
     */
    constructor(i18next, corbadoApp, postProcess, handleNavigationEvents = true) {
        _ProcessHandler_currentScreen.set(this, void 0);
        _ProcessHandler_currentBlock.set(this, null);
        _ProcessHandler_abortController.set(this, new AbortController());
        _ProcessHandler_corbadoApp.set(this, void 0);
        _ProcessHandler_processHistoryHandler.set(this, void 0);
        _ProcessHandler_errorTranslator.set(this, void 0);
        _ProcessHandler_postProcess.set(this, void 0);
        _ProcessHandler_onScreenChangeCallbacks.set(this, []);
        _ProcessHandler_updatePrimaryBlock.set(this, (newPrimaryBlock) => {
            // if process has been completed, we don't want to update the screen anymore
            if (newPrimaryBlock instanceof CompletedBlock) {
                this.onProcessCompleted(newPrimaryBlock.data);
                return;
            }
            const blockHasChanged = __classPrivateFieldGet(this, _ProcessHandler_currentBlock, "f") == null || newPrimaryBlock.type !== __classPrivateFieldGet(this, _ProcessHandler_currentBlock, "f").type;
            if (blockHasChanged) {
                __classPrivateFieldSet(this, _ProcessHandler_currentScreen, newPrimaryBlock.initialScreen, "f");
            }
            __classPrivateFieldSet(this, _ProcessHandler_currentBlock, newPrimaryBlock, "f");
            __classPrivateFieldGet(this, _ProcessHandler_onScreenChangeCallbacks, "f").forEach(cb => cb({
                screen: __classPrivateFieldGet(this, _ProcessHandler_currentScreen, "f"),
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                block: __classPrivateFieldGet(this, _ProcessHandler_currentBlock, "f"),
            }));
            if (blockHasChanged) {
                __classPrivateFieldGet(this, _ProcessHandler_processHistoryHandler, "f").registerBlockChange(newPrimaryBlock.type);
            }
        });
        _ProcessHandler_parseBlockData.set(this, (blockBody, common) => {
            if (blockBody.continueOnOtherDevice) {
                return new ContinueOnOtherEnvBlock(__classPrivateFieldGet(this, _ProcessHandler_corbadoApp, "f"), this, common, __classPrivateFieldGet(this, _ProcessHandler_errorTranslator, "f"), blockBody.authType, blockBody.continueOnOtherDevice);
            }
            let block;
            switch (blockBody.block) {
                case BlockType.PasskeyAppend:
                    block = new PasskeyAppendBlock(__classPrivateFieldGet(this, _ProcessHandler_corbadoApp, "f"), this, common, __classPrivateFieldGet(this, _ProcessHandler_errorTranslator, "f"), blockBody);
                    break;
                case BlockType.SignupInit:
                    block = new SignupInitBlock(__classPrivateFieldGet(this, _ProcessHandler_corbadoApp, "f"), this, common, __classPrivateFieldGet(this, _ProcessHandler_errorTranslator, "f"), blockBody);
                    break;
                case BlockType.LoginInit:
                    block = new LoginInitBlock(__classPrivateFieldGet(this, _ProcessHandler_corbadoApp, "f"), this, common, __classPrivateFieldGet(this, _ProcessHandler_errorTranslator, "f"), blockBody);
                    break;
                case BlockType.PasskeyAppended:
                    block = new PasskeyAppendedBlock(__classPrivateFieldGet(this, _ProcessHandler_corbadoApp, "f"), this, common, __classPrivateFieldGet(this, _ProcessHandler_errorTranslator, "f"), blockBody);
                    break;
                case BlockType.PostSignupEmailVerify:
                case BlockType.EmailVerify:
                    block = EmailVerifyBlock.fromBackend(__classPrivateFieldGet(this, _ProcessHandler_corbadoApp, "f"), this, common, __classPrivateFieldGet(this, _ProcessHandler_errorTranslator, "f"), blockBody.data, blockBody.authType);
                    break;
                case BlockType.PhoneVerify:
                    block = new PhoneVerifyBlock(__classPrivateFieldGet(this, _ProcessHandler_corbadoApp, "f"), this, common, __classPrivateFieldGet(this, _ProcessHandler_errorTranslator, "f"), blockBody);
                    break;
                case BlockType.Completed:
                    block = new CompletedBlock(__classPrivateFieldGet(this, _ProcessHandler_corbadoApp, "f"), this, common, __classPrivateFieldGet(this, _ProcessHandler_errorTranslator, "f"), blockBody);
                    break;
                case BlockType.PasskeyVerify:
                    block = new PasskeyVerifyBlock(__classPrivateFieldGet(this, _ProcessHandler_corbadoApp, "f"), this, common, __classPrivateFieldGet(this, _ProcessHandler_errorTranslator, "f"), blockBody);
                    break;
                //TODO: Add MissingFieldsBlock
                // case BlockType.MissingFields:
                // block = new MissingFieldsBlock(this.#corbadoApp, this, common, this.#errorTranslator, blockBody);
                // break;
                default:
                    throw new Error(`Invalid block type: ${blockBody.block}}`);
            }
            if (blockBody.error) {
                block.setError(blockBody.error);
            }
            return block;
        });
        if (!corbadoApp) {
            throw new Error('corbadoApp is undefined. This should not happen.');
        }
        const errorTranslator = new ErrorTranslator(i18next);
        __classPrivateFieldSet(this, _ProcessHandler_corbadoApp, corbadoApp, "f");
        __classPrivateFieldSet(this, _ProcessHandler_processHistoryHandler, new ProcessHistoryHandler(handleNavigationEvents), "f");
        __classPrivateFieldSet(this, _ProcessHandler_errorTranslator, errorTranslator, "f");
        __classPrivateFieldSet(this, _ProcessHandler_postProcess, postProcess, "f");
    }
    /**
     * Initializes the ProcessHandler.
     * Call this function after registering all callbacks.
     */
    async init(initialBlockFromComponentConfig) {
        const frontendPreferredBlockType = __classPrivateFieldGet(this, _ProcessHandler_processHistoryHandler, "f").init((blockType) => this.switchToBlock(blockType), () => this.startAskForAbort());
        const emailVerifyFromUrl = __classPrivateFieldGet(this, _ProcessHandler_corbadoApp, "f").authProcessService.initEmailVerifyFromUrl();
        if (emailVerifyFromUrl.err) {
            this.handleError(emailVerifyFromUrl.val);
            return Ok(void 0);
        }
        if (emailVerifyFromUrl.val) {
            this.handleProcessUpdateFromUrl(emailVerifyFromUrl.val);
            return Ok(void 0);
        }
        // we prefer frontendPreferredBlockType over initialBlockFromComponentConfig
        const res = await __classPrivateFieldGet(this, _ProcessHandler_corbadoApp, "f").authProcessService.init(__classPrivateFieldGet(this, _ProcessHandler_abortController, "f"), (frontendPreferredBlockType !== null && frontendPreferredBlockType !== void 0 ? frontendPreferredBlockType : initialBlockFromComponentConfig));
        if (res.err) {
            return res;
        }
        this.handleProcessUpdateBackend(res.val);
        return Ok(void 0);
    }
    onProcessCompleted(data) {
        __classPrivateFieldGet(this, _ProcessHandler_corbadoApp, "f").authProcessService.clearProcess();
        __classPrivateFieldGet(this, _ProcessHandler_corbadoApp, "f").authProcessService.dropLastIdentifier(data.passkeyOperation);
        __classPrivateFieldSet(this, _ProcessHandler_currentBlock, null, "f");
        __classPrivateFieldGet(this, _ProcessHandler_corbadoApp, "f").sessionService.setSession(data.sessionToken, data.refreshToken);
        __classPrivateFieldGet(this, _ProcessHandler_postProcess, "f").call(this);
    }
    switchToBlock(blockType) {
        var _a, _b, _c, _d;
        if (((_a = __classPrivateFieldGet(this, _ProcessHandler_currentBlock, "f")) === null || _a === void 0 ? void 0 : _a.type) === blockType) {
            this.handleProcessUpdateFrontend(__classPrivateFieldGet(this, _ProcessHandler_currentBlock, "f"), __classPrivateFieldGet(this, _ProcessHandler_currentBlock, "f").alternatives);
            return true;
        }
        const newBlock = (_b = __classPrivateFieldGet(this, _ProcessHandler_currentBlock, "f")) === null || _b === void 0 ? void 0 : _b.alternatives.find(b => b.type === blockType);
        if (!newBlock) {
            return false;
        }
        const newAlternatives = (_d = (_c = __classPrivateFieldGet(this, _ProcessHandler_currentBlock, "f")) === null || _c === void 0 ? void 0 : _c.alternatives.filter(b => b.type !== blockType)) !== null && _d !== void 0 ? _d : [];
        if (__classPrivateFieldGet(this, _ProcessHandler_currentBlock, "f")) {
            newAlternatives.push(__classPrivateFieldGet(this, _ProcessHandler_currentBlock, "f"));
        }
        this.handleProcessUpdateFrontend(newBlock, newAlternatives);
        return true;
    }
    // this adds a ConfirmProcessAbortBlock to the process
    startAskForAbort() {
        const currentBlock = __classPrivateFieldGet(this, _ProcessHandler_currentBlock, "f");
        if (!currentBlock || initScreenBlocks.includes(currentBlock.type)) {
            return;
        }
        // in login processes we don't want to ask for abort (we auto-confirm it)
        if (currentBlock.authType === AuthType.Login) {
            void currentBlock.confirmAbort();
            return;
        }
        // The default action is to continue on yes and abort on no because mobile Safari will auto-cancel.
        // For reference see (unsolved bug): https://stackoverflow.com/questions/38083702/alert-confirm-and-prompt-not-working-after-using-history-api-on-safari-ios
        if (confirm('Going back will restart the signup process and your current progress will be lost. Do you wish to complete the current signup first?')) {
            history.forward();
        }
        else {
            void currentBlock.confirmAbort();
        }
    }
    dispose() {
        __classPrivateFieldGet(this, _ProcessHandler_corbadoApp, "f").dispose();
        __classPrivateFieldGet(this, _ProcessHandler_abortController, "f").abort();
        __classPrivateFieldGet(this, _ProcessHandler_processHistoryHandler, "f").dispose();
    }
    get currentScreenName() {
        return __classPrivateFieldGet(this, _ProcessHandler_currentScreen, "f");
    }
    updateScreen(newScreen) {
        __classPrivateFieldSet(this, _ProcessHandler_currentScreen, newScreen, "f");
        __classPrivateFieldGet(this, _ProcessHandler_onScreenChangeCallbacks, "f").forEach(cb => cb({
            screen: newScreen,
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            block: __classPrivateFieldGet(this, _ProcessHandler_currentBlock, "f"),
        }));
    }
    onScreenChange(cb) {
        return __classPrivateFieldGet(this, _ProcessHandler_onScreenChangeCallbacks, "f").push(cb) - 1;
    }
    removeOnScreenChangeCallback(cbId) {
        __classPrivateFieldGet(this, _ProcessHandler_onScreenChangeCallbacks, "f").splice(cbId, 1);
    }
    handleProcessUpdateFromUrl(emailVerifyFromUrl) {
        const newBlock = EmailVerifyBlock.fromUrl(__classPrivateFieldGet(this, _ProcessHandler_corbadoApp, "f"), this, __classPrivateFieldGet(this, _ProcessHandler_errorTranslator, "f"), emailVerifyFromUrl.data, emailVerifyFromUrl.authType, emailVerifyFromUrl.isNewDevice, emailVerifyFromUrl.token);
        newBlock.init();
        __classPrivateFieldGet(this, _ProcessHandler_updatePrimaryBlock, "f").call(this, newBlock);
    }
    handleProcessUpdateBackend(processResponse, error) {
        var _a, _b;
        const newPrimaryBlock = __classPrivateFieldGet(this, _ProcessHandler_parseBlockData, "f").call(this, processResponse.blockBody, processResponse.common);
        if (error) {
            newPrimaryBlock.error = error;
        }
        const alternatives = (_b = (_a = processResponse.blockBody.alternatives) === null || _a === void 0 ? void 0 : _a.map(b => __classPrivateFieldGet(this, _ProcessHandler_parseBlockData, "f").call(this, b, processResponse.common))) !== null && _b !== void 0 ? _b : [];
        newPrimaryBlock.setAlternatives(alternatives);
        newPrimaryBlock.init();
        __classPrivateFieldGet(this, _ProcessHandler_updatePrimaryBlock, "f").call(this, newPrimaryBlock);
    }
    handleProcessUpdateFrontend(newPrimaryBlock, newAlternatives = []) {
        newPrimaryBlock.setAlternatives(newAlternatives);
        newPrimaryBlock.init();
        __classPrivateFieldGet(this, _ProcessHandler_updatePrimaryBlock, "f").call(this, newPrimaryBlock);
    }
    // updates the current block with the error and updates the screen
    handleError(corbadoError) {
        console.log('handleError', corbadoError.name, corbadoError.message);
        const primaryBlockWithError = __classPrivateFieldGet(this, _ProcessHandler_currentBlock, "f");
        if (!primaryBlockWithError) {
            return;
        }
        primaryBlockWithError.error = corbadoError;
        __classPrivateFieldGet(this, _ProcessHandler_updatePrimaryBlock, "f").call(this, primaryBlockWithError);
        return;
    }
}
_ProcessHandler_currentScreen = new WeakMap(), _ProcessHandler_currentBlock = new WeakMap(), _ProcessHandler_abortController = new WeakMap(), _ProcessHandler_corbadoApp = new WeakMap(), _ProcessHandler_processHistoryHandler = new WeakMap(), _ProcessHandler_errorTranslator = new WeakMap(), _ProcessHandler_postProcess = new WeakMap(), _ProcessHandler_onScreenChangeCallbacks = new WeakMap(), _ProcessHandler_updatePrimaryBlock = new WeakMap(), _ProcessHandler_parseBlockData = new WeakMap();
