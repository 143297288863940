const getStorageKey = (projectId) => `cbo_auth_process-${projectId}`;
export class AuthProcess {
    constructor(id, projectId, expiresAt, frontendApiUrl) {
        this.id = id;
        this.projectId = projectId;
        this.expiresAt = expiresAt;
        this.frontendApiUrl = frontendApiUrl;
    }
    isValid() {
        return this.expiresAt > Date.now() / 1000;
    }
    static loadFromStorage(projectId) {
        const serialized = localStorage.getItem(getStorageKey(projectId));
        if (!serialized) {
            return undefined;
        }
        const { id, expiresAt, frontendApiUrl } = JSON.parse(serialized);
        const process = new AuthProcess(id, projectId, expiresAt, frontendApiUrl);
        if (!process.isValid()) {
            return undefined;
        }
        return process;
    }
    persistToStorage() {
        localStorage.setItem(getStorageKey(this.projectId), JSON.stringify({
            id: this.id,
            expiresAt: this.expiresAt,
            frontendApiUrl: this.frontendApiUrl,
        }));
    }
    static clearStorage(projectId) {
        localStorage.removeItem(getStorageKey(projectId));
    }
}
