import { useStyletron } from "baseui";

import { colors } from "@smc/theme/theme";

type TitleProps = {
  title: string;
  subtitle: string;
};

const Title = ({ title, subtitle }: TitleProps) => {
  const [css] = useStyletron();
  return (
    <div className={css({ textAlign: "center" })}>
      <h1
        className={css({
          color: colors.cyan[400], // Primary accent color
          fontVariantCaps: "small-caps",
          margin: "0.5rem 0"
        })}
      >
        {title}
      </h1>
      <p
        className={css({
          color: colors.fuchsia[500], // Secondary accent color
          fontVariantCaps: "small-caps",
          margin: "0.25rem 0 1rem"
        })}
      >
        {subtitle}
      </p>
    </div>
  );
};

export default Title;
