/* tslint:disable */
/* eslint-disable */
/**
 * Corbado Frontend API
 * Overview of all Corbado Frontend API calls to implement passwordless authentication.
 *
 * The version of the OpenAPI document: 2.0.4
 * Contact: support@corbado.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBearerAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, BaseAPI } from './base';
/**
 *
 * @export
 * @enum {string}
 */
export const AuthType = {
    Signup: 'signup',
    Login: 'login'
};
/**
 *
 * @export
 * @enum {string}
 */
export const BlockType = {
    SignupInit: 'signup-init',
    PasskeyAppend: 'passkey-append',
    PhoneVerify: 'phone-verify',
    EmailVerify: 'email-verify',
    PasskeyAppended: 'passkey-appended',
    Completed: 'completed',
    SocialVerify: 'social-verify',
    LoginInit: 'login-init',
    PasskeyVerify: 'passkey-verify',
    ConditionalUiCompleted: 'conditional-ui-completed',
    PostSignupEmailVerify: 'post-signup-email-verify',
    PasskeyAppendAfterHybrid: 'passkey-append-after-hybrid'
};
export const ConnectAppendStartRspVariantEnum = {
    Default: 'default',
    AfterHybrid: 'after-hybrid',
    AfterError: 'after-error'
};
export const ConnectLoginStartReqSourceEnum = {
    OneTap: 'one-tap',
    TextField: 'text-field',
    ErrorSoft: 'error-soft',
    ErrorHard: 'error-hard'
};
export const ContinueOnOtherDeviceReasonEnum = {
    EmailLinkVerified: 'email-link-verified',
    ProcessAlreadyCompleted: 'process-already-completed'
};
export const GeneralBlockPasskeyAppendVariantEnum = {
    Default: 'default',
    AfterHybrid: 'after-hybrid',
    AfterError: 'after-error'
};
export const GeneralBlockPasskeyVerifyLoginHintEnum = {
    Cda: 'cda'
};
/**
 *
 * @export
 * @enum {string}
 */
export const LoginIdentifierType = {
    Email: 'email',
    Phone: 'phone',
    Username: 'username'
};
export const MePasskeysAppendStartRspAppendNotAllowedReasonEnum = {
    PasskeyAlreadyExists: 'passkey_already_exists',
    PasskeysNotSupported: 'passkeys_not_supported'
};
export const PasskeyTransportEnum = {
    Usb: 'usb',
    Nfc: 'nfc',
    Ble: 'ble',
    Internal: 'internal',
    Hybrid: 'hybrid',
    SmartCard: 'smart-card'
};
export const PasskeyStatusEnum = {
    Pending: 'pending',
    Active: 'active'
};
/**
 *
 * @export
 * @enum {string}
 */
export const PasskeyEventType = {
    LoginExplicitAbort: 'login-explicit-abort',
    LoginError: 'login-error',
    LoginErrorUntyped: 'login-error-untyped',
    LoginErrorUnexpected: 'login-error-unexpected',
    LoginOneTapSwitch: 'login-one-tap-switch',
    UserAppendAfterCrossPlatformBlacklisted: 'user-append-after-cross-platform-blacklisted',
    UserAppendAfterLoginErrorBlacklisted: 'user-append-after-login-error-blacklisted',
    AppendCredentialExists: 'append-credential-exists',
    AppendExplicitAbort: 'append-explicit-abort',
    AppendError: 'append-error',
    AppendErrorUnexpected: 'append-error-unexpected',
    ManageErrorUnexpected: 'manage-error-unexpected'
};
/**
 *
 * @export
 * @enum {string}
 */
export const PasskeyIconSet = {
    Default: 'default',
    Apple: 'apple',
    Android: 'android',
    Windows: 'windows'
};
export const PasskeyOperationOperationTypeEnum = {
    Append: 'append',
    Verify: 'verify'
};
export const PasskeyOperationCeremonyTypeEnum = {
    Local: 'local',
    Cda: 'cda',
    SecurityKey: 'security-key'
};
export const SessionTokenCookieConfigSameSiteEnum = {
    Lax: 'lax',
    Strict: 'strict',
    None: 'none'
};
export const ShortSessionCookieConfigSameSiteEnum = {
    Lax: 'lax',
    Strict: 'strict',
    None: 'none'
};
export const SocialDataStatusEnum = {
    Initial: 'initial',
    Started: 'started',
    Finished: 'finished'
};
/**
 *
 * @export
 * @enum {string}
 */
export const SocialProviderType = {
    Google: 'google',
    Microsoft: 'microsoft',
    Github: 'github'
};
/**
 *
 * @export
 * @enum {string}
 */
export const VerificationMethod = {
    EmailLink: 'email-link',
    EmailOtp: 'email-otp',
    PhoneOtp: 'phone-otp'
};
/**
 * AuthApi - axios parameter creator
 * @export
 */
export const AuthApiAxiosParamCreator = function (configuration) {
    return {
        /**
         * tbd
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        blockSkip: async (options = {}) => {
            const localVarPath = `/v2/auth/block/skip`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);
            // authentication projectID required
            await setApiKeyToObject(localVarHeaderParameter, "X-Corbado-ProjectID", configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a new user generated complete event.
         * @param {EventCreateReq} eventCreateReq
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eventCreate: async (eventCreateReq, options = {}) => {
            // verify required parameter 'eventCreateReq' is not null or undefined
            assertParamExists('eventCreate', 'eventCreateReq', eventCreateReq);
            const localVarPath = `/v2/auth/events`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);
            // authentication projectID required
            await setApiKeyToObject(localVarHeaderParameter, "X-Corbado-ProjectID", configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(eventCreateReq, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * tbd
         * @param {IdentifierUpdateReq} identifierUpdateReq
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identifierUpdate: async (identifierUpdateReq, options = {}) => {
            // verify required parameter 'identifierUpdateReq' is not null or undefined
            assertParamExists('identifierUpdate', 'identifierUpdateReq', identifierUpdateReq);
            const localVarPath = `/v2/auth/identifier/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);
            // authentication projectID required
            await setApiKeyToObject(localVarHeaderParameter, "X-Corbado-ProjectID", configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(identifierUpdateReq, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * tbd
         * @param {IdentifierVerifyFinishReq} identifierVerifyFinishReq
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identifierVerifyFinish: async (identifierVerifyFinishReq, options = {}) => {
            // verify required parameter 'identifierVerifyFinishReq' is not null or undefined
            assertParamExists('identifierVerifyFinish', 'identifierVerifyFinishReq', identifierVerifyFinishReq);
            const localVarPath = `/v2/auth/identifier/verify/finish`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);
            // authentication projectID required
            await setApiKeyToObject(localVarHeaderParameter, "X-Corbado-ProjectID", configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(identifierVerifyFinishReq, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * tbd
         * @param {IdentifierVerifyStartReq} identifierVerifyStartReq
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identifierVerifyStart: async (identifierVerifyStartReq, options = {}) => {
            // verify required parameter 'identifierVerifyStartReq' is not null or undefined
            assertParamExists('identifierVerifyStart', 'identifierVerifyStartReq', identifierVerifyStartReq);
            const localVarPath = `/v2/auth/identifier/verify/start`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);
            // authentication projectID required
            await setApiKeyToObject(localVarHeaderParameter, "X-Corbado-ProjectID", configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(identifierVerifyStartReq, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * tbd
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identifierVerifyStatus: async (options = {}) => {
            const localVarPath = `/v2/auth/identifier/verify/status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);
            // authentication projectID required
            await setApiKeyToObject(localVarHeaderParameter, "X-Corbado-ProjectID", configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * tbd
         * @param {LoginInitReq} loginInitReq
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginInit: async (loginInitReq, options = {}) => {
            // verify required parameter 'loginInitReq' is not null or undefined
            assertParamExists('loginInit', 'loginInitReq', loginInitReq);
            const localVarPath = `/v2/auth/login/init`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);
            // authentication projectID required
            await setApiKeyToObject(localVarHeaderParameter, "X-Corbado-ProjectID", configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(loginInitReq, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * tbd
         * @param {PasskeyAppendFinishReq} passkeyAppendFinishReq
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        passkeyAppendFinish: async (passkeyAppendFinishReq, options = {}) => {
            // verify required parameter 'passkeyAppendFinishReq' is not null or undefined
            assertParamExists('passkeyAppendFinish', 'passkeyAppendFinishReq', passkeyAppendFinishReq);
            const localVarPath = `/v2/auth/passkey/append/finish`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);
            // authentication projectID required
            await setApiKeyToObject(localVarHeaderParameter, "X-Corbado-ProjectID", configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(passkeyAppendFinishReq, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * tbd
         * @param {PasskeyAppendStartReq} passkeyAppendStartReq
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        passkeyAppendStart: async (passkeyAppendStartReq, options = {}) => {
            // verify required parameter 'passkeyAppendStartReq' is not null or undefined
            assertParamExists('passkeyAppendStart', 'passkeyAppendStartReq', passkeyAppendStartReq);
            const localVarPath = `/v2/auth/passkey/append/start`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);
            // authentication projectID required
            await setApiKeyToObject(localVarHeaderParameter, "X-Corbado-ProjectID", configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(passkeyAppendStartReq, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * tbd
         * @param {PasskeyLoginFinishReq} passkeyLoginFinishReq
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        passkeyLoginFinish: async (passkeyLoginFinishReq, options = {}) => {
            // verify required parameter 'passkeyLoginFinishReq' is not null or undefined
            assertParamExists('passkeyLoginFinish', 'passkeyLoginFinishReq', passkeyLoginFinishReq);
            const localVarPath = `/v2/auth/passkey/login/finish`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);
            // authentication projectID required
            await setApiKeyToObject(localVarHeaderParameter, "X-Corbado-ProjectID", configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(passkeyLoginFinishReq, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * tbd
         * @param {PasskeyLoginStartReq} passkeyLoginStartReq
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        passkeyLoginStart: async (passkeyLoginStartReq, options = {}) => {
            // verify required parameter 'passkeyLoginStartReq' is not null or undefined
            assertParamExists('passkeyLoginStart', 'passkeyLoginStartReq', passkeyLoginStartReq);
            const localVarPath = `/v2/auth/passkey/login/start`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);
            // authentication projectID required
            await setApiKeyToObject(localVarHeaderParameter, "X-Corbado-ProjectID", configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(passkeyLoginStartReq, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * tbd
         * @param {PasskeyMediationFinishReq} passkeyMediationFinishReq
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        passkeyMediationFinish: async (passkeyMediationFinishReq, options = {}) => {
            // verify required parameter 'passkeyMediationFinishReq' is not null or undefined
            assertParamExists('passkeyMediationFinish', 'passkeyMediationFinishReq', passkeyMediationFinishReq);
            const localVarPath = `/v2/auth/passkey/mediation/finish`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);
            // authentication projectID required
            await setApiKeyToObject(localVarHeaderParameter, "X-Corbado-ProjectID", configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(passkeyMediationFinishReq, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * tbd
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processComplete: async (options = {}) => {
            const localVarPath = `/v2/auth/process/complete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);
            // authentication projectID required
            await setApiKeyToObject(localVarHeaderParameter, "X-Corbado-ProjectID", configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * tbd
         * @param {BlockType} [preferredBlock]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processGet: async (preferredBlock, options = {}) => {
            const localVarPath = `/v2/auth/process`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);
            // authentication projectID required
            await setApiKeyToObject(localVarHeaderParameter, "X-Corbado-ProjectID", configuration);
            if (preferredBlock !== undefined) {
                localVarQueryParameter['preferredBlock'] = preferredBlock;
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * tbd
         * @param {ProcessInitReq} processInitReq
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processInit: async (processInitReq, options = {}) => {
            // verify required parameter 'processInitReq' is not null or undefined
            assertParamExists('processInit', 'processInitReq', processInitReq);
            const localVarPath = `/v2/auth/process/init`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);
            // authentication projectID required
            await setApiKeyToObject(localVarHeaderParameter, "X-Corbado-ProjectID", configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(processInitReq, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * tbd
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processReset: async (options = {}) => {
            const localVarPath = `/v2/auth/process/reset`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);
            // authentication projectID required
            await setApiKeyToObject(localVarHeaderParameter, "X-Corbado-ProjectID", configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * tbd
         * @param {SignupInitReq} signupInitReq
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signupInit: async (signupInitReq, options = {}) => {
            // verify required parameter 'signupInitReq' is not null or undefined
            assertParamExists('signupInit', 'signupInitReq', signupInitReq);
            const localVarPath = `/v2/auth/signup/init`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);
            // authentication projectID required
            await setApiKeyToObject(localVarHeaderParameter, "X-Corbado-ProjectID", configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(signupInitReq, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * tbd
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        socialVerifyCallback: async (options = {}) => {
            const localVarPath = `/v2/auth/social/verify/callback`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);
            // authentication projectID required
            await setApiKeyToObject(localVarHeaderParameter, "X-Corbado-ProjectID", configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * tbd
         * @param {object} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        socialVerifyFinish: async (body, options = {}) => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('socialVerifyFinish', 'body', body);
            const localVarPath = `/v2/auth/social/verify/finish`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);
            // authentication projectID required
            await setApiKeyToObject(localVarHeaderParameter, "X-Corbado-ProjectID", configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * tbd
         * @param {SocialVerifyStartReq} socialVerifyStartReq
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        socialVerifyStart: async (socialVerifyStartReq, options = {}) => {
            // verify required parameter 'socialVerifyStartReq' is not null or undefined
            assertParamExists('socialVerifyStart', 'socialVerifyStartReq', socialVerifyStartReq);
            const localVarPath = `/v2/auth/social/verify/start`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);
            // authentication projectID required
            await setApiKeyToObject(localVarHeaderParameter, "X-Corbado-ProjectID", configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(socialVerifyStartReq, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * AuthApi - functional programming interface
 * @export
 */
export const AuthApiFp = function (configuration) {
    const localVarAxiosParamCreator = AuthApiAxiosParamCreator(configuration);
    return {
        /**
         * tbd
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async blockSkip(options) {
            const localVarAxiosArgs = await localVarAxiosParamCreator.blockSkip(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates a new user generated complete event.
         * @param {EventCreateReq} eventCreateReq
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async eventCreate(eventCreateReq, options) {
            const localVarAxiosArgs = await localVarAxiosParamCreator.eventCreate(eventCreateReq, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * tbd
         * @param {IdentifierUpdateReq} identifierUpdateReq
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async identifierUpdate(identifierUpdateReq, options) {
            const localVarAxiosArgs = await localVarAxiosParamCreator.identifierUpdate(identifierUpdateReq, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * tbd
         * @param {IdentifierVerifyFinishReq} identifierVerifyFinishReq
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async identifierVerifyFinish(identifierVerifyFinishReq, options) {
            const localVarAxiosArgs = await localVarAxiosParamCreator.identifierVerifyFinish(identifierVerifyFinishReq, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * tbd
         * @param {IdentifierVerifyStartReq} identifierVerifyStartReq
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async identifierVerifyStart(identifierVerifyStartReq, options) {
            const localVarAxiosArgs = await localVarAxiosParamCreator.identifierVerifyStart(identifierVerifyStartReq, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * tbd
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async identifierVerifyStatus(options) {
            const localVarAxiosArgs = await localVarAxiosParamCreator.identifierVerifyStatus(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * tbd
         * @param {LoginInitReq} loginInitReq
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loginInit(loginInitReq, options) {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loginInit(loginInitReq, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * tbd
         * @param {PasskeyAppendFinishReq} passkeyAppendFinishReq
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async passkeyAppendFinish(passkeyAppendFinishReq, options) {
            const localVarAxiosArgs = await localVarAxiosParamCreator.passkeyAppendFinish(passkeyAppendFinishReq, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * tbd
         * @param {PasskeyAppendStartReq} passkeyAppendStartReq
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async passkeyAppendStart(passkeyAppendStartReq, options) {
            const localVarAxiosArgs = await localVarAxiosParamCreator.passkeyAppendStart(passkeyAppendStartReq, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * tbd
         * @param {PasskeyLoginFinishReq} passkeyLoginFinishReq
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async passkeyLoginFinish(passkeyLoginFinishReq, options) {
            const localVarAxiosArgs = await localVarAxiosParamCreator.passkeyLoginFinish(passkeyLoginFinishReq, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * tbd
         * @param {PasskeyLoginStartReq} passkeyLoginStartReq
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async passkeyLoginStart(passkeyLoginStartReq, options) {
            const localVarAxiosArgs = await localVarAxiosParamCreator.passkeyLoginStart(passkeyLoginStartReq, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * tbd
         * @param {PasskeyMediationFinishReq} passkeyMediationFinishReq
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async passkeyMediationFinish(passkeyMediationFinishReq, options) {
            const localVarAxiosArgs = await localVarAxiosParamCreator.passkeyMediationFinish(passkeyMediationFinishReq, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * tbd
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async processComplete(options) {
            const localVarAxiosArgs = await localVarAxiosParamCreator.processComplete(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * tbd
         * @param {BlockType} [preferredBlock]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async processGet(preferredBlock, options) {
            const localVarAxiosArgs = await localVarAxiosParamCreator.processGet(preferredBlock, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * tbd
         * @param {ProcessInitReq} processInitReq
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async processInit(processInitReq, options) {
            const localVarAxiosArgs = await localVarAxiosParamCreator.processInit(processInitReq, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * tbd
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async processReset(options) {
            const localVarAxiosArgs = await localVarAxiosParamCreator.processReset(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * tbd
         * @param {SignupInitReq} signupInitReq
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async signupInit(signupInitReq, options) {
            const localVarAxiosArgs = await localVarAxiosParamCreator.signupInit(signupInitReq, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * tbd
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async socialVerifyCallback(options) {
            const localVarAxiosArgs = await localVarAxiosParamCreator.socialVerifyCallback(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * tbd
         * @param {object} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async socialVerifyFinish(body, options) {
            const localVarAxiosArgs = await localVarAxiosParamCreator.socialVerifyFinish(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * tbd
         * @param {SocialVerifyStartReq} socialVerifyStartReq
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async socialVerifyStart(socialVerifyStartReq, options) {
            const localVarAxiosArgs = await localVarAxiosParamCreator.socialVerifyStart(socialVerifyStartReq, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};
/**
 * AuthApi - factory interface
 * @export
 */
export const AuthApiFactory = function (configuration, basePath, axios) {
    const localVarFp = AuthApiFp(configuration);
    return {
        /**
         * tbd
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        blockSkip(options) {
            return localVarFp.blockSkip(options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a new user generated complete event.
         * @param {EventCreateReq} eventCreateReq
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eventCreate(eventCreateReq, options) {
            return localVarFp.eventCreate(eventCreateReq, options).then((request) => request(axios, basePath));
        },
        /**
         * tbd
         * @param {IdentifierUpdateReq} identifierUpdateReq
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identifierUpdate(identifierUpdateReq, options) {
            return localVarFp.identifierUpdate(identifierUpdateReq, options).then((request) => request(axios, basePath));
        },
        /**
         * tbd
         * @param {IdentifierVerifyFinishReq} identifierVerifyFinishReq
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identifierVerifyFinish(identifierVerifyFinishReq, options) {
            return localVarFp.identifierVerifyFinish(identifierVerifyFinishReq, options).then((request) => request(axios, basePath));
        },
        /**
         * tbd
         * @param {IdentifierVerifyStartReq} identifierVerifyStartReq
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identifierVerifyStart(identifierVerifyStartReq, options) {
            return localVarFp.identifierVerifyStart(identifierVerifyStartReq, options).then((request) => request(axios, basePath));
        },
        /**
         * tbd
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identifierVerifyStatus(options) {
            return localVarFp.identifierVerifyStatus(options).then((request) => request(axios, basePath));
        },
        /**
         * tbd
         * @param {LoginInitReq} loginInitReq
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginInit(loginInitReq, options) {
            return localVarFp.loginInit(loginInitReq, options).then((request) => request(axios, basePath));
        },
        /**
         * tbd
         * @param {PasskeyAppendFinishReq} passkeyAppendFinishReq
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        passkeyAppendFinish(passkeyAppendFinishReq, options) {
            return localVarFp.passkeyAppendFinish(passkeyAppendFinishReq, options).then((request) => request(axios, basePath));
        },
        /**
         * tbd
         * @param {PasskeyAppendStartReq} passkeyAppendStartReq
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        passkeyAppendStart(passkeyAppendStartReq, options) {
            return localVarFp.passkeyAppendStart(passkeyAppendStartReq, options).then((request) => request(axios, basePath));
        },
        /**
         * tbd
         * @param {PasskeyLoginFinishReq} passkeyLoginFinishReq
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        passkeyLoginFinish(passkeyLoginFinishReq, options) {
            return localVarFp.passkeyLoginFinish(passkeyLoginFinishReq, options).then((request) => request(axios, basePath));
        },
        /**
         * tbd
         * @param {PasskeyLoginStartReq} passkeyLoginStartReq
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        passkeyLoginStart(passkeyLoginStartReq, options) {
            return localVarFp.passkeyLoginStart(passkeyLoginStartReq, options).then((request) => request(axios, basePath));
        },
        /**
         * tbd
         * @param {PasskeyMediationFinishReq} passkeyMediationFinishReq
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        passkeyMediationFinish(passkeyMediationFinishReq, options) {
            return localVarFp.passkeyMediationFinish(passkeyMediationFinishReq, options).then((request) => request(axios, basePath));
        },
        /**
         * tbd
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processComplete(options) {
            return localVarFp.processComplete(options).then((request) => request(axios, basePath));
        },
        /**
         * tbd
         * @param {BlockType} [preferredBlock]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processGet(preferredBlock, options) {
            return localVarFp.processGet(preferredBlock, options).then((request) => request(axios, basePath));
        },
        /**
         * tbd
         * @param {ProcessInitReq} processInitReq
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processInit(processInitReq, options) {
            return localVarFp.processInit(processInitReq, options).then((request) => request(axios, basePath));
        },
        /**
         * tbd
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processReset(options) {
            return localVarFp.processReset(options).then((request) => request(axios, basePath));
        },
        /**
         * tbd
         * @param {SignupInitReq} signupInitReq
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signupInit(signupInitReq, options) {
            return localVarFp.signupInit(signupInitReq, options).then((request) => request(axios, basePath));
        },
        /**
         * tbd
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        socialVerifyCallback(options) {
            return localVarFp.socialVerifyCallback(options).then((request) => request(axios, basePath));
        },
        /**
         * tbd
         * @param {object} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        socialVerifyFinish(body, options) {
            return localVarFp.socialVerifyFinish(body, options).then((request) => request(axios, basePath));
        },
        /**
         * tbd
         * @param {SocialVerifyStartReq} socialVerifyStartReq
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        socialVerifyStart(socialVerifyStartReq, options) {
            return localVarFp.socialVerifyStart(socialVerifyStartReq, options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
export class AuthApi extends BaseAPI {
    /**
     * tbd
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    blockSkip(options) {
        return AuthApiFp(this.configuration).blockSkip(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Creates a new user generated complete event.
     * @param {EventCreateReq} eventCreateReq
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    eventCreate(eventCreateReq, options) {
        return AuthApiFp(this.configuration).eventCreate(eventCreateReq, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * tbd
     * @param {IdentifierUpdateReq} identifierUpdateReq
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    identifierUpdate(identifierUpdateReq, options) {
        return AuthApiFp(this.configuration).identifierUpdate(identifierUpdateReq, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * tbd
     * @param {IdentifierVerifyFinishReq} identifierVerifyFinishReq
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    identifierVerifyFinish(identifierVerifyFinishReq, options) {
        return AuthApiFp(this.configuration).identifierVerifyFinish(identifierVerifyFinishReq, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * tbd
     * @param {IdentifierVerifyStartReq} identifierVerifyStartReq
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    identifierVerifyStart(identifierVerifyStartReq, options) {
        return AuthApiFp(this.configuration).identifierVerifyStart(identifierVerifyStartReq, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * tbd
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    identifierVerifyStatus(options) {
        return AuthApiFp(this.configuration).identifierVerifyStatus(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * tbd
     * @param {LoginInitReq} loginInitReq
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    loginInit(loginInitReq, options) {
        return AuthApiFp(this.configuration).loginInit(loginInitReq, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * tbd
     * @param {PasskeyAppendFinishReq} passkeyAppendFinishReq
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    passkeyAppendFinish(passkeyAppendFinishReq, options) {
        return AuthApiFp(this.configuration).passkeyAppendFinish(passkeyAppendFinishReq, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * tbd
     * @param {PasskeyAppendStartReq} passkeyAppendStartReq
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    passkeyAppendStart(passkeyAppendStartReq, options) {
        return AuthApiFp(this.configuration).passkeyAppendStart(passkeyAppendStartReq, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * tbd
     * @param {PasskeyLoginFinishReq} passkeyLoginFinishReq
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    passkeyLoginFinish(passkeyLoginFinishReq, options) {
        return AuthApiFp(this.configuration).passkeyLoginFinish(passkeyLoginFinishReq, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * tbd
     * @param {PasskeyLoginStartReq} passkeyLoginStartReq
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    passkeyLoginStart(passkeyLoginStartReq, options) {
        return AuthApiFp(this.configuration).passkeyLoginStart(passkeyLoginStartReq, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * tbd
     * @param {PasskeyMediationFinishReq} passkeyMediationFinishReq
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    passkeyMediationFinish(passkeyMediationFinishReq, options) {
        return AuthApiFp(this.configuration).passkeyMediationFinish(passkeyMediationFinishReq, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * tbd
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    processComplete(options) {
        return AuthApiFp(this.configuration).processComplete(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * tbd
     * @param {BlockType} [preferredBlock]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    processGet(preferredBlock, options) {
        return AuthApiFp(this.configuration).processGet(preferredBlock, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * tbd
     * @param {ProcessInitReq} processInitReq
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    processInit(processInitReq, options) {
        return AuthApiFp(this.configuration).processInit(processInitReq, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * tbd
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    processReset(options) {
        return AuthApiFp(this.configuration).processReset(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * tbd
     * @param {SignupInitReq} signupInitReq
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    signupInit(signupInitReq, options) {
        return AuthApiFp(this.configuration).signupInit(signupInitReq, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * tbd
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    socialVerifyCallback(options) {
        return AuthApiFp(this.configuration).socialVerifyCallback(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * tbd
     * @param {object} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    socialVerifyFinish(body, options) {
        return AuthApiFp(this.configuration).socialVerifyFinish(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * tbd
     * @param {SocialVerifyStartReq} socialVerifyStartReq
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    socialVerifyStart(socialVerifyStartReq, options) {
        return AuthApiFp(this.configuration).socialVerifyStart(socialVerifyStartReq, options).then((request) => request(this.axios, this.basePath));
    }
}
/**
 * ConfigsApi - axios parameter creator
 * @export
 */
export const ConfigsApiAxiosParamCreator = function (configuration) {
    return {
        /**
         * tbd
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSessionConfig: async (options = {}) => {
            const localVarPath = `/v2/session-config`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);
            // authentication projectID required
            await setApiKeyToObject(localVarHeaderParameter, "X-Corbado-ProjectID", configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets configs needed by the UserDetails component
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserDetailsConfig: async (options = {}) => {
            const localVarPath = `/v2/user-details-config`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);
            // authentication projectID required
            await setApiKeyToObject(localVarHeaderParameter, "X-Corbado-ProjectID", configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * ConfigsApi - functional programming interface
 * @export
 */
export const ConfigsApiFp = function (configuration) {
    const localVarAxiosParamCreator = ConfigsApiAxiosParamCreator(configuration);
    return {
        /**
         * tbd
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSessionConfig(options) {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSessionConfig(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Gets configs needed by the UserDetails component
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserDetailsConfig(options) {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserDetailsConfig(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};
/**
 * ConfigsApi - factory interface
 * @export
 */
export const ConfigsApiFactory = function (configuration, basePath, axios) {
    const localVarFp = ConfigsApiFp(configuration);
    return {
        /**
         * tbd
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSessionConfig(options) {
            return localVarFp.getSessionConfig(options).then((request) => request(axios, basePath));
        },
        /**
         * Gets configs needed by the UserDetails component
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserDetailsConfig(options) {
            return localVarFp.getUserDetailsConfig(options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * ConfigsApi - object-oriented interface
 * @export
 * @class ConfigsApi
 * @extends {BaseAPI}
 */
export class ConfigsApi extends BaseAPI {
    /**
     * tbd
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigsApi
     */
    getSessionConfig(options) {
        return ConfigsApiFp(this.configuration).getSessionConfig(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Gets configs needed by the UserDetails component
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigsApi
     */
    getUserDetailsConfig(options) {
        return ConfigsApiFp(this.configuration).getUserDetailsConfig(options).then((request) => request(this.axios, this.basePath));
    }
}
/**
 * CorbadoConnectApi - axios parameter creator
 * @export
 */
export const CorbadoConnectApiAxiosParamCreator = function (configuration) {
    return {
        /**
         * Finishes an initialized connect passkey append process.
         * @param {ConnectAppendFinishReq} connectAppendFinishReq
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        connectAppendFinish: async (connectAppendFinishReq, options = {}) => {
            // verify required parameter 'connectAppendFinishReq' is not null or undefined
            assertParamExists('connectAppendFinish', 'connectAppendFinishReq', connectAppendFinishReq);
            const localVarPath = `/v2/connect/append/finish`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);
            // authentication projectID required
            await setApiKeyToObject(localVarHeaderParameter, "X-Corbado-ProjectID", configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(connectAppendFinishReq, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Initializes a connect process for passkey append.
         * @param {ConnectAppendInitReq} connectAppendInitReq
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        connectAppendInit: async (connectAppendInitReq, options = {}) => {
            // verify required parameter 'connectAppendInitReq' is not null or undefined
            assertParamExists('connectAppendInit', 'connectAppendInitReq', connectAppendInitReq);
            const localVarPath = `/v2/connect/append/init`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);
            // authentication projectID required
            await setApiKeyToObject(localVarHeaderParameter, "X-Corbado-ProjectID", configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(connectAppendInitReq, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Starts an initialized connect passkey append process.
         * @param {ConnectAppendStartReq} connectAppendStartReq
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        connectAppendStart: async (connectAppendStartReq, options = {}) => {
            // verify required parameter 'connectAppendStartReq' is not null or undefined
            assertParamExists('connectAppendStart', 'connectAppendStartReq', connectAppendStartReq);
            const localVarPath = `/v2/connect/append/start`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);
            // authentication projectID required
            await setApiKeyToObject(localVarHeaderParameter, "X-Corbado-ProjectID", configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(connectAppendStartReq, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a new user generated connect event.
         * @param {ConnectEventCreateReq} connectEventCreateReq
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        connectEventCreate: async (connectEventCreateReq, options = {}) => {
            // verify required parameter 'connectEventCreateReq' is not null or undefined
            assertParamExists('connectEventCreate', 'connectEventCreateReq', connectEventCreateReq);
            const localVarPath = `/v2/connect/events`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);
            // authentication projectID required
            await setApiKeyToObject(localVarHeaderParameter, "X-Corbado-ProjectID", configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(connectEventCreateReq, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Finishes an initialized connect login process.
         * @param {ConnectLoginFinishReq} connectLoginFinishReq
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        connectLoginFinish: async (connectLoginFinishReq, options = {}) => {
            // verify required parameter 'connectLoginFinishReq' is not null or undefined
            assertParamExists('connectLoginFinish', 'connectLoginFinishReq', connectLoginFinishReq);
            const localVarPath = `/v2/connect/login/finish`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);
            // authentication projectID required
            await setApiKeyToObject(localVarHeaderParameter, "X-Corbado-ProjectID", configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(connectLoginFinishReq, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Initializes a connect process for login.
         * @param {ConnectLoginInitReq} connectLoginInitReq
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        connectLoginInit: async (connectLoginInitReq, options = {}) => {
            // verify required parameter 'connectLoginInitReq' is not null or undefined
            assertParamExists('connectLoginInit', 'connectLoginInitReq', connectLoginInitReq);
            const localVarPath = `/v2/connect/login/init`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);
            // authentication projectID required
            await setApiKeyToObject(localVarHeaderParameter, "X-Corbado-ProjectID", configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(connectLoginInitReq, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Starts an initialized connect login process.
         * @param {ConnectLoginStartReq} connectLoginStartReq
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        connectLoginStart: async (connectLoginStartReq, options = {}) => {
            // verify required parameter 'connectLoginStartReq' is not null or undefined
            assertParamExists('connectLoginStart', 'connectLoginStartReq', connectLoginStartReq);
            const localVarPath = `/v2/connect/login/start`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);
            // authentication projectID required
            await setApiKeyToObject(localVarHeaderParameter, "X-Corbado-ProjectID", configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(connectLoginStartReq, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Deletes a passkey for a user identified by a connect token
         * @param {ConnectManageDeleteReq} connectManageDeleteReq
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        connectManageDelete: async (connectManageDeleteReq, options = {}) => {
            // verify required parameter 'connectManageDeleteReq' is not null or undefined
            assertParamExists('connectManageDelete', 'connectManageDeleteReq', connectManageDeleteReq);
            const localVarPath = `/v2/connect/manage/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);
            // authentication projectID required
            await setApiKeyToObject(localVarHeaderParameter, "X-Corbado-ProjectID", configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(connectManageDeleteReq, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Initializes a connect process for passkey management.
         * @param {ConnectManageInitReq} connectManageInitReq
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        connectManageInit: async (connectManageInitReq, options = {}) => {
            // verify required parameter 'connectManageInitReq' is not null or undefined
            assertParamExists('connectManageInit', 'connectManageInitReq', connectManageInitReq);
            const localVarPath = `/v2/connect/manage/init`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);
            // authentication projectID required
            await setApiKeyToObject(localVarHeaderParameter, "X-Corbado-ProjectID", configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(connectManageInitReq, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Lists all passkeys for a user identifier by a connect token.
         * @param {ConnectManageListReq} connectManageListReq
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        connectManageList: async (connectManageListReq, options = {}) => {
            // verify required parameter 'connectManageListReq' is not null or undefined
            assertParamExists('connectManageList', 'connectManageListReq', connectManageListReq);
            const localVarPath = `/v2/connect/manage/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);
            // authentication projectID required
            await setApiKeyToObject(localVarHeaderParameter, "X-Corbado-ProjectID", configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(connectManageListReq, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Remove process state for a connect process.
         * @param {ConnectProcessClearReq} connectProcessClearReq
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        connectProcessClear: async (connectProcessClearReq, options = {}) => {
            // verify required parameter 'connectProcessClearReq' is not null or undefined
            assertParamExists('connectProcessClear', 'connectProcessClearReq', connectProcessClearReq);
            const localVarPath = `/v2/connect/process/clear`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);
            // authentication projectID required
            await setApiKeyToObject(localVarHeaderParameter, "X-Corbado-ProjectID", configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(connectProcessClearReq, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * CorbadoConnectApi - functional programming interface
 * @export
 */
export const CorbadoConnectApiFp = function (configuration) {
    const localVarAxiosParamCreator = CorbadoConnectApiAxiosParamCreator(configuration);
    return {
        /**
         * Finishes an initialized connect passkey append process.
         * @param {ConnectAppendFinishReq} connectAppendFinishReq
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async connectAppendFinish(connectAppendFinishReq, options) {
            const localVarAxiosArgs = await localVarAxiosParamCreator.connectAppendFinish(connectAppendFinishReq, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Initializes a connect process for passkey append.
         * @param {ConnectAppendInitReq} connectAppendInitReq
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async connectAppendInit(connectAppendInitReq, options) {
            const localVarAxiosArgs = await localVarAxiosParamCreator.connectAppendInit(connectAppendInitReq, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Starts an initialized connect passkey append process.
         * @param {ConnectAppendStartReq} connectAppendStartReq
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async connectAppendStart(connectAppendStartReq, options) {
            const localVarAxiosArgs = await localVarAxiosParamCreator.connectAppendStart(connectAppendStartReq, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates a new user generated connect event.
         * @param {ConnectEventCreateReq} connectEventCreateReq
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async connectEventCreate(connectEventCreateReq, options) {
            const localVarAxiosArgs = await localVarAxiosParamCreator.connectEventCreate(connectEventCreateReq, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Finishes an initialized connect login process.
         * @param {ConnectLoginFinishReq} connectLoginFinishReq
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async connectLoginFinish(connectLoginFinishReq, options) {
            const localVarAxiosArgs = await localVarAxiosParamCreator.connectLoginFinish(connectLoginFinishReq, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Initializes a connect process for login.
         * @param {ConnectLoginInitReq} connectLoginInitReq
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async connectLoginInit(connectLoginInitReq, options) {
            const localVarAxiosArgs = await localVarAxiosParamCreator.connectLoginInit(connectLoginInitReq, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Starts an initialized connect login process.
         * @param {ConnectLoginStartReq} connectLoginStartReq
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async connectLoginStart(connectLoginStartReq, options) {
            const localVarAxiosArgs = await localVarAxiosParamCreator.connectLoginStart(connectLoginStartReq, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Deletes a passkey for a user identified by a connect token
         * @param {ConnectManageDeleteReq} connectManageDeleteReq
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async connectManageDelete(connectManageDeleteReq, options) {
            const localVarAxiosArgs = await localVarAxiosParamCreator.connectManageDelete(connectManageDeleteReq, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Initializes a connect process for passkey management.
         * @param {ConnectManageInitReq} connectManageInitReq
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async connectManageInit(connectManageInitReq, options) {
            const localVarAxiosArgs = await localVarAxiosParamCreator.connectManageInit(connectManageInitReq, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Lists all passkeys for a user identifier by a connect token.
         * @param {ConnectManageListReq} connectManageListReq
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async connectManageList(connectManageListReq, options) {
            const localVarAxiosArgs = await localVarAxiosParamCreator.connectManageList(connectManageListReq, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Remove process state for a connect process.
         * @param {ConnectProcessClearReq} connectProcessClearReq
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async connectProcessClear(connectProcessClearReq, options) {
            const localVarAxiosArgs = await localVarAxiosParamCreator.connectProcessClear(connectProcessClearReq, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};
/**
 * CorbadoConnectApi - factory interface
 * @export
 */
export const CorbadoConnectApiFactory = function (configuration, basePath, axios) {
    const localVarFp = CorbadoConnectApiFp(configuration);
    return {
        /**
         * Finishes an initialized connect passkey append process.
         * @param {ConnectAppendFinishReq} connectAppendFinishReq
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        connectAppendFinish(connectAppendFinishReq, options) {
            return localVarFp.connectAppendFinish(connectAppendFinishReq, options).then((request) => request(axios, basePath));
        },
        /**
         * Initializes a connect process for passkey append.
         * @param {ConnectAppendInitReq} connectAppendInitReq
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        connectAppendInit(connectAppendInitReq, options) {
            return localVarFp.connectAppendInit(connectAppendInitReq, options).then((request) => request(axios, basePath));
        },
        /**
         * Starts an initialized connect passkey append process.
         * @param {ConnectAppendStartReq} connectAppendStartReq
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        connectAppendStart(connectAppendStartReq, options) {
            return localVarFp.connectAppendStart(connectAppendStartReq, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a new user generated connect event.
         * @param {ConnectEventCreateReq} connectEventCreateReq
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        connectEventCreate(connectEventCreateReq, options) {
            return localVarFp.connectEventCreate(connectEventCreateReq, options).then((request) => request(axios, basePath));
        },
        /**
         * Finishes an initialized connect login process.
         * @param {ConnectLoginFinishReq} connectLoginFinishReq
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        connectLoginFinish(connectLoginFinishReq, options) {
            return localVarFp.connectLoginFinish(connectLoginFinishReq, options).then((request) => request(axios, basePath));
        },
        /**
         * Initializes a connect process for login.
         * @param {ConnectLoginInitReq} connectLoginInitReq
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        connectLoginInit(connectLoginInitReq, options) {
            return localVarFp.connectLoginInit(connectLoginInitReq, options).then((request) => request(axios, basePath));
        },
        /**
         * Starts an initialized connect login process.
         * @param {ConnectLoginStartReq} connectLoginStartReq
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        connectLoginStart(connectLoginStartReq, options) {
            return localVarFp.connectLoginStart(connectLoginStartReq, options).then((request) => request(axios, basePath));
        },
        /**
         * Deletes a passkey for a user identified by a connect token
         * @param {ConnectManageDeleteReq} connectManageDeleteReq
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        connectManageDelete(connectManageDeleteReq, options) {
            return localVarFp.connectManageDelete(connectManageDeleteReq, options).then((request) => request(axios, basePath));
        },
        /**
         * Initializes a connect process for passkey management.
         * @param {ConnectManageInitReq} connectManageInitReq
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        connectManageInit(connectManageInitReq, options) {
            return localVarFp.connectManageInit(connectManageInitReq, options).then((request) => request(axios, basePath));
        },
        /**
         * Lists all passkeys for a user identifier by a connect token.
         * @param {ConnectManageListReq} connectManageListReq
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        connectManageList(connectManageListReq, options) {
            return localVarFp.connectManageList(connectManageListReq, options).then((request) => request(axios, basePath));
        },
        /**
         * Remove process state for a connect process.
         * @param {ConnectProcessClearReq} connectProcessClearReq
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        connectProcessClear(connectProcessClearReq, options) {
            return localVarFp.connectProcessClear(connectProcessClearReq, options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * CorbadoConnectApi - object-oriented interface
 * @export
 * @class CorbadoConnectApi
 * @extends {BaseAPI}
 */
export class CorbadoConnectApi extends BaseAPI {
    /**
     * Finishes an initialized connect passkey append process.
     * @param {ConnectAppendFinishReq} connectAppendFinishReq
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CorbadoConnectApi
     */
    connectAppendFinish(connectAppendFinishReq, options) {
        return CorbadoConnectApiFp(this.configuration).connectAppendFinish(connectAppendFinishReq, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Initializes a connect process for passkey append.
     * @param {ConnectAppendInitReq} connectAppendInitReq
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CorbadoConnectApi
     */
    connectAppendInit(connectAppendInitReq, options) {
        return CorbadoConnectApiFp(this.configuration).connectAppendInit(connectAppendInitReq, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Starts an initialized connect passkey append process.
     * @param {ConnectAppendStartReq} connectAppendStartReq
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CorbadoConnectApi
     */
    connectAppendStart(connectAppendStartReq, options) {
        return CorbadoConnectApiFp(this.configuration).connectAppendStart(connectAppendStartReq, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Creates a new user generated connect event.
     * @param {ConnectEventCreateReq} connectEventCreateReq
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CorbadoConnectApi
     */
    connectEventCreate(connectEventCreateReq, options) {
        return CorbadoConnectApiFp(this.configuration).connectEventCreate(connectEventCreateReq, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Finishes an initialized connect login process.
     * @param {ConnectLoginFinishReq} connectLoginFinishReq
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CorbadoConnectApi
     */
    connectLoginFinish(connectLoginFinishReq, options) {
        return CorbadoConnectApiFp(this.configuration).connectLoginFinish(connectLoginFinishReq, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Initializes a connect process for login.
     * @param {ConnectLoginInitReq} connectLoginInitReq
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CorbadoConnectApi
     */
    connectLoginInit(connectLoginInitReq, options) {
        return CorbadoConnectApiFp(this.configuration).connectLoginInit(connectLoginInitReq, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Starts an initialized connect login process.
     * @param {ConnectLoginStartReq} connectLoginStartReq
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CorbadoConnectApi
     */
    connectLoginStart(connectLoginStartReq, options) {
        return CorbadoConnectApiFp(this.configuration).connectLoginStart(connectLoginStartReq, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Deletes a passkey for a user identified by a connect token
     * @param {ConnectManageDeleteReq} connectManageDeleteReq
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CorbadoConnectApi
     */
    connectManageDelete(connectManageDeleteReq, options) {
        return CorbadoConnectApiFp(this.configuration).connectManageDelete(connectManageDeleteReq, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Initializes a connect process for passkey management.
     * @param {ConnectManageInitReq} connectManageInitReq
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CorbadoConnectApi
     */
    connectManageInit(connectManageInitReq, options) {
        return CorbadoConnectApiFp(this.configuration).connectManageInit(connectManageInitReq, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Lists all passkeys for a user identifier by a connect token.
     * @param {ConnectManageListReq} connectManageListReq
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CorbadoConnectApi
     */
    connectManageList(connectManageListReq, options) {
        return CorbadoConnectApiFp(this.configuration).connectManageList(connectManageListReq, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Remove process state for a connect process.
     * @param {ConnectProcessClearReq} connectProcessClearReq
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CorbadoConnectApi
     */
    connectProcessClear(connectProcessClearReq, options) {
        return CorbadoConnectApiFp(this.configuration).connectProcessClear(connectProcessClearReq, options).then((request) => request(this.axios, this.basePath));
    }
}
/**
 * UsersApi - axios parameter creator
 * @export
 */
export const UsersApiAxiosParamCreator = function (configuration) {
    return {
        /**
         * Deletes current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentUserDelete: async (options = {}) => {
            const localVarPath = `/v2/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);
            // authentication projectID required
            await setApiKeyToObject(localVarHeaderParameter, "X-Corbado-ProjectID", configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentUserGet: async (options = {}) => {
            const localVarPath = `/v2/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);
            // authentication projectID required
            await setApiKeyToObject(localVarHeaderParameter, "X-Corbado-ProjectID", configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates an identifier
         * @param {MeIdentifierCreateReq} meIdentifierCreateReq
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentUserIdentifierCreate: async (meIdentifierCreateReq, options = {}) => {
            // verify required parameter 'meIdentifierCreateReq' is not null or undefined
            assertParamExists('currentUserIdentifierCreate', 'meIdentifierCreateReq', meIdentifierCreateReq);
            const localVarPath = `/v2/me/identifier`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);
            // authentication projectID required
            await setApiKeyToObject(localVarHeaderParameter, "X-Corbado-ProjectID", configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(meIdentifierCreateReq, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Deletes an identifier
         * @param {MeIdentifierDeleteReq} meIdentifierDeleteReq
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentUserIdentifierDelete: async (meIdentifierDeleteReq, options = {}) => {
            // verify required parameter 'meIdentifierDeleteReq' is not null or undefined
            assertParamExists('currentUserIdentifierDelete', 'meIdentifierDeleteReq', meIdentifierDeleteReq);
            const localVarPath = `/v2/me/identifier`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);
            // authentication projectID required
            await setApiKeyToObject(localVarHeaderParameter, "X-Corbado-ProjectID", configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(meIdentifierDeleteReq, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Modifies an identifier (only permitted for username; identifierID will change)
         * @param {MeIdentifierUpdateReq} meIdentifierUpdateReq
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentUserIdentifierUpdate: async (meIdentifierUpdateReq, options = {}) => {
            // verify required parameter 'meIdentifierUpdateReq' is not null or undefined
            assertParamExists('currentUserIdentifierUpdate', 'meIdentifierUpdateReq', meIdentifierUpdateReq);
            const localVarPath = `/v2/me/identifier`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);
            // authentication projectID required
            await setApiKeyToObject(localVarHeaderParameter, "X-Corbado-ProjectID", configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(meIdentifierUpdateReq, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Verifies challenge
         * @param {MeIdentifierVerifyFinishReq} meIdentifierVerifyFinishReq
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentUserIdentifierVerifyFinish: async (meIdentifierVerifyFinishReq, options = {}) => {
            // verify required parameter 'meIdentifierVerifyFinishReq' is not null or undefined
            assertParamExists('currentUserIdentifierVerifyFinish', 'meIdentifierVerifyFinishReq', meIdentifierVerifyFinishReq);
            const localVarPath = `/v2/me/identifier/verify/finish`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);
            // authentication projectID required
            await setApiKeyToObject(localVarHeaderParameter, "X-Corbado-ProjectID", configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(meIdentifierVerifyFinishReq, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates challenge (only email otp and phone otp supported for now)
         * @param {MeIdentifierVerifyStartReq} meIdentifierVerifyStartReq
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentUserIdentifierVerifyStart: async (meIdentifierVerifyStartReq, options = {}) => {
            // verify required parameter 'meIdentifierVerifyStartReq' is not null or undefined
            assertParamExists('currentUserIdentifierVerifyStart', 'meIdentifierVerifyStartReq', meIdentifierVerifyStartReq);
            const localVarPath = `/v2/me/identifier/verify/start`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);
            // authentication projectID required
            await setApiKeyToObject(localVarHeaderParameter, "X-Corbado-ProjectID", configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(meIdentifierVerifyStartReq, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Finishes passkey append for current user
         * @param {MePasskeysAppendFinishReq} mePasskeysAppendFinishReq
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentUserPasskeyAppendFinish: async (mePasskeysAppendFinishReq, options = {}) => {
            // verify required parameter 'mePasskeysAppendFinishReq' is not null or undefined
            assertParamExists('currentUserPasskeyAppendFinish', 'mePasskeysAppendFinishReq', mePasskeysAppendFinishReq);
            const localVarPath = `/v2/me/passkeys/append/finish`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);
            // authentication projectID required
            await setApiKeyToObject(localVarHeaderParameter, "X-Corbado-ProjectID", configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(mePasskeysAppendFinishReq, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Starts passkey append for current user
         * @param {MePasskeysAppendStartReq} mePasskeysAppendStartReq
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentUserPasskeyAppendStart: async (mePasskeysAppendStartReq, options = {}) => {
            // verify required parameter 'mePasskeysAppendStartReq' is not null or undefined
            assertParamExists('currentUserPasskeyAppendStart', 'mePasskeysAppendStartReq', mePasskeysAppendStartReq);
            const localVarPath = `/v2/me/passkeys/append/start`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);
            // authentication projectID required
            await setApiKeyToObject(localVarHeaderParameter, "X-Corbado-ProjectID", configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(mePasskeysAppendStartReq, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete current user\'s passkeys
         * @param {string} credentialID Credential ID from passkeys
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentUserPasskeyDelete: async (credentialID, options = {}) => {
            // verify required parameter 'credentialID' is not null or undefined
            assertParamExists('currentUserPasskeyDelete', 'credentialID', credentialID);
            const localVarPath = `/v2/me/passkeys/{credentialID}`
                .replace(`{${"credentialID"}}`, encodeURIComponent(String(credentialID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);
            // authentication projectID required
            await setApiKeyToObject(localVarHeaderParameter, "X-Corbado-ProjectID", configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets current user\'s passkeys
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentUserPasskeyGet: async (options = {}) => {
            const localVarPath = `/v2/me/passkeys`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);
            // authentication projectID required
            await setApiKeyToObject(localVarHeaderParameter, "X-Corbado-ProjectID", configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Performs session logout
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentUserSessionLogout: async (options = {}) => {
            const localVarPath = `/v2/me/logout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);
            // authentication projectID required
            await setApiKeyToObject(localVarHeaderParameter, "X-Corbado-ProjectID", configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Performs session refresh
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentUserSessionRefresh: async (options = {}) => {
            const localVarPath = `/v2/me/refresh`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);
            // authentication projectID required
            await setApiKeyToObject(localVarHeaderParameter, "X-Corbado-ProjectID", configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates current user
         * @param {MeUpdateReq} meUpdateReq
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentUserUpdate: async (meUpdateReq, options = {}) => {
            // verify required parameter 'meUpdateReq' is not null or undefined
            assertParamExists('currentUserUpdate', 'meUpdateReq', meUpdateReq);
            const localVarPath = `/v2/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);
            // authentication projectID required
            await setApiKeyToObject(localVarHeaderParameter, "X-Corbado-ProjectID", configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(meUpdateReq, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function (configuration) {
    const localVarAxiosParamCreator = UsersApiAxiosParamCreator(configuration);
    return {
        /**
         * Deletes current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async currentUserDelete(options) {
            const localVarAxiosArgs = await localVarAxiosParamCreator.currentUserDelete(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Gets current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async currentUserGet(options) {
            const localVarAxiosArgs = await localVarAxiosParamCreator.currentUserGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates an identifier
         * @param {MeIdentifierCreateReq} meIdentifierCreateReq
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async currentUserIdentifierCreate(meIdentifierCreateReq, options) {
            const localVarAxiosArgs = await localVarAxiosParamCreator.currentUserIdentifierCreate(meIdentifierCreateReq, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Deletes an identifier
         * @param {MeIdentifierDeleteReq} meIdentifierDeleteReq
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async currentUserIdentifierDelete(meIdentifierDeleteReq, options) {
            const localVarAxiosArgs = await localVarAxiosParamCreator.currentUserIdentifierDelete(meIdentifierDeleteReq, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Modifies an identifier (only permitted for username; identifierID will change)
         * @param {MeIdentifierUpdateReq} meIdentifierUpdateReq
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async currentUserIdentifierUpdate(meIdentifierUpdateReq, options) {
            const localVarAxiosArgs = await localVarAxiosParamCreator.currentUserIdentifierUpdate(meIdentifierUpdateReq, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Verifies challenge
         * @param {MeIdentifierVerifyFinishReq} meIdentifierVerifyFinishReq
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async currentUserIdentifierVerifyFinish(meIdentifierVerifyFinishReq, options) {
            const localVarAxiosArgs = await localVarAxiosParamCreator.currentUserIdentifierVerifyFinish(meIdentifierVerifyFinishReq, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates challenge (only email otp and phone otp supported for now)
         * @param {MeIdentifierVerifyStartReq} meIdentifierVerifyStartReq
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async currentUserIdentifierVerifyStart(meIdentifierVerifyStartReq, options) {
            const localVarAxiosArgs = await localVarAxiosParamCreator.currentUserIdentifierVerifyStart(meIdentifierVerifyStartReq, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Finishes passkey append for current user
         * @param {MePasskeysAppendFinishReq} mePasskeysAppendFinishReq
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async currentUserPasskeyAppendFinish(mePasskeysAppendFinishReq, options) {
            const localVarAxiosArgs = await localVarAxiosParamCreator.currentUserPasskeyAppendFinish(mePasskeysAppendFinishReq, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Starts passkey append for current user
         * @param {MePasskeysAppendStartReq} mePasskeysAppendStartReq
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async currentUserPasskeyAppendStart(mePasskeysAppendStartReq, options) {
            const localVarAxiosArgs = await localVarAxiosParamCreator.currentUserPasskeyAppendStart(mePasskeysAppendStartReq, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete current user\'s passkeys
         * @param {string} credentialID Credential ID from passkeys
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async currentUserPasskeyDelete(credentialID, options) {
            const localVarAxiosArgs = await localVarAxiosParamCreator.currentUserPasskeyDelete(credentialID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Gets current user\'s passkeys
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async currentUserPasskeyGet(options) {
            const localVarAxiosArgs = await localVarAxiosParamCreator.currentUserPasskeyGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Performs session logout
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async currentUserSessionLogout(options) {
            const localVarAxiosArgs = await localVarAxiosParamCreator.currentUserSessionLogout(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Performs session refresh
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async currentUserSessionRefresh(options) {
            const localVarAxiosArgs = await localVarAxiosParamCreator.currentUserSessionRefresh(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates current user
         * @param {MeUpdateReq} meUpdateReq
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async currentUserUpdate(meUpdateReq, options) {
            const localVarAxiosArgs = await localVarAxiosParamCreator.currentUserUpdate(meUpdateReq, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};
/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (configuration, basePath, axios) {
    const localVarFp = UsersApiFp(configuration);
    return {
        /**
         * Deletes current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentUserDelete(options) {
            return localVarFp.currentUserDelete(options).then((request) => request(axios, basePath));
        },
        /**
         * Gets current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentUserGet(options) {
            return localVarFp.currentUserGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Creates an identifier
         * @param {MeIdentifierCreateReq} meIdentifierCreateReq
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentUserIdentifierCreate(meIdentifierCreateReq, options) {
            return localVarFp.currentUserIdentifierCreate(meIdentifierCreateReq, options).then((request) => request(axios, basePath));
        },
        /**
         * Deletes an identifier
         * @param {MeIdentifierDeleteReq} meIdentifierDeleteReq
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentUserIdentifierDelete(meIdentifierDeleteReq, options) {
            return localVarFp.currentUserIdentifierDelete(meIdentifierDeleteReq, options).then((request) => request(axios, basePath));
        },
        /**
         * Modifies an identifier (only permitted for username; identifierID will change)
         * @param {MeIdentifierUpdateReq} meIdentifierUpdateReq
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentUserIdentifierUpdate(meIdentifierUpdateReq, options) {
            return localVarFp.currentUserIdentifierUpdate(meIdentifierUpdateReq, options).then((request) => request(axios, basePath));
        },
        /**
         * Verifies challenge
         * @param {MeIdentifierVerifyFinishReq} meIdentifierVerifyFinishReq
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentUserIdentifierVerifyFinish(meIdentifierVerifyFinishReq, options) {
            return localVarFp.currentUserIdentifierVerifyFinish(meIdentifierVerifyFinishReq, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates challenge (only email otp and phone otp supported for now)
         * @param {MeIdentifierVerifyStartReq} meIdentifierVerifyStartReq
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentUserIdentifierVerifyStart(meIdentifierVerifyStartReq, options) {
            return localVarFp.currentUserIdentifierVerifyStart(meIdentifierVerifyStartReq, options).then((request) => request(axios, basePath));
        },
        /**
         * Finishes passkey append for current user
         * @param {MePasskeysAppendFinishReq} mePasskeysAppendFinishReq
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentUserPasskeyAppendFinish(mePasskeysAppendFinishReq, options) {
            return localVarFp.currentUserPasskeyAppendFinish(mePasskeysAppendFinishReq, options).then((request) => request(axios, basePath));
        },
        /**
         * Starts passkey append for current user
         * @param {MePasskeysAppendStartReq} mePasskeysAppendStartReq
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentUserPasskeyAppendStart(mePasskeysAppendStartReq, options) {
            return localVarFp.currentUserPasskeyAppendStart(mePasskeysAppendStartReq, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete current user\'s passkeys
         * @param {string} credentialID Credential ID from passkeys
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentUserPasskeyDelete(credentialID, options) {
            return localVarFp.currentUserPasskeyDelete(credentialID, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets current user\'s passkeys
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentUserPasskeyGet(options) {
            return localVarFp.currentUserPasskeyGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Performs session logout
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentUserSessionLogout(options) {
            return localVarFp.currentUserSessionLogout(options).then((request) => request(axios, basePath));
        },
        /**
         * Performs session refresh
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentUserSessionRefresh(options) {
            return localVarFp.currentUserSessionRefresh(options).then((request) => request(axios, basePath));
        },
        /**
         * Updates current user
         * @param {MeUpdateReq} meUpdateReq
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentUserUpdate(meUpdateReq, options) {
            return localVarFp.currentUserUpdate(meUpdateReq, options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI {
    /**
     * Deletes current user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    currentUserDelete(options) {
        return UsersApiFp(this.configuration).currentUserDelete(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Gets current user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    currentUserGet(options) {
        return UsersApiFp(this.configuration).currentUserGet(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Creates an identifier
     * @param {MeIdentifierCreateReq} meIdentifierCreateReq
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    currentUserIdentifierCreate(meIdentifierCreateReq, options) {
        return UsersApiFp(this.configuration).currentUserIdentifierCreate(meIdentifierCreateReq, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Deletes an identifier
     * @param {MeIdentifierDeleteReq} meIdentifierDeleteReq
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    currentUserIdentifierDelete(meIdentifierDeleteReq, options) {
        return UsersApiFp(this.configuration).currentUserIdentifierDelete(meIdentifierDeleteReq, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Modifies an identifier (only permitted for username; identifierID will change)
     * @param {MeIdentifierUpdateReq} meIdentifierUpdateReq
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    currentUserIdentifierUpdate(meIdentifierUpdateReq, options) {
        return UsersApiFp(this.configuration).currentUserIdentifierUpdate(meIdentifierUpdateReq, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Verifies challenge
     * @param {MeIdentifierVerifyFinishReq} meIdentifierVerifyFinishReq
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    currentUserIdentifierVerifyFinish(meIdentifierVerifyFinishReq, options) {
        return UsersApiFp(this.configuration).currentUserIdentifierVerifyFinish(meIdentifierVerifyFinishReq, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Creates challenge (only email otp and phone otp supported for now)
     * @param {MeIdentifierVerifyStartReq} meIdentifierVerifyStartReq
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    currentUserIdentifierVerifyStart(meIdentifierVerifyStartReq, options) {
        return UsersApiFp(this.configuration).currentUserIdentifierVerifyStart(meIdentifierVerifyStartReq, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Finishes passkey append for current user
     * @param {MePasskeysAppendFinishReq} mePasskeysAppendFinishReq
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    currentUserPasskeyAppendFinish(mePasskeysAppendFinishReq, options) {
        return UsersApiFp(this.configuration).currentUserPasskeyAppendFinish(mePasskeysAppendFinishReq, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Starts passkey append for current user
     * @param {MePasskeysAppendStartReq} mePasskeysAppendStartReq
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    currentUserPasskeyAppendStart(mePasskeysAppendStartReq, options) {
        return UsersApiFp(this.configuration).currentUserPasskeyAppendStart(mePasskeysAppendStartReq, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Delete current user\'s passkeys
     * @param {string} credentialID Credential ID from passkeys
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    currentUserPasskeyDelete(credentialID, options) {
        return UsersApiFp(this.configuration).currentUserPasskeyDelete(credentialID, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Gets current user\'s passkeys
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    currentUserPasskeyGet(options) {
        return UsersApiFp(this.configuration).currentUserPasskeyGet(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Performs session logout
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    currentUserSessionLogout(options) {
        return UsersApiFp(this.configuration).currentUserSessionLogout(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Performs session refresh
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    currentUserSessionRefresh(options) {
        return UsersApiFp(this.configuration).currentUserSessionRefresh(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Updates current user
     * @param {MeUpdateReq} meUpdateReq
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    currentUserUpdate(meUpdateReq, options) {
        return UsersApiFp(this.configuration).currentUserUpdate(meUpdateReq, options).then((request) => request(this.axios, this.basePath));
    }
}
